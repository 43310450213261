import { Box, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MainContainer from "../../components/MainContainer";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import WidgetProgress from '../../components/WidgetsRelatory/widged';
import WidgetBlacklist from '../../components/WidgetsRelatory/widgetBlacklist';
import WidgetError from '../../components/WidgetsRelatory/widgetError';
import WidgetResponse from '../../components/WidgetsRelatory/widgetResponse';
import WidgetSended from '../../components/WidgetsRelatory/widgetSendedPerDay';
import TableRelatory from '../../components/WidgetsRelatory/widgetTableRelatory';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import JsonParse from "../../helpers/JsonParse";

const useStyles = makeStyles(theme => ({
    container: {
        margin: '20px 10px!important',
        padding: '10px',
        background: '#fff',
        borderRadius: '10px',
    },
    drawer: {
        width: '360px',
        flexShrink: 0,
    },
    drawerPaper: {
        width: '360px',
        display: "flex",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    header: {
        display: "flex",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#eee",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        minHeight: "73px",
        justifyContent: "flex-start",
    },
    content: {
        display: "flex",
        backgroundColor: "#eee",
        flexDirection: "column",
        padding: "8px 0px 8px 8px",
        height: "100%",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },

    contactAvatar: {
        margin: 15,
        width: 160,
        height: 160,
    },

    contactHeader: {
        display: "flex",
        padding: 8,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: 4,
        },
    },

    contactDetails: {
        marginTop: 8,
        padding: 8,
        display: "flex",
        flexDirection: "column",
    },
    contactExtraInfo: {
        marginTop: 4,
        padding: 6,
    },
    h4: {
        borderBottom: "3px solid rgba(0, 122, 95, 0.85)",
        width: "max-content",
        paddingRight: "15px",
        textTransform: "capitalize"
    }
}));


const RelatoryCampaigns = () => {
    const classes = useStyles();
    const [relatory, setRelatory] = useState(null);
    const [loading, setLoading] = useState([]);
    const params = useParams();
    const [updatedAt, setUpdatedAt] = useState("");
    const [loop, setLoop] = useState(true);

    useEffect(() => {
        if (loop == false) return;
        let isMounted = true;
        const abortController = new AbortController();

        const reloadMessage = async () => {
            setLoading(true);

            try {
                const relatoryId = params?.id;
                if (relatoryId) {
                    const { data } = await api.get(`/relatory/${relatoryId}`, {
                        signal: abortController.signal,
                    });
                    if (!isMounted) return;
                    if (data?.AllRelatory) {
                        try {
                            let parsed = JsonParse(data.AllRelatory);
                            data.AllRelatory = parsed;
                        } catch (err) {
                            console.error("ERROR TO CONVERT ALL RELATORY DATA");
                        }
                    }
                    setLoop(data.campaign?.status == 'finished' ? false : true);
                    setRelatory(data);
                    setLoading(false);
                    setUpdatedAt(new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }));
                } else {
                    toast.error('RELATORY ID NOT FOUND.');
                }
            } catch (err) {
                if (!isMounted || err.name == 'AbortError') return;
                console.error({err});
                toastError(err);
                setLoading(false);
            }
    
        }

        reloadMessage();
        const intervalId = setInterval(reloadMessage, 60000);

        return () => {
            isMounted = false;
            abortController.abort();
            clearInterval(intervalId);
        };

    }, [,loop]);

    return (
        <MainContainer>
            <div className={`row g-5 g-xl-8 ${classes.container}`}>
                {loading ? (
                    <TableRowSkeleton columns={3} />
                ) : (
                    <>
                        {(relatory.campaign && relatory.campaign.name) &&
                            <Box className="col-xl-12" style={{ marginTop: 0 }}>
                                <Typography variant="h4" component="h4" className={classes.h4}>{relatory.campaign.name}</Typography>
                                <Typography style={{ marginBottom: "20px", color: "#9e9e9e", marginTop: "2px" }}>{`${i18n.t("dashboard.updatedAt")} ${updatedAt}`}</Typography>
                            </Box>
                        }

                        <div className='col-xl-3'>
                            <WidgetSended
                                className='card-xl-stretch mb-xl-8'
                                chartColor='primary'
                                chartHeight='200px'
                                relatorio={relatory.enviadas}
                            />
                        </div>
                        <div
                            className='col-xl-3'>
                            <WidgetResponse
                                className='card-xl-stretch mb-xl-8'
                                chartColor='info'
                                chartHeight='200px'
                                relatorio={relatory.respondidas} />
                        </div>
                        <div
                            className='col-xl-3'>
                            <WidgetBlacklist
                                className='card-xl-stretch mb-xl-8'
                                chartColor='danger'
                                chartHeight='200px'
                                relatorio={relatory.bloqueadas} />

                        </div>
                        <div
                            className='col-xl-3'>
                            <WidgetError
                                className='card-xl-stretch mb-xl-8'
                                chartColor='danger'
                                chartHeight='200px'
                                relatorio={relatory.error} />

                        </div>
                        <div
                            className='col-xl-4'>
                            <WidgetProgress
                                className='card-xl-stretch mb-xl-8'
                                chartColor='success'
                                chartHeight='200px'
                                relatorio={relatory.progressCampaign}
                                totalContacts={relatory?.AllRelatory?.total}
                            />
                        </div>
                        <div
                            className='col-xl-8'>
                            <TableRelatory
                                className='card-xl-stretch mb-xl-8'
                                relatoryData={JSON.stringify(relatory)}
                            />
                        </div>

                    </>
                )}
            </div>
        </MainContainer>);
};

export default RelatoryCampaigns;
