import { ptBR } from "@material-ui/core/locale";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Suspense, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import Routes from "./routes";

const App = () => {
  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        //primary: { main: "#2576d2" },
        primary: { main: "#0b708c" },
      },
      body: {
        backgroundColor: '#ebebeb !important'
      },
      '::-webkit-scrollbar': {
        display: 'none'
      }
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
  <ThemeProvider theme={theme}>
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <Routes />
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  </ThemeProvider>
  );
};

export default App;
