import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../../translate/i18n";
import toastError from "../../../errors/toastError";
import { Grid, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Select, MenuItem, Tooltip } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import api from "../../../services/api";
import MultiSelect from "../../InputStyled/multiselect";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
}));

const StatusStoreModal = ({ open, onClose, statusId }) => {

    const classes = useStyles();

    const initialState = {
        name: null,
        userIds: []
    };
    const [status, setStatus] = useState(initialState);
    const [userIds, setUserIds] = useState([]);

    useEffect(() => {
        if (!open) return;
        let isMounted = true;
        const abortController = new AbortController();

        (async () => {
            try {

                const { data: usrs } = await api.get(`/usersList`, {
                    signal: abortController.signal,
                });
                if (!isMounted) return;
                if (usrs) setUserIds(usrs);

                if (statusId) {
                    const { data } = await api.get(`/customerRelationship/status/${statusId}`, {
                        signal: abortController.signal,
                    });
                    if (!isMounted) return;
                    if (data.status) {
                        setStatus(prevState => {
                            return { ...prevState, ...data.status };
                        });
                    }
    
                }

            } catch (err) {
                if (!isMounted || err.name == 'AbortError') return;
                console.error({err});
                toastError(err);
            }
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        };

    }, [open]);

    const clearInputs = () => {
        setUserIds([]);
        setStatus(initialState);
    }

    const handleClose = (event, reason) => {
        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        onClose();
        clearInputs();
    };

    const handleSave = async () => {
        try {
            if (!status.name || status.name == '') { toastError({ response: { data: { error: i18n.t('customerRelationship.configuration.status.messages.nameRequired') } } }); return; }

            if (statusId) {
                await api.put(`/customerRelationship/status/update/${statusId}`, { ...status });
            } else {
                await api.post(`/customerRelationship/status/create`, { ...status });
            }

            toast.success(i18n.t('backendSuccess.SAVED_SUCCESSFULLY'));
            handleClose();
        } catch (err) {
            console.error({ err });
            toastError(err);
        }
    }

    const handleUsers = (userIds) => {
        setStatus(prevState => {
            return { ...prevState, userIds: userIds };
        });
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open == true ? true : false}
                onClose={(event, reason) => handleClose(event, reason)}
                scroll="paper"
                fullWidth="sm"
                maxWidth="sm"
                disableEscapeKeyDown={true}
            >
                <DialogTitle>
                    {i18n.t('customerRelationship.configuration.status.title')}
                </DialogTitle>
                <DialogContent style={{ padding: "0 25px" }}>
                    <Grid container spacing={1} style={{ marginBottom: "10px" }}>
                        <Grid item sm={12} xs={12} mb={3}>
                            <Typography component="label">
                                {i18n.t('customerRelationship.configuration.status.form.name')}
                                <Typography component="span" className="text-danger ml-2">*</Typography>
                            </Typography>
                            <TextField
                                id="crmStatusName"
                                key="crmStatusName"
                                placeholder={i18n.t('customerRelationship.configuration.status.form.name')}
                                variant="outlined"
                                style={{ marginTop: "10px" }}
                                value={status.name || null}
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                size="small"
                                onChange={(e) => setStatus({ ...status, name: e.target.value })}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ marginBottom: "10px" }}>
                        <Grid item sm={12} xs={12} mb={3}>
                            <MultiSelect 
                                title={
                                    <Typography component="label">
                                        {i18n.t('customerRelationship.configuration.status.form.userIds')}
                                        <Typography component="span" className="text-danger ml-2">*</Typography>
                                    </Typography>
                                }
                                displayText={i18n.t('customerRelationship.configuration.status.form.selectUsers')}
                                options={userIds.map((usr) => { return { key: usr.id, value: usr.name, disabled: false } })}
                                disabled={false}
                                onSelected={handleUsers}
                                selecteds={status.userIds}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
                    >
                        {i18n.t("buttons.close")}
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                    >
                        {i18n.t("buttons.save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default StatusStoreModal;