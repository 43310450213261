import { useEffect, useReducer, useState } from "react";

import { Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import socket from "../../hooks/useSocket";
import { TableCell, TableBody, TableRow, Table, Grid, TableHead, Container, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const reducer = (state, action) => {
  if (action.type === "LOAD_COMPONENTS") {
    const components = action.payload;
    const newComponents = [];

    components.forEach((component) => {
      const componentIndex = state.findIndex((u) => u.id === component.id);
      if (componentIndex !== -1) {
        state[componentIndex] = component;
      } else {
        newComponents.push(component);
      }
    });

    return [...state, ...newComponents];
  }

  if (action.type === "UPDATE_COMPONENTS") {
    const components = action.payload;
    const componentIndex = state.findIndex((u) => u.id === components.id);

    if (componentIndex !== -1) {
      state[componentIndex] = components;
      return [...state];
    } else {
      return [components, ...state];
    }
  }

  if (action.type === "DELETE_COMPONENTS") {
    const componentId = action.payload;

    const componentIndex = state.findIndex((u) => u.id === componentId);
    if (componentIndex !== -1) {
      state.splice(componentIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    minWidth: '310px',
    width: 'auto',
    margin: '30px 20px!important',
    padding: '30px',
    background: '#fff',
    borderRadius: '10px',
    maxWidth: 'calc(100% - 20px)!important',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  },
}));
const Component = () => {
  const classes = useStyles();
  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [components, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const abortController = new AbortController();

    const delayDebounceFn = setTimeout(() => {
      const fetchComponent = async () => {
        try {
          const { data } = await api.get("/component/", {
            params: { searchParam, pageNumber },
            signal: abortController.signal,
          });
          if (!isMounted) return;
          if (data?.components) dispatch({ type: "LOAD_COMPONENTS", payload: data.components });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          if (!isMounted || err.name == 'AbortError') return;
          console.error({ err });
          toastError(err);
        }
      };
      fetchComponent();
    }, 500);

    return () => {
      isMounted = false;
      abortController.abort();
      clearTimeout(delayDebounceFn);
    };
  }, [searchParam, pageNumber]);

  useEffect(() => {

    const componentEventName = 'component';
    const componentEventFunction = async (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_COMPONENTS", payload: data.component });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_COMPONENTS", payload: +data.componentId });
      }
    };

    socket.on(componentEventName, componentEventFunction);

    return () => {
      socket.off(componentEventName, componentEventFunction);
    };
  }, []);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleDisable = async (openingData) => {
    try {
      openingData = { ...openingData, status: !openingData.status };
      await api.put(`/component/${openingData.id}`, openingData);
      toast.success(i18n.t("component.messages.success"));
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  };

  return (
    <MainContainer>
      <Container
        className={classes.modalStyle}
      >

        <Grid container spacing={5}>
          <Grid item sm={12} xs={12} mb={2}>
            <Typography variant="h6">{i18n.t("component.title")}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item sm={12} xs={12} mb={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.t("component.table.name")}</TableCell>
                  <TableCell>{i18n.t("component.table.description")}</TableCell>
                  <TableCell>{i18n.t("component.table.status")}</TableCell>
                  <TableCell>{i18n.t("component.table.createdAt")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {components.map((component) => (
                  <TableRow>
                    <TableCell component="th">{component?.name || ''}</TableCell>
                    <TableCell component="th">{component?.description || ''}</TableCell>
                    <TableCell>
                      <Switch
                        checked={component.status}
                        onChange={() => handleDisable(component)}
                        color="primary"
                        name={`status${component.status}`}
                        key={`status${component.status}`}
                        inputProps={{ 'aria-label': 'handle status' }}
                      />
                    </TableCell>
                    <TableCell>{component?.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>

  )
}

export default Component;
