import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    makeStyles
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TagCheckList from "./tagCheckList";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        maxWidth: '500px'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textTagContainer: {
        width: "100%",
    },
}));

const ForContactsModal = ({ open, onClose, contactId, onSave, tagsId }) => {
    // Hooks
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [submittingContent, setSubmittingContent] = useState(false);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        if (tagsId && Array.isArray(tagsId)) {
            const initialSelectedTags = tagsId.map(t => t.id);
            setSelectedTags(initialSelectedTags)
        } else {
            setSelectedTags([]);
        }
    }, [tagsId, open]);

    useEffect(() => {

        if (open != true) return;
        let isMounted = true;
        const abortController = new AbortController();

        const fetchTag = async () => {
            try {
                const { data } = await api.get(`/tags`, {
                    signal: abortController.signal,
                });
                if (!isMounted) return;
                setTags(data.tags.filter(t => t.status == true));
            } catch (err) {
                if (!isMounted || err.name == 'AbortError') return;
                console.error({err});
                toastError(err);
            }
        };
        fetchTag();

        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [contactId, open]);

    // Handlers
    const handleClose = useCallback(() => {
        onClose();
        setSelectedTags([]);
    }, [onClose]);

    const handleTagChange = useCallback((tagId, isChecked) => {
        setSelectedTags((prev) =>
            isChecked ? [...prev, tagId] : prev.filter(id => id !== tagId)
        );
    }, []);

    const handleSaveTag = useCallback(async () => {
        if (!contactId) return;
        try {
            await api.put(`/tags/updateInContact/${contactId}`, { tagsId: JSON.stringify(selectedTags) });
            if (onSave) {
                onSave(selectedTags);
            }
            handleClose();
            toast.success(i18n.t("tagForContactsModal.success"));
            return selectedTags;
        } catch (err) {
            console.error({err});
            toastError(err);
            setSubmittingContent(false);
        }
    }, [contactId, handleClose, selectedTags]);

    // Renderização
    return (
        <div className={classes.root}>
            <Dialog 
                open={open == true ? true : false}
                onClose={handleClose} 
                maxWidth="sm" 
                fullWidth 
                scroll="paper" 
                className="forContactsmodal"
            >
                <DialogTitle id="form-dialog-title">{i18n.t("tagForContactsModal.title")}</DialogTitle>
                <Box component="div" style={{ padding: "10px", maxHeight: "600px", maxWidth: "100%", overflowY: "auto" }}>
                    <TagCheckList tags={tags} selectedTags={selectedTags} onChange={handleTagChange} />
                </Box>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={submittingContent} variant="outlined">
                        {i18n.t("tagForContactsModal.buttons.cancel")}
                    </Button>
                    <Button onClick={handleSaveTag} color="primary" disabled={submittingContent} variant="contained">
                        {i18n.t("tagForContactsModal.buttons.ok")}
                        {submittingContent && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ForContactsModal;