import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { useEffect, useReducer, useRef, useState, useContext } from 'react';
import { i18n } from '../../../translate/i18n';
import CustomPagination from '../../General/CustomPagination';
import socket from '../../../hooks/useSocket';
import SkeletonList from '../../Skeleton/list';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import toastError from '../../../errors/toastError';
import { AuthContext } from '../../../context/Auth/AuthContext';
import StoreModal from './storeModal';
const io = require('socket.io-client');

export default function WhatsappTemplateTable({ ...props }) {

	const reducer = (state, action) => {

		if (action.type === "LOAD_WHATSAPP_TEMPLATES") {
			const whatsappTemplates = action.payload;
			const newWhatsappTemplates = [];

			whatsappTemplates.forEach((whatsappTemplate) => {
				newWhatsappTemplates.push(whatsappTemplate);
			});

			return [...newWhatsappTemplates].sort((a,b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) ? 1 : ((b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()) ? -1 : 0));
		}

		if (action.type === "UPDATE_WHATSAPP_TEMPLATES") {
			const whatsappTemplate = action.payload;
			const whatsappTemplateIndex = state.findIndex((t) => t.id == whatsappTemplate.id);

			if (whatsappTemplateIndex !== -1) {
				state[whatsappTemplateIndex] = whatsappTemplate;
				return [...state];
			} else {
				return [whatsappTemplate, ...state].sort((a,b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) ? 1 : ((b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()) ? -1 : 0));
			}
		}

		if (action.type === "DELETE_WHATSAPP_TEMPLATES") {
			const whatsappTemplateId = action.payload;

			const whatsappTemplateIndex = state.findIndex((t) => t.id === whatsappTemplateId);

			return [...state].sort((a,b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) ? 1 : ((b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()) ? -1 : 0));;
		}

		if (action.type === "RESET") {
			return [];
		}
	};

	const [whatsappTemplates, dispatch] = useReducer(reducer, []);
	const [searching, setSearching] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [wppTemplate, setWppTemplate] = useState(null);
	const initialized = useRef(false);
	const { user } = useContext(AuthContext);

	const confirmationModalInitialState = {
		id: "",
		open: false,
		action: "",
		title: "",
		message: "",
	};
	const updateModalInitialState = {
		open: false,
		data: null
	};
	const paginationInitialState = {
		total: 0,
		totalPages: 1,
		currentPage: 1,
		quantityPerPage: 10,
	};

	const [confirmModalInfo, setConfirmModalInfo] = useState(confirmationModalInitialState);
	const [updateModalInfo, setUpdateModalInfo] = useState(updateModalInitialState);
	const [pagination, setPagination] = useState(paginationInitialState);

	useEffect(() => {
		setSearching(true);
		if (!initialized.current) {
			initialized.current = true;
			loadWhatsappTemplates(pagination.currentPage, pagination.quantityPerPage);
		}

		const templateEventName = `whatsappTemplate-${user?.companyId}`;
		const templateEventFunction = async (data) => {

			if (data.action === "create") {
				const currentTotal = whatsappTemplates?.length || 0;
				const paginationTotal = pagination?.quantityPerPage || 10;
				if (currentTotal < paginationTotal) dispatch({ type: "UPDATE_WHATSAPP_TEMPLATES", payload: data.whatsappTemplate });
			}

			if (data.action === "update") {
				dispatch({ type: "UPDATE_WHATSAPP_TEMPLATES", payload: data.whatsappTemplate });
				if (data.whatsappTemplate.id == wppTemplate?.id) setWppTemplate(data.whatsappTemplate);
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_WHATSAPP_TEMPLATES", payload: +data.whatsappTemplateId });
			}
		};
		socket.on(templateEventName, templateEventFunction);

		return () => {
			socket.off(templateEventName, templateEventFunction);
		};
	}, []);

	const loadWhatsappTemplates = async (cPage, qttPerPage) => {
		setSearching(true);
		try {
			const { data } = await api.get(`/whatsappTemplate/list/page/${cPage || 1}`, {params: { quantityPerPage: qttPerPage }});
			if (data.whatsappTemplates) dispatch({ type: "LOAD_WHATSAPP_TEMPLATES", payload: data.whatsappTemplates });
			if (data?.currentPage && data?.total && data?.totalPages) {
				setPagination({
					total: data.total,
					totalPages: data.totalPages,
					currentPage: data.currentPage,
					quantityPerPage: qttPerPage
				});
			}
		} catch (err) {
            toastError(err);
		} finally {
			setTimeout(() => {
				setSearching(false);
			}, 500);
		}
	}

	const handleModalOpen = (prop) => {
		setModalOpen(true);
		setWppTemplate(prop);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setWppTemplate(null);
	};

	const handleConfirmModalClose = (open) => {
		setConfirmModalInfo({
			...confirmationModalInitialState,
			open
		});
	}

	const handleConfirmModal = () => {
		// verificar qual rota deverá ser acionada
		switch (confirmModalInfo.action) {
			case 'delete':
				(async () => {
					try {
						const { data } = await api.post('/whatsappTemplate/remove', { id: confirmModalInfo.id });
						setTimeout(() => {
							// limpar informações do modal
							setConfirmModalInfo(confirmationModalInitialState);
                            toast.success(data?.message ? i18n.t(data.message) : i18n.t("generalMessages.SUCCESSFULLY_SAVED"));
						}, 500);

					} catch (err) {
                        toastError(err);
					}
				})();
				break;
			default:
                toast.error(i18n.t('generalMessages.INTERNAL_ERROR'));
				break;
		}
	}

	const handleUpdateModalClose = () => {
		setUpdateModalInfo({
			open: false,
			data: null
		});
	}

	const handlePagination = (event, values) => {
		const page = event.target.value || values;
		setPagination({...pagination, currentPage: page});
		loadWhatsappTemplates(page, pagination.quantityPerPage);
	}

	const handleQuantityPerPage = (event) => {
		const qtt = event?.target?.value || pagination.quantityPerPage;
		setPagination({ ...pagination, quantityPerPage: qtt });
		setTimeout(() => {
			loadWhatsappTemplates(pagination.currentPage, qtt);
		}, 500);
	}

	const renderWhatsappTemplates = () => {
		let rows = [];
		if (searching == true) {
			rows.push(<TableRow key="00">
				<TableCell colSpan={6}>
					<SkeletonList rows={5} />
				</TableCell>
			</TableRow>);
		} else {
			(whatsappTemplates && whatsappTemplates.length >= 1) ? 
                    whatsappTemplates.map(whatsappTemplate => {
                        rows.push(
                            <TableRow key={whatsappTemplate.id}>
                                <TableCell align="left">{whatsappTemplate?.id}</TableCell>
                                <TableCell align="left">{whatsappTemplate?.name}</TableCell>
                                <TableCell align="left">{whatsappTemplate?.status}</TableCell>
                                <TableCell align="left">{whatsappTemplate?.qualityRating}</TableCell>
                                <TableCell align="left">{whatsappTemplate?.lastUpdated}</TableCell>
                                <TableCell align="right">
                                    opt
                                </TableCell>
                            </TableRow>
                        );
                    })
                :
                    rows.push(
                        <TableRow key="notFoundKey">
                            <TableCell colSpan={6} align="center">{i18n.t('connections.whatsapp.template.table.notFound')}</TableCell>
                        </TableRow>
                    );
		}

		return rows;
	}

	return (
		<>
			<Grid container spacing={2} mb={2} mt={2}>
				<Grid item xs={12}>
					<TableContainer component={Paper} {...props} >
						<Table
							sx={{
								minWidth: 500,
								'& td, & th': { maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }
							}}
							aria-label="simple table"
						>
							<TableHead>
								<TableRow>
                                    <TableCell align="left">{i18n.t('connections.whatsapp.template.table.id')}</TableCell>
									<TableCell align="left">{i18n.t('connections.whatsapp.template.table.name')}</TableCell>
                                    <TableCell align="left">{i18n.t('connections.whatsapp.template.table.status')}</TableCell>
                                    <TableCell align="left">{i18n.t('connections.whatsapp.template.table.qualityRating')}</TableCell>
                                    <TableCell align="left">{i18n.t('connections.whatsapp.template.table.lastUpdated')}</TableCell>
									<TableCell align="right">{i18n.t('connections.whatsapp.template.table.actions')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{renderWhatsappTemplates()}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<Grid container spacing={2} mb={2} mt={2}>
				<Grid item xs={12} sm={11}>
					<CustomPagination
						totalPages={pagination?.totalPages || 1}
						currentPage={pagination?.currentPage || 1}
						onChange={handlePagination}
					/>
				</Grid>
				<Grid item xs={12} sm={1}>
					<FormControl fullWidth>
						<InputLabel id="qttPerPageLabel">{i18n.t('connections.whatsapp.template.table.filters.quantity')}</InputLabel>
						<Select
							labelId="qttPerPageLabel"
							id="qttPerPage"
							value={pagination?.quantityPerPage || 10}
							label={i18n.t('whatsappTemplate.table.filters.quantity')}
							onChange={handleQuantityPerPage}
							sx={{height: "40px"}}
						>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={20}>20</MenuItem>
							<MenuItem value={50}>50</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
            <StoreModal 
                open={updateModalInfo.open}
                id={updateModalInfo?.data?.id || null}
                onClose={handleUpdateModalClose}
                templateData={updateModalInfo?.data || null}
            />
		</>
	);
}