import { useContext, useEffect, useReducer, useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import MainContainer from "../../components/MainContainer";

import { KTSVG } from '../../../_metronic/helpers';
import DeleteContactModal from "../../components/DeleteContactModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import UserModal from "../../components/UserModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import socket from "../../hooks/useSocket";
import { Button } from "@material-ui/core";

const reducer = (state, action) => {

  if (action.type === "LOAD_USERS") {
    const users = action.payload;
    const newUsers = [];

    users.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USERS") {
    const user = action.payload;
    const userIndex = state.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    } else {
      return [user, ...state];
    }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;

    const userIndex = state.findIndex((u) => u.id === userId);
    if (userIndex !== -1) {
      state.splice(userIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    minWidth: '465px',
    margin: '20px 10px!important',
		padding: '10px',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));

const Users = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [userToMove, setUserToMove] = useState("");
  const [users, dispatch] = useReducer(reducer, []);

  const { user } = useContext(AuthContext);
  const adminId = user.company.id;

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const abortController = new AbortController();

    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam, pageNumber, limit: 1000 },
            signal: abortController.signal,
          });
          if (!isMounted) return;
          dispatch({ type: "LOAD_USERS", payload: data?.users || [] });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          if (!isMounted || err.name == 'AbortError') return;
          console.error({err});
          toastError(err);
        }
      };
      fetchUsers();
    }, 500);

    return () => {
      isMounted = false;
      abortController.abort();
      clearTimeout(delayDebounceFn);
    };
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const userEventName = 'user';
    const userEventFunction = async (data) => {
      if (data.action === "update" || data.action === "create") {
        if (data?.user?.id != user?.id) {
          dispatch({ type: "UPDATE_USERS", payload: data.user });
        }
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.userId });
      }
    };
    socket.on(userEventName, userEventFunction);

    return () => {
      socket.off(userEventName, userEventFunction);
    };
  }, []);

  const handleOpenUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditUser = (user, admin) => {
    setSelectedUser(user);
    setUserModalOpen(true);
  };

  const handleDeleteUser = async (userId) => {
    setDeletingUser(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  function renderizarLinha(usercoluna) {
    return (
      <tr>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <a className='text-dark fw-bolder text-hover-primary fs-6'>
                {usercoluna.name}
              </a>
            </div>
          </div>
        </td>
        <td>
          <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
            {usercoluna.email}
          </a>
        </td>
        <td>
          <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
            {usercoluna.profile}
          </a>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <button

              title='Editar'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => handleEditUser(usercoluna)}
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
            {/* <button

              title='Excluir'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={(e) => {
                setConfirmModalOpen(true);
                setDeletingUser(usercoluna);
              }}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen027.svg'
                className='svg-icon-3'
              />
            </button> */}
          </div>
        </td>
      </tr>
    )
  }

  function renderizarTabela() {
    var rows = [];
    if (user.profile != "adminSuper") {
      users.map((user) => (
        (user.adminId == adminId && user.profile != "adminSuper" && rows.push(renderizarLinha(user)))
      ))
    } else {
      users.map((user) => (
        rows.push(renderizarLinha(user))
      ))
    }
    return rows;
  }
  return (
    <MainContainer>
      <DeleteContactModal
        title={
          deletingUser &&
          `${i18n.t("users.confirmationModal.deleteTitle")} ${deletingUser.name
          }?`
        }
        open={confirmModalOpen}
        userToDelete={deletingUser?.id}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteUser(deletingUser.id)}
      />
      <UserModal
        open={userModalOpen}
        onClose={handleCloseUserModal}
        aria-labelledby="form-dialog-title"
        userId={selectedUser && selectedUser.id}
        userEditing={user.id}
      />
      {/*<MainHeader>
        <Title>{i18n.t("users.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenUserModal}
          >
            {i18n.t("users.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("users.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("users.table.email")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("users.table.profile")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("users.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {
                renderizarTabela()
              }
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>*/}
      <>
        <div class='row gy-5 gx-xl-8'
          className={classes.modalStyle}
          onScroll={handleScroll}>
          <div className='col-xl-15'>
            <div className='card card-xxl-stretch-5 mb-15 mb-xl-8'>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Usuários</span>
                  {/*<span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span>*/}
                </h3>
                <div
                  className='card-toolbar '
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                >
                  <TextField
                    placeholder={i18n.t("users.search")}
                    type="search"
                    value={searchParam}
                    style={{ marginRight: '10px' }}
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "gray" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenUserModal}
                  >
                    <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Adicionar Usuário
                  </Button>
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-150px'>Nome</th>
                        <th className='min-w-120px'>Email</th>
                        <th className='min-w-120px'>Perfil</th>
                        <th className='min-w-100px text-end'>Ações</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      <>
                        {
                          renderizarTabela()
                        }
                        {loading && <TableRowSkeleton columns={4} />}
                      </>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
              {/* begin::Body */}
            </div>
          </div>
        </div>
      </>
    </MainContainer>
  );
};

export default Users;
