import { useEffect, useState } from "react";

import { FormControlLabel, Select, Switch, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import States from "../../helpers/States";
import api from "../../services/api";
import MultiSelect from '../InputStyled/multiselect';

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},


	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	}

}));

const CompanyModal = ({ open, onClose, companyId }) => {
	const classes = useStyles();
	const initialState = {
		name: "",
		fantasyName: "",
		stateRegistration: "",
		segment: "",
		cnpj: "",
		status: true,
		partnerCustomer: false,
		deactivationAt: "",
		email: "",
		seller: "",
		origin: "",
		representative: "",
		source: "",
		size: "",
		address: "",
		number: "",
		complement: "",
		cep: "",
		district: "",
		city: "",
		uf: "",
		usersManagement: true,
		maxWhatsapps: 1,
		maxUsers: 1,
		modules: [],
	};

	const [successCreate, setSuccessCreate] = useState(false)
	const [successMsgCreate, setMsgSuccessCreate] = useState({})
	const [company, setCompany] = useState(initialState);
	const [valor, setValor] = useState('');
	const [loading, setLoading] = useState(false);
	const [modules, setModules] = useState([]);

	useEffect(() => {

		let isMounted = true;
        const abortController = new AbortController();

		const fetchUser = async () => {
			if (!companyId) {
				setValor("");
				setSuccessCreate("")
				return;
			};
			try {
				setLoading(true);
				setValor("");

				const { data: modulesData } = await api.get(`/companyModules/list`);
				const { data } = await api.get(`/company/${companyId}`, {
					signal: abortController.signal,
				});

				if (!isMounted) return;

				var array = {
					"cnpj": setValor(data.cnpj),
					"fantasyName": data.fantasyName,
					"stateRegistration": data.stateRegistration,
					"segment": data.segment,
					"seller": data.seller,
					"origin": data.origin,
					"fonte": data.fonte,
					"tamansizeho": data.size,
					"address": data.address,
					"number": data.number,
					"complement": data.complement,
					"cep": data.cep,
					"district": data.district,
					"city": data.city,
					"uf": data.uf,
					"representative": data.representative,
					"email": data.email,
					"id": data.id,
					"status": data.status,
					"partnerCustomer": data.partnerCustomer,
					"name": data.name,
					"usersManagement": data.usersManagement,
					"maxWhatsapps": data.maxWhatsapps,
					"maxUsers": data.maxUsers,
					"modules": modulesData.map(module => module.id),
				}

				setCompany(prevState => {
					return { ...prevState, ...array };
				});
			} catch (err) {
				if (!isMounted || err.name == 'AbortError') return;
				console.error({err});
				toastError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchUser();

		const fetchModules = async () => {
			try {
				const { data } = await api.get(`/companyModules/list`, {
					signal: abortController.signal,
				});

				if (!isMounted) return;
				const moduleData = data.map(module => ({
					...module,
					selected: false
				}));
				setModules(moduleData);
			} catch (err) {
				if (!isMounted || err.name == 'AbortError') return;
				console.error({err});
				toastError(err);
			}
		};

		fetchModules();

		return () => {
            isMounted = false;
            abortController.abort();
        };

	}, [companyId, open]);

	const handleClose = () => {
		onClose();
		setCompany({ ...initialState });
	};

	const handleSaveCompany = async values => {
		const CompanyData = { ...values };

		try {
			setLoading(true);
			if (companyId) {
				await api.put(`/company/${companyId}`, CompanyData);
				toast.success('Atualizado com sucesso!');
				handleClose();
			} else {
				await api.post("/company", CompanyData);
				toast.success('Cadastrado com sucesso!');
				setSuccessCreate(true)
				setMsgSuccessCreate({ "emailUser": CompanyData.email, "nameCompany": CompanyData.name })
			}
			// handleClose();
		} catch (err) {
			console.error({err});
			toastError(err);
		} finally {
			setLoading(false);
		}

	};

	const origens = ['VENDA INTERNA', 'VENDA EXTERNA', 'INDICAÇÃO'];

	const seguimentos = ['CALL CENTER', 'COBRANÇA', 'EDUCAÇÃO', 'FINANCEIRO', 'IMOBILIARIO',
		'INDUSTRIA', 'RELIGIOSO', 'SAÚDE', 'SEGUROS', 'SERVIÇO', 'TI/TELECOM',
		'TRANSPORTE', 'TURISMO', 'VAREJO'];

	const fontes = ['CLIENTES', 'ELEV TECNOLOGIA', 'ATENDIMENTO WHATS', 'Conexa Digital',
		'EVENTO MKT', 'GERENTE COMERCIAL', 'MÍDIA',
		'RECEPTIVO GOOGLE', 'RECEPTIVO PARCEIROS', 'RECEPTIVO SITE',
		'REDE SOCIAL', 'REPRESENTANTE'];

	const tamanhos = ['1 ATÉ 10', '11 ATÉ 50', '51 ATÉ 100',
		'101 ATÉ 500', '501 ATÉ 1000', 'ACIMA DE 10001'];

	const mask = (v) => {
		v = v.replace(/\D/g, "")
		v = v.replace(/^(\d{2})(\d)/, "$1.$2")
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
		v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
		v = v.replace(/(\d{4})(\d)/, "$1-$2")
		return v.substring(0, 18)
	}

	function handleChangeMask(event) {
		const { value } = event.target

		setValor(mask(value))
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open == true ? true : false}
				onClose={handleClose}
				maxWidth="md"
				fullWidth

				scroll="paper"

			>
				<DialogTitle id="form-dialog-title">
					{companyId
						? `${'Editar Empresa'}`
						: `${'Cadastrar Empresa'}`}
				</DialogTitle>
				<Formik
					initialValues={company}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							var array = {
								"cnpj": valor,
								"fantasyName": values.fantasyName,
								"stateRegistration": values.stateRegistration,
								"segment": values.segment,
								"seller": values.seller,
								"origin": values.origin,
								"source": values.source,
								"size": values.size,
								"endereco": values.endereco,
								"number": values.number,
								"complement": values.complement,
								"cep": values.cep,
								"district": values.district,
								"city": values.city,
								"uf": values.uf,
								"representative": values.representative,
								"id": values.id,
								"name": values.name,
								"status": values.status,
								"partnerCustomer": values.partnerCustomer,
								"email": values.email,
								"usersManagement": values.usersManagement,
								"maxWhatsapps": values.maxWhatsapps,
								"maxUsers": values.maxUsers,
								"modules": values.modules
							}
							handleSaveCompany(array);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting, setFieldValue }) => (
						<>

							{successCreate ?
								<>
									<DialogContent dividers>
										<div>
											<p>
												A empresa <b>{successMsgCreate?.nameCompany}</b> foi criada com sucesso!<br />
												E-mail Administrador: <b>{successMsgCreate?.emailUser}</b><br />
												Senha:  <b>123456.</b>
											</p>
										</div>
									</DialogContent>
									<DialogActions dividers>
										<Button
											onClick={handleClose}
											color="primary"
											variant="outlined"
										>
											{'FINALIZAR'}
										</Button>
									</DialogActions>
								</>
								:
								<Form style={{ padding: "10px" }}>

									<DialogContent dividers>
										<div className="form-row">
											<Field
												type="hidden"
												name="adminId"
											/>
											<div className="form-group col-md-6">
												<label>Razão social</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Razão social'}
													type="text"
													name="name"
													required

												/>
											</div>

											<div className="form-group col-md-3">
												<label>Email de contato</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'Email de contato'}
													type="email"
													name="email"
													required
												/>
											</div>
											<div className="form-group col-md-3">
												<label>CNPJ</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'00.000.000/0000-00'}
													maxlength="18"
													onChange={handleChangeMask} value={valor}
													type="text"
													name="cnpj"
													required

												/>
											</div>
											<div className="form-group col-md-6">
												<label>Nome fantasia</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Nome fantasia'}
													type="text"
													name="fantasyName"
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Inscrição estadual</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control '
													placeholder={'Inscrição estadual'}
													maxlength="18"
													type="text"
													name="stateRegistration"
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Vendedor</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Vendedor'}
													type="text"
													name="seller"
												/>
											</div>

											<div className="form-group col-md-6">
												<MultiSelect
													title="Módulos"
													displayText="Selecionar módulos"
													disabled={false}
													options={modules.map((module) => { return { key: module.id, value: module.name } })}
													onSelected={function handleModulesSelect(options) {
														setFieldValue("modules", options)
													}}
													selecteds={company?.modules}
												/>
											</div>

											<div className="form-group col-md-6">
												<label>N° Usuários</label>
												<Field
													as={Select}
													native
													style={{
														padding: '6px',
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													type="text"
													name="maxUsers"
												>
													{[...Array(15)].map((nWpp, i) => (
														<>
															<option
																className='fw-bolder text-hover-primary d-block'
																style={{

																	cursor: 'pointer',
																}}
																key={(i + 1).toString()} value={i + 1}>{i + 1} {i == 0 ? 'Usuário' : 'Usuários'}</option>
														</>
													))}
												</Field>
											</div>

											<div className="form-group col-md-6">
												<label>N° Conexões</label>
												<Field
													as={Select}
													native
													style={{
														padding: '6px',
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													type="text"
													name="maxWhatsapps"
												>
													{[...Array(15)].map((nWpp, i) => (
														<>
															<option
																className='fw-bolder text-hover-primary d-block'
																style={{

																	cursor: 'pointer',
																}}
																key={(i + 1).toString()} value={i + 1}>{i + 1} {i == 0 ? 'Conexão' : 'Conexões'}</option>
														</>
													))}
												</Field>
											</div>

											<div className="form-group col-md-4">
												<label>Representante</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Representante'}
													type="text"
													name="representative"
												/>
											</div>

											<div className="form-group col-md-2">
												<label>Segmento</label>
												<Field

													as={Select}
													native
													style={{
														padding: '6px',
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													type="text"
													name="segment"
												>
													{seguimentos.map((seguimentos) => (
														<option
															className='fw-bolder text-hover-primary d-block '
															style={{
																cursor: 'pointer',
															}}
															key={seguimentos} value={seguimentos}>{seguimentos}</option>
													))}
												</Field>
											</div>



											<div className="form-group col-md-2">
												<label>Origem</label>
												<Field

													as={Select}
													native
													style={{
														padding: '6px',
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													type="text"
													name="origin"
												>
													{origens.map((origens) => (
														<option
															className='fw-bolder text-hover-primary d-block '
															style={{
																cursor: 'pointer',
															}}
															key={origens} value={origens}>{origens}</option>
													))}
												</Field>
											</div>

											<div className="form-group col-md-2">
												<label>Fonte</label>
												<Field

													as={Select}
													native
													style={{
														padding: '6px',
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													type="text"
													name="source"
												>
													{fontes.map((fontes) => (
														<option
															className='fw-bolder text-hover-primary d-block '
															style={{
																cursor: 'pointer',
															}}
															key={fontes} value={fontes}>{fontes}</option>
													))}
												</Field>
											</div>


											<div className="form-group col-md-2">
												<label>Tamanho</label>
												<Field

													as={Select}
													native
													style={{
														padding: '6px',
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													type="text"
													name="size"
												>
													{tamanhos.map((tamanhos) => (
														<option
															className='fw-bolder text-hover-primary d-block '
															style={{

																cursor: 'pointer',
															}}
															key={tamanhos} value={tamanhos}>{tamanhos}</option>
													))}
												</Field>
											</div>

											<div className="form-group col-md-6">
												<label>Endereço</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Endereço'}
													type="text"
													name="address"
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Número</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Número'}
													type="text"
													name="number"
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Complemento</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Complemento'}
													type="text"
													name="complement"
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Cep</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'CEP'}
													type="text"
													name="cep"
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Bairro</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Bairro'}
													type="text"
													name="district"
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Cidade</label>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													className='form-control'
													placeholder={'Cidade'}
													type="text"
													name="city"
												/>
											</div>
											<div className="form-group col-md-2">
												<label>UF</label>
												<Field

													as={Select}
													native
													style={{
														padding: '6px',
														borderColor: '#c4c4c4',
														borderRadius: '2px'
													}}
													className='form-control'
													type="text"
													name="uf"
												>
													{States().map((state) => (
														<option
															className='fw-bolder text-hover-primary d-block '
															style={{ cursor: 'pointer' }}
															key={state.uf}
															value={state.uf}
														>
															{state.name}
														</option>
													))}
												</Field>
											</div>
											<FormControlLabel
												style={{ marginTop: '0px' }}
												control={
													<Field
														as={Switch}
														color="primary"
														name="partnerCustomer"
														checked={values.partnerCustomer}

													/>
												}
												label={
													<Typography component="span">
														<b>Cliente parceiro ?</b>
													</Typography>
												}
												labelPlacement="end"
											/>
										</div>
										<div className={classes.multFieldLine}>
											<FormControl
												variant="outlined"
												className={classes.formControl}
												margin="dense"
											>
											</FormControl>
										</div>
									</DialogContent>
									<DialogActions>
										<Button
											onClick={handleClose}
											color="secondary"
											disabled={loading}
											variant="outlined"
										>
											{'cancelar'}
										</Button>
										<Button
											type="submit"
											color="primary"
											disabled={loading}
											variant="contained"
											className={classes.btnWrapper}
										>
											{companyId
												? `${'Salvar'}`
												: `${'Cadastrar'}`}
											{loading && (
												<CircularProgress
													size={24}
													className={classes.buttonProgress}
												/>
											)}
										</Button>
									</DialogActions>
								</Form>
							}
						</>
					)}
				</Formik>

			</Dialog>
		</div>
	);
};

export default CompanyModal;
