import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Broadcast from "../../components/Broadcast";
import Ticket from "../../components/Ticket";
import Schedule from "../../components/Schedule";
import TicketsManager from "../../components/TicketsManager";
import { i18n } from "../../translate/i18n";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import Logo from "../../assets/logo_login.png";
const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    height: `100%`,
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    width: 'calc(1vh - 5px)'
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    paddingTop: "22%",
    backgroundColor: "#eee",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentMarginTop: {
    marginTop: '5px'
  }
}));

document.onkeydown = function (e) {
  if (e.key === 'Escape') {
    const btnClose = document.querySelector('#btnCloseTicketOnHeader');
    if (btnClose) btnClose.click();
  }
}

const Chat = () => {
  const classes = useStyles();
  const { ticketId, broadcastId, contactId } = useParams();
  const { user } = useContext(AuthContext);
  const { adminId } = user;

  const RenderPage = ({ ticketId, broadcastId, contactId }) => {
    if (broadcastId) return <Broadcast />;
    else if (contactId) return <Schedule />;
    else return <Ticket ticketId={ticketId} />;
  };

  useEffect(() => {
    const initializeOneSignal = async () => {
      if (!window.OneSignal) {
        console.error("OneSignal não foi carregado corretamente.");
        return;
      }
  
      // Função para detectar mobile/PWA
      const isTargetDevice = () => {
        // 1. Verifica se é PWA instalado
        const isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                     window.navigator.standalone ||
                     document.referrer.includes('android-app://');
  
        // 2. Verifica userAgent para mobile
        const ua = navigator.userAgent.toLowerCase();
        const isAndroid = ua.includes('android');
        const isIOS = /iphone|ipad|ipod/.test(ua);
  
        // 3. Verifica se é Safari no iOS (que tem comportamento especial)
        const isSafari = /safari/.test(ua) && !/chrome|crios/.test(ua);
  
        // 4. Casos especiais para Chrome Android e Safari iOS
        const isChromeAndroid = isAndroid && ua.includes('chrome');
        const isSafariIOS = isIOS && isSafari;
  
        console.log({
          isPWA, isAndroid, isIOS, isSafari, isChromeAndroid, isSafariIOS,
          userAgent: navigator.userAgent
        });
  
        return isPWA || isChromeAndroid || isSafariIOS;
      };
  
      try {
        await window.OneSignal.init({
          appId: "09905d03-3f3f-4c28-9e30-f180c13344c3",
          allowLocalhostAsSecureOrigin: true,
          notifyButton: { enable: true }
        });
  
        if (isTargetDevice()) {
          const externalUserId = user?.id?.toString();
          
          if (externalUserId) {
            await window.OneSignal.login(externalUserId);
            console.log("OneSignal configurado com sucesso para:", {
              platform: navigator.platform,
              userAgent: navigator.userAgent,
              externalUserId
            });
          }
  
          //const playerId = await window.OneSignal.User.pushSubscription?.id;
          if (externalUserId) {
            sendUserIdToBackend(externalUserId);
            console.log("PlayerID enviado ao backend:", externalUserId);
          }
  
          await window.OneSignal.User.addTag("platform", "mobile");
        } else {
          console.log("Dispositivo não compatível. UserAgent:", navigator.userAgent);
        }
      } catch (error) {
        console.error("Erro no OneSignal:", {
          error: error.message,
          stack: error.stack
        });
      }
    };
  
    const timer = setTimeout(initializeOneSignal, 1000);
    return () => clearTimeout(timer);
  }, []);

  const sendUserIdToBackend = async (userId) => {
        try {

			const { response } = await api.post(`/registerOneSignal/${userId}`, {});
			
            console.log("User ID registrado com sucesso no backend.");
        } catch (error) {
            console.error(error);
        }
  };

  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0} id="ticketPage">
          <Grid
            item
            xs={12}
            md={4}
            className={
              ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
            }
          >
            <TicketsManager />
          </Grid>
          <Grid item xs={12} md={8} className={classes.messagessWrapper}>
            {(ticketId || broadcastId || contactId) ? (
              <RenderPage
                ticketId={ticketId}
                broadcastId={broadcastId}
                contactId={contactId}
              />
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Paper className={classes.welcomeMsg}>
                    <img
                      src={Logo}
                      style={{
                        height: "180px",
                        width: "auto",
                        margin: "0 auto 10px auto",
                        display: "block"
                      }}
                    />
                  <b>{i18n.t("chat.noTicketMessage")}</b>
                </Paper>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Chat;