import { Button, Grid, Table, TableCell, TableHead, TableRow, TableBody, Switch, IconButton } from "@material-ui/core";
import { useState } from "react";
import { i18n } from "../../../translate/i18n";
import { useReducer, useEffect, useContext } from "react";
import socket from "../../../hooks/useSocket";
import { AuthContext } from "../../../context/Auth/AuthContext";
import toastError from "../../../errors/toastError";
import api from "../../../services/api";
import { toast } from "react-toastify";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from "../../ConfirmationModal";
import StatusStoreModal from "../Modals/StatusStoreModal";
import TableRowSkeleton from "../../TableRowSkeleton";
import SingleSelect from "../../InputStyled/singleSelect";

const reducer = (state, action) => {
    const sortByOrder = (list) => list.slice().sort((a, b) => a.order - b.order);

    switch (action.type) {
        case "LOAD_STATUS": {
            const status = action.payload;
            const updatedState = state.map((s) =>
                status.find((stat) => stat.id === s.id) || s
            );
            const newStatus = status.filter(
                (stat) => !state.some((s) => s.id === stat.id)
            );
            return sortByOrder([...updatedState, ...newStatus]);
        }

        case "UPDATE_STATUS": {
            const stat = action.payload;
            const updatedState = state.some((s) => s.id === stat.id)
                ? state.map((s) => (s.id === stat.id ? stat : s))
                : [stat, ...state];
            return sortByOrder(updatedState);
        }

        case "DELETE_STATUS": {
            const statusId = action.payload;
            const updatedState = state.filter((s) => s.id !== statusId);
            return sortByOrder(updatedState);
        }

        case "RESET":
            return [];

        default:
            return state;
    }
};


export default function StatusTab({ data, setData }) {

    const [statusModal, setStatusModal] = useState(false);
    const [status, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const adminId = user.adminId;
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    useEffect(() => {
        const statusEventName = `customerRelationshipStatus-${adminId}`;
        const statusEventFunction = async (data) => {

            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_STATUS", payload: data.status });
            }

            if (data.action === "delete") {
                dispatch({
                    type: "DELETE_STATUS",
                    payload: +data.statusId,
                });
            }
        };
        socket.on(statusEventName, statusEventFunction);

        return () => {
            socket.off(statusEventName, statusEventFunction);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        let isMounted = true;
        const abortController = new AbortController();

        const delayDebounceFn = setTimeout(() => {
            const fetchTags = async () => {
                try {
                    const { data } = await api.get("/customerRelationship/status/list", {
                        signal: abortController.signal,
                    });
                    if (!isMounted) return;
                    dispatch({ type: "LOAD_STATUS", payload: data.status });
                    setLoading(false);
                } catch (err) {
                    if (!isMounted || err.name == 'AbortError') return;
                    console.error({ err });
                    toastError(err);
                }
            };
            fetchTags();
        }, 500);

        return () => {
            isMounted = false;
            abortController.abort();
            clearTimeout(delayDebounceFn);
        };
    }, []);


    const handleOriginModalClose = () => {
        setSelectedStatus(null);
        setStatusModal(false);
    }

    const handleStatus = async (originData) => {
        try {
            setLoading(true);
            await api.put(`/customerRelationship/status/update/${originData.id}`, originData);
            toast.success(i18n.t('backendSuccess.SAVED_SUCCESSFULLY'));
            setLoading(false);
        } catch (err) {
            console.error({ err });
            toastError(err);
            setLoading(false);
        }
    };

    const handleOrder = async (statusId, toOrder) => {
        try {
            setLoading(true);
            await api.post(`/customerRelationship/status/updateIndex`, { statusId, toOrder });
            toast.success(i18n.t('backendSuccess.SAVED_SUCCESSFULLY'));
            setLoading(false);
        } catch (err) {
            console.error({ err });
            toastError(err);
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await api.delete(`/customerRelationship/status/delete/${selectedStatus.id}`);
            toast.success(i18n.t('backendSuccess.DELETED_SUCCESSFULLY'));
            setLoading(false);
        } catch (err) {
            console.error({ err });
            toastError(err);
            setLoading(false);
        }
    }

    const handleConfirmModalClose = () => {
        setSelectedStatus(null);
        setConfirmModalOpen(false);
    }

    return (
        <>
            <Grid container spacing={5}>
                <Grid item sm={12} xs={12} mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: 'right' }}
                        onClick={() => { setStatusModal(true) }}
                    >
                        {i18n.t('buttons.add')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={5}>
                <Grid item sm={12} xs={12} mb={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{i18n.t("customerRelationship.configuration.status.table.order")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.status.table.name")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.status.table.status")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.status.table.editedBy")}</TableCell>
                                <TableCell>{i18n.t("customerRelationship.configuration.status.table.actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading == true
                                    ?
                                    <TableRowSkeleton columns={4} />
                                    :
                                    status.map((stat) => (
                                        <TableRow key={stat.id}>
                                            <TableCell>
                                                <SingleSelect 
                                                    title=""
                                                    displayText="Selecione"
                                                    options={status.map((s, index) => { return { key: index + 1, value: String(index + 1) } })}
                                                    disabled={false}
                                                    onSelected={(o) => handleOrder(stat.id, o)}
                                                    selected={stat.order}
                                                    closeOnSelect={true}
                                                    style={{
                                                        width: "60px",
                                                        minWidth: "60px"
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                            >
                                                {stat?.name ? `${stat.name}` : ''}
                                            </TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={stat.status}
                                                    onChange={() => handleStatus({ ...stat, status: !stat.status })}
                                                    disabled={String(stat?.name).toLowerCase() == 'sem status'}
                                                    color="primary"
                                                    name={`status${stat.status}`}
                                                    key={`status${stat.status}`}
                                                    inputProps={{ 'aria-label': 'handle status' }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                            >
                                                {stat.user?.name || ''}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    disabled={String(stat?.name).toLowerCase() == 'sem status'}
                                                    onClick={() => {
                                                        setSelectedStatus(stat);
                                                        setStatusModal(true);
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="secondary"
                                                    component="span"
                                                    disabled={String(stat?.name).toLowerCase() == 'sem status'}
                                                    onClick={() => {
                                                        setSelectedStatus(stat);
                                                        setConfirmModalOpen(true);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <StatusStoreModal
                open={statusModal}
                onClose={handleOriginModalClose}
                statusId={selectedStatus?.id || undefined}
            />
            <ConfirmationModal
                title={i18n.t("customerRelationship.configuration.status.delete.title")}
                open={confirmModalOpen}
                onClose={handleConfirmModalClose}
                onConfirm={handleDelete}
            >
                {i18n.t("customerRelationship.configuration.status.delete.description")}
            </ConfirmationModal>
        </>
    );
}