import { useContext, useState, useEffect, useRef } from "react";

import { Avatar, Card, CardContent, Container, Stack, SvgIcon, Tooltip } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

import { Groups } from "@mui/icons-material";
import GroupsIcon from '@mui/icons-material/Groups';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ChatIcon from '@mui/icons-material/Chat';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import ThreePIcon from '@mui/icons-material/ThreeP';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SendIcon from '@material-ui/icons/Send';

import { grey, blue } from "@material-ui/core/colors";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	container: {
		margin: '20px 10px!important',
		padding: '10px',
		background: '#fff',
		borderRadius: '10px',
		width: 'calc(100% - 20px)!important',
		maxWidth: '100%!important',
		minHeight: 'calc(100vh - 100px)'
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "500px",
		overflow: "hidden"
	},
	overline: {
		fontSize: '0.9rem',
		fontWeight: 700,
		color: "grey",
		letterSpacing: '0.5px',
		lineHeight: 2.5,
		textTransform: 'uppercase',
		fontFamily: "'Plus Jakarta Sans', sans-serif'",
	},
	h4: {
		fontFamily: "'Plus Jakarta Sans', sans-serif'",
		fontWeight: 500,
		fontSize: '2rem',
		lineHeight: 1,
		color: "grey",
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		flexDirection: "column",
		height: 240,
		overflowY: "auto",
		...theme.scrollbarStyles,
	},
	cardAvatar: {
		fontSize: "55px",
		color: grey[500],
		backgroundColor: "#ffffff",
		width: theme.spacing(7),
		height: theme.spacing(7),
	},
	cardTitle: {
		fontSize: "18px",
		color: blue[700],
	},
	cardSubtitle: {
		color: grey[600],
		fontSize: "14px",
	},
	alignRight: {
		textAlign: "right",
	},
	fullWidth: {
		width: "100%",
	},
	selectContainer: {
		width: "100%",
		textAlign: "left",
	},
	iframeDashboard: {
		width: "100%",
		height: "calc(100vh - 64px)",
		border: "none",
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
	fixedHeightPaper2: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
}));

const Dashboard = () => {

	const { user } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [options, setOptions] = useState([]);
	const [onlineUsersCount, setOnlineUsersCount] = useState(0);
	const [totalUsersCount, setTotalUsersCount] = useState(0);
	const [updatedAt, setUpdatedAt] = useState("");

	const classes = useStyles();

	useEffect(() => {

		let isMounted = true;
        const abortController = new AbortController();

		const fetchTickets = async () => {
			setLoading(true);
			try {
				const { data } = await api.get("/dashboard", {
					signal: abortController.signal,
				});
				if (!isMounted) return;
				setTickets(data);
				setUpdatedAt(new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }));
			} catch (err) {
				if (!isMounted || err.name == 'AbortError') return;
				console.error({err});
				toastError(err);
			} finally {
				setLoading(false);
			}
		};

		const fetchUsers = async () => {
			setLoading(true);
			try {
				const { data } = await api.get("/usersList/", {
					signal: abortController.signal,
				});
				if (!isMounted) return;
				setOptions(data);
	
				// Armazenar a quantidade total de usuários
				setTotalUsersCount(data.length);
	
				// Armazenar a quantidade de usuários com estado "online"
				const onlineCount = data.filter(user => user.state === "online").length;
				setOnlineUsersCount(onlineCount);
	
			} catch (err) {
				if (!isMounted || err.name == 'AbortError') return;
				console.error({err});
				toastError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchTickets();
		fetchUsers();

		const intervalId = setInterval(() => {
			fetchTickets();
			fetchUsers();
		}, 60000);

		return () => {
            isMounted = false;
            abortController.abort();
			clearInterval(intervalId);
        };
	}, []);
	
	return (
		<Container
			className={classes.container}
		>
			<Grid xs={12} sm={6}></Grid>
			<Typography style={{ margin: '20px 0px 20px 0px', color: 'black', fontWeight: 'bold' }} component="h1" variant="h5" gutterBottom>
				{`${i18n.t("dashboard.title")} ${user.name}!`}
			</Typography>
			<Typography style={{ marginBottom: "20px", color: "#9e9e9e" }}>{`${i18n.t("dashboard.updatedAt")} ${updatedAt}`}</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Container maxWidth="xl">
						<Grid2 container spacing={3}>
							{[
								{
									title: i18n.t("dashboard.cards.activeAttendants"),
									value: `${onlineUsersCount}/${totalUsersCount}`,
									icon: <GroupsIcon />,
									color: '#805753',
								},
								{
									title: i18n.t("dashboard.cards.ticketWithoutAttendants"),
									value: tickets.counters?.ticketWithoutAttendants || 0,
									icon: <ThreePIcon />,
									color: '#8c6b19',
								},
								{
									title: i18n.t("dashboard.cards.unread"),
									value: tickets.counters?.unreadMessages || 0,
									icon: <MarkUnreadChatAltIcon />,
									color: '#01BBAC',
								},
								{
									title: i18n.t("dashboard.cards.inAttendance"),
									value: tickets.counters?.open || 0,
									icon: <QuestionAnswerIcon />,
									color: '#0b708c',
								},
								{
									title: i18n.t("dashboard.cards.passiveTickets"),
									value: tickets.counters?.receptiveTickects || 0,
									icon: <QuestionAnswerIcon />,
									color: '#28C037',
								},
								{
									title: i18n.t("dashboard.cards.averageServiceTime"),
									value: tickets.average?.averageTimeTicket || "00h 00m",
									icon: <AlarmOnIcon />,
									color: '#F79009',
								},
								{
									title: i18n.t("dashboard.cards.waiting"),
									value: tickets.counters?.pending || 0,
									icon: <PauseCircleFilledOutlinedIcon />,
									color: '#47606e',
								},
								{
									title: i18n.t("dashboard.cards.chatbotTickets"),
									value: tickets.counters?.openChatbot || 0,
									icon: <SmartToyOutlinedIcon />,
									color: '#8a2c40',
								},
								{
									title: i18n.t("dashboard.cards.finalized"),
									value: tickets.counters?.closed || 0,
									icon: <TaskAltIcon />,
									color: '#5852ab',
								},
								{
									title: i18n.t("dashboard.cards.totalReceivedMessages"),
									value: tickets.counters?.receivedMessages || 0,
									icon: <ChatIcon />,
									color: '#333133',
								},
								{
									title: i18n.t("dashboard.cards.totalSentMessages"),
									value: tickets.counters?.sentMessages || 0,
									icon: <SendIcon />,
									color: '#558a59',
								},
								{
									title: i18n.t("dashboard.cards.activeTickets"),
									value: tickets.counters?.campaign || 0,
									icon: <ScheduleSendIcon />,
									color: '#EE4512',
								},
							].map((card, index) => (
								<Grid2 key={index} xs={12} sm={6} lg={4}>
									<Card sx={{ height: "100%", backgroundColor: "transparent" }}>
										<CardContent>
											<Stack
												alignItems="flex-start"
												direction="row"
												justifyContent="space-between"
												spacing={3}
											>
												<Stack spacing={1}>
													<Typography
														color="primary"
														variant="overline"
														className={classes.overline}
													>
														{card.title}
													</Typography>
													<Typography variant="h4" className={classes.h4}>
														{card.value}
													</Typography>
												</Stack>
												<Avatar sx={{ backgroundColor: card.color, height: 60, width: 60 }}>
													<SvgIcon>{card.icon}</SvgIcon>
												</Avatar>
											</Stack>
										</CardContent>
									</Card>
								</Grid2>
							))}
						</Grid2>
					</Container>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Dashboard;
