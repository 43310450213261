import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useTickets = ({
	searchParam,
	tagsFilter,
	pageNumber,
	status,
	date,
	updatedAt,
	showAll,
	queueIds,
	withUnreadMessages,
}) => {
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [contacts, setContacts] = useState([]);

	useEffect(() => {

		let isMounted = true;
        const abortController = new AbortController();

		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTickets = async () => {
				try {
					const { data } = await api.get("/tickets", {
						params: {
							searchParam,
							pageNumber,
							status,
							date,
							updatedAt,
							showAll,
							queueIds,
							withUnreadMessages,
						},
						signal: abortController.signal,
					});

					if (!isMounted) return;
					// caso seja paginação, concatenar atendimentos já carregados com os novos
					if (pageNumber > 1) setTickets([...tickets, ...data.tickets]);
					else setTickets(data.tickets);

					setHasMore(data.hasMore);
					setContacts(data.contacts);
					setLoading(false);
				} catch (err) {
					if (!isMounted || err.name == 'AbortError') return;
					console.error({err});
					setLoading(false);
					toastError({ response: { data: { error: err?.message || 'INTERNAL_ERROR' } } });
				}
			};
			fetchTickets();
		}, 500);

		return () => {
            isMounted = false;
            abortController.abort();
			clearTimeout(delayDebounceFn);
        };
	}, [
		searchParam,
		tagsFilter,
		pageNumber,
		status,
		date,
		updatedAt,
		showAll,
		queueIds,
		withUnreadMessages,
	]);

	return { tickets, loading, hasMore, contacts, setLoading };
};

export default useTickets;
