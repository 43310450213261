// Importações do React e hooks
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// Importações do Material-UI
import { Button, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";

// Importações de ícones
import AddCommentIcon from '@mui/icons-material/AddComment';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import SearchIcon from "@material-ui/icons/Search";
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import AlarmOutlinedIcon from '@material-ui/icons/AlarmOutlined';

// Importações de componentes e contexto
import ContactModal from "../ContactModal";
import NewTicketModal from "../NewTicketModal";
import TabPanel from "../TabPanel";
import TicketSearch from "../TicketSearch/TicketSearch";
import TicketsList from "../TicketsList";
import TicketListScheduleMessages from "../TicketListScheduleMessages";
import { AuthContext } from "../../context/Auth/AuthContext";

// Importações de utilitários e serviços
import api from "../../services/api";
import FilteredTickets from "../../helpers/FilteredTickets";
import socket from "../../hooks/useSocket";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

// Importações de recursos
import profileNoPic from "../../assets/blank.png";
import alertSound from "../../assets/sound.mp3";

// Importações de bibliotecas
import format from "date-fns/format";
import useSound from "use-sound";
import JsonParse from "../../helpers/JsonParse";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    display: "flex",
    backgroundColor: "#eee",
    padding: "16px",
    alignItems: " center"
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: '21%',
    width: '21%',
    fontSize: '9px',
    height: '63px',
    minHeight: '35px !important'
  },

  ticketOptionsBox: {
    marginTop: 5,
    marginLeft: 10,
    height: 30,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: '0px 0px 0px 0px'
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
    marginLeft: 10
  },
  atendimentoUsuario: {
    display: "flex",

  },
  buttonAdd: {
    border: "none",
    padding: 0,
    position: "absolute",
    right: "0px",
    color: '#0B708C'
  },
  buttonBroadcast: {
    border: "none",
    padding: 0,
    right: "5px",
    position: "absolute",
  },
  buttonAddMenu: {
    '@media(min-width: 1500px)': {
      display: 'none !important'
    }
  },
}));


function setPageFind() {
  var path = window.location.pathname
  var tab = 'open'
  if (path.includes('broadcast')) {
    tab = 'broadcast'
  } if (path.includes('schedule')) {
    tab = 'scheduleMessage'
  }
  return tab;
}

const TicketsManager = ({ handleDrawerClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState(setPageFind());
  const [newContact, setNewContact] = useState({});
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { adminId } = user;
  const [selectedQueueIds, setSelectedQueueIds] = useState(user.queues.filter(q => q.name != 'FILA_CHATBOT').map(q => q.id) || []);
  const [tags, setTags] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [notificationTicketIds, setNotificationTicketIds] = useState([]);
  const [play] = useSound(alertSound);
  const soundAlertRef = useRef();
  const historyRef = useRef(history);

  let connections = [];
  if (user?.queues) {
    for (const q of user.queues) {
      for (const w of q.Whatsapps) {
        if (w?.state != true) continue;
        const idx = connections.findIndex(conn => conn.id == w.id);
        if (idx == -1) connections.push(w);
      }
    }
  }

  let searchTimeout;
  const handleSearch = (searchedTerm) => {

    clearTimeout(searchTimeout);
    if (!searchedTerm || searchedTerm == "" || searchedTerm == 'undefined') {
      setSearchParam('');
      setTab("open");
    } else {
      searchedTerm = searchedTerm.toLowerCase();
      setTab("search");
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 750);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  }

  const handleCloseContactModal = (ticket) => {
    setContactModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const createNewContact = (contact) => {
    setNewContact({ name: contact });
    setContactModalOpen(true);
  }

  const handleAddNewContactTicket = contact => {
    handleSaveTicket(contact.id);
    setSearchParam("");
  };

  const handleSaveTicket = async contactId => {
    if (!contactId) return;
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        queueId: null,
        userId: user.id,
        status: "open",
      });
      handleCloseContactModal(ticket);
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  };

  function primeiraLetraMaiuscula(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSelectedQueueId = (queueId) => {
    const btnClose = document.querySelector('#btnCloseTicketOnHeader');
    if (btnClose) btnClose.click();
    let queueIds = selectedQueueIds || [];
    const index = queueIds.indexOf(queueId);
    if (index == -1) setSelectedQueueIds([...queueIds, queueId]);
    else queueIds = setSelectedQueueIds(queueIds.filter((q) => q != queueId));
  }

  useEffect(() => {

    let isMounted = true;
    const abortController = new AbortController();

    const getTags = async () => {
      try {
        const { data } = await api.get("/tags", {
          signal: abortController.signal,
        });
        if (!isMounted) return;
        return data.tags;
      } catch (error) {
        if (!isMounted || error.name == 'AbortError') return;
        console.error(error);
        throw error;
      }
    }

    const fetchData = async () => {
      try {
        let [tagsData] = await Promise.all([getTags()]);
        if (tagsData) {
          tagsData.unshift({ id: null, name: i18n.t("ticketsSearch.noTags"), status: 1, color: "#000000" });
          tagsData.unshift({ id: 'selectAll', name: i18n.t("ticketsSearch.selectAllTags"), status: 1, color: "#0b708c" });
        }
        setTags(tagsData);
        const tagsMapped = tagsData.map(t => t.id);
        setTagsFilter(tagsMapped);
      } catch (error) { }
    };

    fetchData();

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const elementId = 'tagsFilterHtmlController';
    document.body.dataset[elementId] = JSON.stringify(tagsFilter);

  }, [tagsFilter]);

  useEffect(() => {
    const btnClose = document.querySelector('#btnCloseTicketOnHeader');
    if (btnClose) btnClose.click();
    if (tab != 'search' && tab != 'broadcast') {
      setSearchParam("");
      searchInputRef.current.value = "";
    }
  }, [tab]);

  useEffect(() => {
    if (notificationTicketIds.length > 0) {
      document.title = `(${notificationTicketIds.length}) Conexa Digital`
    } else {
      document.title = "Conexa Digital";
    }
  }, [notificationTicketIds]);

  useEffect(() => {
    soundAlertRef.current = play;

    if (!("Notification" in window)) {
      console.log("This browser doesn't support notifications");
    } else {
      Notification.requestPermission();
    }
  }, [play]);

  useEffect(() => {
    socket.on("connect", () => socket.emit("joinNotification"));

    const ticketEventName = `ticket-${adminId}`;
    const ticketEventFunction = async (data) => {
      // verificar se é uma transferência de atendimento da mesma empresa
      const { action, ticket, transfer } = data;
      if (!action || !ticket || transfer != true) return;
      if (ticket?.whatsapp?.adminId != adminId) return;

      handleNotifications({ message: { body: 'Você recebeu uma transferência...' }, contact: ticket.Contact, ticket });
    };

    const appMessageEventName = `appMessage-${adminId}`;
    const appMessageEventFunction = async (data) => {
      const ticket = data?.ticket || data.Ticket;

      if (
        data.action === "create" &&
        !data.message.read &&
        !data.message.fromMe &&
        adminId == ticket.whatsapp.adminId
      ) {
        handleNotifications(data);
      }
    };

    const userEventName = `user-${user.id}`;
    const userEventFunction = async (data) => {
			const { action } = data;
			if (action == 'addedQueue') {
				setSelectedQueueIds(data.queueIds);
			}
		}

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setNotificationTicketIds([]);
      }
    };

    socket.on(ticketEventName, ticketEventFunction);
    socket.on(appMessageEventName, appMessageEventFunction);
    socket.on(userEventName, userEventFunction);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      socket.off(ticketEventName, ticketEventFunction);
      socket.off(appMessageEventName, appMessageEventFunction);
      socket.off(userEventName, userEventFunction);
    };
  }, []);

  const findInArray = (obj) => {
    const numbers = [];
    obj.map(item => {
      item.Whatsapps.map(w => {
        if (w.numberConnection && w.numberConnection.trim() != '' && !numbers.includes(w.numberConnection.trim())) numbers.push(w.numberConnection.trim());
      })
    });
    return numbers;
  }

  const findQueueId = (obj) => {
    return obj.map(item => item.id);
  }

  const handleNotifications = async (data) => {
    const { message, contact, ticket } = data;
    if (ticket.Queue === null) return;
    if (document.visibilityState == "visible") return;
    if (!user?.adminId || !ticket?.whatsapp?.adminId || user.adminId != ticket.whatsapp.adminId || !localStorage.getItem('token')) return;
    if (ticket?.status == 'closed') return;
    const numbers = findInArray(user.queues);
    const queues = findQueueId(user.queues);
    let tagsTmp = document.body.dataset['tagsFilterHtmlController'];
    tagsTmp = tagsTmp && tagsTmp != '' ? JsonParse(tagsTmp) : [];

    const filteredTickets = FilteredTickets([ticket], tagsTmp, numbers, user, selectedQueueIds);

    if (!filteredTickets || filteredTickets.length == 0) return;
    setNotificationTicketIds(prevState => {
      const ticketIndex2 = prevState.findIndex(ti => ti.id === ticket.id);
      if (ticketIndex2 !== -1) {
        prevState[ticketIndex2] = ticket;
        return [...prevState];
      }
      return [ticket, ...prevState];
    });

    const options = {
      body: `${message.body} - ${format(new Date(), "HH:mm")}`,
      icon: contact.profilePicUrl,
      tag: ticket.uuid,
      renotify: true,
    };

    const notification = new Notification(
      `${i18n.t("tickets.notification.message")} ${contact.name}`,
      options
    );

    notification.onclick = e => {
      e.preventDefault();
      window.focus();
      historyRef.current.push(`/tickets/${ticket.uuid}`);
    };

    soundAlertRef.current();
  };

  return (
    <>
      <ContactModal
        open={contactModalOpen}
        initialValues={newContact}
        onClose={handleCloseContactModal}
        onSave={handleAddNewContactTicket}
        byContactPage={false}
      ></ContactModal>
      <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
        <NewTicketModal
          modalOpen={newTicketModalOpen}
          onClose={(ticket) => { handleCloseOrOpenTicket(ticket); }}
          connections={connections}
          queues={user?.queues || []}
        />
        <Paper
          elevation={0}
          square
          className={classes.tabsHeader}
          style={{
            position: "relative",
            display: "flex"
          }}
        >
          <Avatar style={{ marginBottom: '-5px' }} >
            <img id="picConnection" src={!profileNoPic || profileNoPic == null ? '/media/avatars/blank.png' : profileNoPic} style={{ width: '40px' }}></img>
          </Avatar>
          <div style={{ display: "flex", maxWidth: "calc(100% - 70px)" }}>
            <p id="definirFila" style={{ marginLeft: '15px', marginBottom: "-5px" }}></p>
          </div>
          <IconButton
            className={classes.buttonAdd}
            title="Novo Atendimento"
            style={{
              float: "right"

            }}
            onClick={() => setNewTicketModalOpen(true)}
          >
            <AddCommentIcon style={{
              width: '45px',
              height: '45px',
              padding: '10px',
              "&::MozTransform": 'scaleX(-1)',
              "&::OTransform": 'scaleX(-1)',
              "&::WebkitTransform": 'scaleX(-1)',
              transform: 'scaleX(-1)'
            }} />
          </IconButton>
        </Paper>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Paper square elevation={0} className={classes.ticketOptionsBox}>
            <InputBase
              id="searchTickets"
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onKeyUp={(e) => {
                const key = e.charCode || e.keyCode || 0;
                if (key === 13) {
                  e.preventDefault();
                  handleSearch(e.target.value);
                }
              }}
            />
            {tab !== "search" ? '' :
              <Button onClick={() => handleSearch('')} className="btn btn-default" title="Apagar" type="button" style={{ padding: '5px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </Button>
            }
            <Button style={{ minWidth: "30px", width: "30px" }} onClick={() => handleSearch(searchInputRef.current.value)}>
              <SearchIcon />
            </Button>
          </Paper>
          <TicketSearch
            selectedQueueIds={selectedQueueIds}
            userQueues={user?.queues}
            onChange={(queueId) => handleSelectedQueueId(queueId)}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
          />

        </div>
        <Paper elevation={0} square style={{ padding: '0px', height: '65px' }} className={classes.tabsHeader}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            variant="fullWidth"
            style={{ width: '100%' }}
            indicatorColor="primary"
            textColor="primary"
            aria-label="icon label tabs example"
            id="ticketTabOptions"
          >
            <Tab
              id="openTab"
              value={"open"}
              icon={<MoveToInboxIcon />}
              label={i18n.t("tickets.tabs.open.title")}
              classes={{ root: classes.tab }}
            />
            <Tab
              id="pendingTab"
              value={"pending"}
              icon={<PauseCircleOutlineOutlinedIcon />}
              label={i18n.t("tickets.tabs.pending.title")}
              classes={{ root: classes.tab }}
            />
            <Tab
              id="closedTab"
              value={"closed"}
              icon={<HowToRegOutlinedIcon />}
              label={i18n.t("tickets.tabs.closed.title")}
              classes={{ root: classes.tab }}
            />
            <Tab
              id="scheduleMessageTab"
              value={"scheduleMessage"}
              icon={<AlarmOutlinedIcon />}
              label={i18n.t("tickets.tabs.schedule.title")}
              classes={{ root: classes.tab }}
            />
            <Tab
              id="searchTab"
              value={"search"}
              icon={<AlarmOutlinedIcon />}
              label={i18n.t("tickets.tabs.schedule.title")}
              hidden={true}
            />
          </Tabs>
        </Paper>
        <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            selectedStatus={tab}
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            usuarioId={user.id}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
            searchParam={searchParam}
          />
        </TabPanel>
        <TabPanel value={tab} name="pending" className={classes.ticketsWrapper}>
          <TicketsList
            status="pending"
            selectedStatus={tab}
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            usuarioId={user.id}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
            searchParam={searchParam}
          />
        </TabPanel>
        <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
          <TicketsList
            status="closed"
            selectedStatus={tab}
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
            searchParam={searchParam}
          />
        </TabPanel>
        <TabPanel value={tab} name="scheduleMessage" className={classes.ticketsWrapper}>
          <TicketListScheduleMessages />
        </TabPanel>
        {/*
        <TabPanel value={tab} name="broadcast" className={classes.ticketsWrapper}>
          <BroadcastList />
        </TabPanel>
        */}
        <TabPanel
          value={tab} name="search"
          className={classes.ticketsWrapper}
          style={{
            width: '100%',
            display: searchParam && searchParam.length > 2 ? 'block' : 'none'
          }}
        >
          <div style={{
            padding: '10px',
            border: 'solid 1px #ebebeb',
          }}>
            <Button onClick={e => createNewContact(primeiraLetraMaiuscula(searchParam))} color="primary">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-rolodex" viewBox="0 0 16 16">
                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
              </svg>
              &nbsp;
              {i18n.t('buttons.add')} {searchParam}
            </Button>
          </div>

          <TicketsList
            searchParam={searchParam}
            selectedStatus={tab}
            showAll={true}
            selectedQueueIds={selectedQueueIds}
            tags={tags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
          />
        </TabPanel>
      </Paper>
    </>
  );
};

export default TicketsManager;