import { Button, Checkbox, CircularProgress, Menu, MenuItem } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FormControlLabel, Skeleton } from "@mui/material";
import 'date-fns';
import { format, isSameDay, parseISO } from "date-fns";
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import TicketsTagSelect from '../../components/TicketsTagSelect';
import GraphRelatory from '../../components/WidgetsRelatory/widgetRelatoryTags';
import toastError from "../../errors/toastError";
import api from '../../services/api';
import { i18n } from "../../translate/i18n";
import RenderChatRelatory from '../../components/RenderChatRelatory';
import * as XLSX from 'xlsx';

const isMobile = Number(window.innerWidth) < 600 ? true : false;

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: "#ffffff",
		boxShadow: "0px 0px 20px 0px rgba(76, 87, 125, 0.02)",
		marginBottom: "2rem",
		backgroundClip: "border-box",
		borderRadius: "0.625rem",
		userSelect: "none"
	},
	mainContainer: {
		maxWidth: "100vw",
		margin: '20px 10px!important',
		padding: '10px',

	},
	title: {
		fontSize: "1.3rem",
		fontWeight: "800",
		color: "#616161"
	},
	subtitle: {
		fontSize: "1.1rem",
		marginTop: "5px",
		marginBottom: "20px",
		display: "block",
		color: "#9e9e9e",
		fontWeight: "600"
	},
	tagContainer: {
		backgroundColor: "#f5f5f5",
		padding: "5px",
		borderRadius: "5px",
		minHeight: "50px",
		boxShadow: "0px 0px 20px 0px rgba(76, 87, 125, 0.01)",
		position: "relative",
		marginBottom: "10px",
		cursor: "pointer",
	},
	tagColor: {
		backgroundColor: "#000",
		padding: "6px",
		width: "50px",
		borderRadius: "30px",
		position: "absolute",
		bottom: "5px",
		left: "5px"
	},
	tagTitle: {
		float: "left",
		fontSize: "1.1rem",
		fontWeight: "600",
		color: "#616161",
		textTransform: "capitalize"
	},
	tagQuantity: {
		float: "right",
		fontSize: "1.3rem",
		fontWeight: "600",
		marginRight: "10px",
		color: "#616161"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	filterButtons: {
		width: !isMobile ? "max-content" : "100%",
		minWidth: "120px",
		height: "35px",
		color: "rgba(0, 0, 0, .8)",
		paddingRight: "30px",
		fontStyle: "italic",
		display: "inline-block",
		marginBottom: !isMobile ? "0" : "10px",
	},
	filterButtonsIcon: {
		position: "absolute",
		right: "2px",
		color: "rgba(0,0,0,.55)"
	},
	filterContainer: {
		float: !isMobile ? "right" : "none",
		marginRight: !isMobile ? "10px" : "0",
		display: !isMobile ? "inline-flex" : "block",
		marginTop: !isMobile ? "0" : "30px",
		"& .MuiBox-root": {
			margin: "0!important",
			display: "flex"
		}
	},
	filterDateButtons: {
		border: "1px solid rgba(0, 0, 0, 0.23)",
		padding: "5px 15px",
		minHeight: "35px",
		borderRadius: "4px",
		width: isMobile ? "100%" : "auto"
	},
	filterButtonsContainer: {
		display: "inline-block!important",
		paddingRight: isMobile ? "0" : "10px!important",
		width: isMobile ? "100%" : "auto"
	},
	modalTicketsContainer: {
		position: "relative",
		display: "flex",
	    width: "100%",
		minWidth: "500px",
		marginBottom: "10px",
		padding: "10px 10px 10px 15px",
		backgroundColor: "#f5f5f5",
		borderRadius: "4px",
		height: "85px",
		boxShadow: "0px 0px 20px 0px rgba(76, 87, 125, 0.01)",
		userSelect: "none",
		cursor: "pointer",
		"& .MuiListItemAvatar-root": {
			display: "inline-block"
		},
		"& h6": {
			fontSize: "1.1rem"
		},
		"& .MuiTypography-subtitle1": {
			fontSize: "0.95rem"
		},
		"& .MuiTypography-subtitle2": {
		    position: "absolute",
			bottom: "5px",
			background: "#009688",
			padding: "3px 7px",
			borderRadius: "4px",
			fontSize: '0.9rem',
			right: "10px",
			minWidth: "50px",
			textAlign: "center",
			color: "#fff"
		}
	},
	modalTicketsQueueColor: {
		borderRadius: "4px",
		height: "100%",
		width: "10px",
		position: "absolute",
		top: "0",
		left: "0"
	},
	modalTicketsContainerBody: {
		display: "inline-block",
		height: "100%",
		width: "100%"
	}
}));

const RelatoryTags = () => {
	const classes = useStyles();
	const [tagsFilter, setTagsFilter] = useState([]);
	const [tags, setTags] = useState([]);
	const [selectedConnectionsIds, setSelectedConnectionsIds] = useState([]);
	const [connections, setConnections] = useState([]);
	const [queues, setQueues] = useState([]); // filas sem filtro buscadas na base
	const [selectedQueuesIds, setSelectedQueuesIds] = useState([]);
	const [filteredQueues, setFilteredQueues] = useState([]); // filas filtradas para mostrar no select
	const [connectionAnchorEl, setConnectionAnchorEl] = useState(null);
	const [queueAnchorEl, setQueueAnchorEl] = useState(null);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [searchingData, setSearchingData] = useState(false);
	const [exportingData, setExportingData] = useState(false);
	const [generalTags, setGeneralTags] = useState(null);
	const [generalTagsTotal, setGeneralTagsTotal] = useState(null);
	const [updatedAt, setUpdatedAt] = useState(null);
	const [open, setOpen] = useState(null);
	const [pending, setPending] = useState(null);
	const [finished, setFinished] = useState(null);
	const [modalOpen, setModalOpen] = React.useState(false);
	const [modalTitle, setModalTitle] = useState(null);
	const [modalTickets, setModalTickets] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const initialChatModal = { title: "", id: null };
	const [chatModalRelatoryInfo, setChatModalRelatoryInfo] = useState(initialChatModal);
	const [openChatRelatory, setOpenChatRelatory] = useState(false);

	useEffect(() => {
		let isMounted = true;
		const load = async() => {
			if (!isMounted) return;
			setSearchingData(true);
			let tgIds = await getTags();
			let quIds = await getQueues();
			let cnIds = await getConnections();
			let stDate = formatDate1Week();
			let edDate = new Date().toISOString().split("T")[0];
			setStartDate(stDate);
			setEndDate(edDate);
			await searchData(
				stDate,
				edDate,
				tgIds.filter(id => String(id).toLowerCase() != 'selectall'),
				cnIds.filter(id => String(id).toLowerCase() != 'selectall'),
				quIds.filter(id => String(id).toLowerCase() != 'selectall')
			);
			setSearchingData(false);
		}
		load();

		return () => {
			isMounted = false;
		}
	}, []);

	const handleConnectionClick = (event) => {
		setConnectionAnchorEl(event.currentTarget);
	};

	const handleConnectionClose = () => {
		setConnectionAnchorEl(null);
	};

	const handleConnectionSelection = (connId) => {

		// verificar se é selecionar todos
		if (String(connId).toLowerCase() == 'selectall') {
			// verificar se é para selecionar ou deselecionar
			if (!selectedConnectionsIds.includes(connId)) {
				// selecionar
				const connectionsMapped = connections.map(t => t.id);
				setSelectedConnectionsIds(connectionsMapped);
			} else {
				// deselecionar
				setSelectedConnectionsIds([]);
			}
			return;
		}

		if (selectedConnectionsIds.includes(connId)) {
			setSelectedConnectionsIds(selectedConnectionsIds.filter(id => id !== connId));
		} else {
			setSelectedConnectionsIds([...selectedConnectionsIds, connId]);
		}
	};

	const handleQueueClick = (event) => {
		setQueueAnchorEl(event.currentTarget);
	};

	const handleQueueClose = () => {
		setQueueAnchorEl(null);
	};

	const handleQueuesSelection = (queueId) => {

		// verificar se é selecionar todos
		if (String(queueId).toLowerCase() == 'selectall') {
			// verificar se é para selecionar ou deselecionar
			if (!selectedQueuesIds.includes(queueId)) {
				// selecionar
				const connectionsMapped = queues.map(t => t.id);
				setSelectedQueuesIds(connectionsMapped);
			} else {
				// deselecionar
				setSelectedQueuesIds([]);
			}
			return;
		}

		if (selectedQueuesIds.includes(queueId)) {
			setSelectedQueuesIds(selectedQueuesIds.filter(id => id !== queueId));
		} else {
			setSelectedQueuesIds([...selectedQueuesIds, queueId]);
		}
	};

	const formatDate1Week = () => {
		var last_week = new Date();
		last_week.setDate(last_week.getDate() - 8);
		return new Date(last_week).toISOString().split("T")[0]
	}

	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setTimeout(() => {
			setModalTitle(null);
			setModalTickets(null);
		}, 400);
	};

	const handleChatModal = (status, props) => {
		if (status == true) {
			setOpenChatRelatory(true);
			setChatModalRelatoryInfo(props);
		}
		else {
			setOpenChatRelatory(false);
			setChatModalRelatoryInfo(initialChatModal);
		}
	}

	async function getTags() {
		try {
			let { data } = await api.get("/tags");
			if (!data || !data.tags) data.tags = [];
      		if (data.tags){
				data.tags.unshift({ id: 0, color: '#000000', name: 'sem etiqueta', status: 1 });
				data.tags.unshift({ id: 'selectAll', color: "#0b708c", name: i18n.t("ticketsByTags.filters.selectAllTags"), status: 1 });
			}
			setTags(data.tags)
			const tagsMapped = data?.tags?.map(t => t.id) || [];
			setTagsFilter(tagsMapped);
			return tagsMapped;
		} catch (err) {
			console.error({err});
			toastError(err);
		}
	}

	async function getConnections() {
		try {
			const { data } = await api.get("/whatsappListAll",{ params: {disabled: '1'}});
			if (data) {
				data.unshift({ id: 'selectAll', color: "#0b708c", name: i18n.t("ticketsByTags.filters.selectAllConnections"), status: 1 });
			}
			setConnections(data);
			const connectionsMapped = data.map(t => t.id);
			setSelectedConnectionsIds(connectionsMapped);
			return connectionsMapped;
		} catch (err) {
			console.error({err});
			toastError(err);
		}
	}

	async function getQueues() {
		try {
			// resgatar lista de filas da base de dados
			let resp = await api.get(`/queueListWithConnections`);
			if (!resp.data) return toast.error('Erro ao resgatar filas, contate o administrador.');
			resp.data.unshift({ id: 'selectAll', color: "#0b708c", name: i18n.t("ticketsByTags.filters.selectAllQueues"), status: 1 });
			setQueues(resp.data);

			const connectionsMapped = resp.data.map(t => t.id);
			setSelectedQueuesIds(connectionsMapped);
			return connectionsMapped;
		} catch (err) {
			console.error({err});
			toastError(err);
		}
	}

	function openDatePicker(input) {
		var inputDate = document.getElementById(input)
		if (!inputDate.disabled) {
			inputDate.showPicker()
		}
	}

	async function searchData(startDate, endDate, tagsId, connectionsId, queuesId) {
		setSearchingData(true);
		const params = {
			startDate,
			endDate,
			tagsId,
			connectionsId,
			queuesId,
		};

		try {
			const { data } = await api.post("/relatory-tags", params);
			// remover milisegundos
			let now = getDate();
			now = now.split(':');
			now.pop();
			now = now.join(':');
			setUpdatedAt(now);

			if (data.general && Object.keys(data.general).length >= 1) {
				setGeneralTagsTotal(data.general.total);
				let arrayTemp = [];
				for (let index in data.general) {
					if (isNaN(index)) continue;
					arrayTemp.push(data.general[index]);
				}
				data.general = arrayTemp;
				data.general = data.general.sort((a, b) => (a.qty < b.qty) ? 1 : (a.qty === b.qty) ? ((a.name > b.name) ? 1 : -1) : -1 );
				setGeneralTags(data.general);
			};

			if (data.grouped) {

				let resetData = [{
					name: '',
					data: [0],
					color: '#bdbdbd'
				}];

				if (data.grouped.open) {
					let openData = [];
					for (let index in data.grouped.open) {
						openData.push({
							name: data.grouped.open[index].name ? data.grouped.open[index].name : 'unknown',
							data: [data.grouped.open[index].qty ? data.grouped.open[index].qty : 0],
							color: data.grouped.open[index].color ? data.grouped.open[index].color : '#bdbdbd'
						})
					}
					setOpen(openData);
				} else { setOpen(resetData); }

				if (data.grouped.pending) {
					let pendingData = [];
					for (let index in data.grouped.pending) {
						pendingData.push({
							name: data.grouped.pending[index].name ? data.grouped.pending[index].name : 'unknown',
							data: [data.grouped.pending[index].qty ? data.grouped.pending[index].qty : 0],
							color: data.grouped.pending[index].color ? data.grouped.pending[index].color : '#bdbdbd'
						})
					}
					setPending(pendingData);
				} else setPending(resetData);

				if (data.grouped.closed) {
					let finishedData = [];
					for (let index in data.grouped.closed) {
						finishedData.push({
							name: data.grouped.closed[index].name ? data.grouped.closed[index].name : 'unknown',
							data: [data.grouped.closed[index].qty ? data.grouped.closed[index].qty : 0],
							color: data.grouped.closed[index].color ? data.grouped.closed[index].color : '#bdbdbd'
						})
					}
					setFinished(finishedData);
				} else setFinished(resetData);
			}
		} catch (err) {
			console.error(err);
			toastError(err);
		} finally {
			setTimeout(() => {
				setSearchingData(false);
			}, 500);
		}
	}

	const exportExcel = async () => {
		setExportingData(true);
		const params = {
			startDate,
			endDate,
			tagsId: tagsFilter.filter(id => String(id).toLowerCase() != 'selectall'),
			connectionsId: selectedConnectionsIds.filter(id => String(id).toLowerCase() != 'selectall'),
			queuesId: selectedQueuesIds.filter(id => String(id).toLowerCase() != 'selectall'),
		};

		try {
			const { data } = await api.post("/relatory-tags", params);

			if (data && data.general && Object.keys(data.general).length >= 1) {

				let general = data.general;
				let arrayTemp = [];

				for (let index in data.general) {
					if (isNaN(index)) continue;
					arrayTemp.push(data.general[index]);
				}

				const sortArray = async (array) => {
					return array.sort((a, b) => {
						const nameA = a.name.toUpperCase();
						const nameB = b.name.toUpperCase();
				
						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}
						return 0;
					});
				}
				general = await sortArray(arrayTemp);
				arrayTemp = [];

				// agrupar informações
				for (const gen of general) {
					for (const tckt of gen.tickets) {
						arrayTemp.push({ id: `#${tckt.id}`, status: tckt.status, isChatbot: tckt.isChatbot, connectionName: tckt['whatsapp.name'], connection: tckt['whatsapp.numberConnection'], lastMessage: tckt.lastMessage, lastMessageDate: tckt.lastMessageDate, tag: gen.name, queueName: tckt['Queue.name'], finishedAt: tckt.finishedAt, finishedBy: tckt['Ticket.finishedBy'], attendant: tckt['User.name'], contactName: tckt['Contact.name'], contactNumber: tckt['Contact.number'], isGroup: tckt['Contact.isGroup'], lastReason: tckt.lastReason, conclusionMessage: tckt['ConclusionMessage.shortcut'] });
					}
				}

				// montar excel
				let tickets = [];
	
				arrayTemp.forEach(ticket => {
					let props = {};
					for (const property in ticket) {
						switch (property) {
							case 'contactName':
								props[i18n.t(`ticketsByTags.excelExport.${property}`)] = ticket['isGroup'] == 1 ? i18n.t('groupPrefix') + ticket[property] : ticket[property];
								break;
							case 'lastMessageDate':
								props[i18n.t(`ticketsByTags.excelExport.${property}`)] = ticket[property] ? format((new Date((ticket[property]))), "dd/MM/yyyy HH:mm") : '';
								break;
							case 'finishedAt':
								props[i18n.t(`ticketsByTags.excelExport.${property}`)] = ticket[property] ? format((new Date((ticket[property]))), "dd/MM/yyyy HH:mm") : ((ticket['lastMessageDate'] && ticket['status'] == 'closed') ? format((new Date((ticket['lastMessageDate']))), "dd/MM/yyyy HH:mm") : "");
								break;
							case 'isGroup':
								props[i18n.t(`ticketsByTags.excelExport.${property}`)] = ticket[property] == 1 ? i18n.t(`ticketsByTags.excelExport.translate.${property}.yes`) : i18n.t(`ticketsByTags.excelExport.translate.${property}.no`);
								break;
							case 'isChatbot':
								props[i18n.t(`ticketsByTags.excelExport.${property}`)] = ticket[property] == 1 ? i18n.t(`ticketsByTags.excelExport.translate.${property}.yes`) : i18n.t(`ticketsByTags.excelExport.translate.${property}.no`);
								break;
							case 'status':
								props[i18n.t(`ticketsByTags.excelExport.${property}`)] = i18n.t(`ticketsByTags.excelExport.translate.${property}.${ticket[property]}`);
								break;
							case 'attendant':
								props[i18n.t(`ticketsByTags.excelExport.${property}`)] = ticket[property] || (ticket['isChatbot'] ? "Chatbot" : "");
								break;
							case 'finishedBy':
								props[i18n.t(`ticketsByTags.excelExport.${property}`)] = ticket[property] || ((ticket['isChatbot'] && ticket['status'] == 'closed') ? "Chatbot" : "");
								break;
							default:
								props[i18n.t(`ticketsByTags.excelExport.${property}`)] = ticket[property];
								break;
						}
					}
					tickets.push(props);
				});
				const ws = XLSX.utils.json_to_sheet(tickets);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, 'Responses');
				XLSX.writeFile(wb, `Relatorio_${format((new Date()), "dd/MM/yyyy")}.xlsx`);
			}
		} catch (err) {
			console.error(err);
			toastError(err);
		} finally {
			setTimeout(() => {
				setExportingData(false);
			}, 500);
		}
	  }

	function renderGeneralTags() {
		if (!generalTags) return;
		let rows = [];
		for (let index in generalTags) {
			if (isNaN(index)) continue;
			rows.push(
			<Box 
				className={classes.tagContainer} 
				onClick={() => {
					setModalTitle(generalTags[index]['name']);
					setModalTickets(generalTags[index]['tickets']);
					handleModalOpen();
				}} 
				title="Visualizar atendimentos" 
			>
				<Typography component='span' className={classes.tagTitle}>{generalTags[index]['name']}</Typography>
				<Typography component='span' className={classes.tagQuantity}>{generalTags[index]['qty']}</Typography>
				<Box className={classes.tagColor} style={{ backgroundColor: generalTags[index]['color'] }} />
			</Box>);
		}
		return <>
			{rows}
		</>;
	}

	function renderModalTickets() {
		if (!modalTickets) return;
		let rows = [];
		for (let index in modalTickets) {
			if (isNaN(index)) continue;
			let status = '';
			switch (modalTickets[index]['status']) {
				case 'open': 
					status = i18n.t("ticketsByTags.tabs.open");
					break;
				case 'pending': 
					status = i18n.t("ticketsByTags.tabs.waitingForReturn");
					break;
				case 'closed': 
					status = i18n.t("ticketsByTags.tabs.closed");
					break;
				default: 
					status = '';
					break;
			}
			rows.push(
			<Box 
				className={classes.modalTicketsContainer}
				onClick={() => handleChatModal(true, {id: modalTickets[index].id, title: modalTickets[index]['Contact.name']})}
			>
				<Box 
					className={classes.modalTicketsQueueColor} 
					style={{backgroundColor: modalTickets[index]['Queue.color'] ? modalTickets[index]['Queue.color'] : '#616161'}} 
					title={modalTickets[index]['Queue.name'] ? modalTickets[index]['Queue.name'] : ''}
				/>
				<ListItemAvatar>
					<Avatar src={modalTickets[index]['Contact.profilePicUrl']} />
				</ListItemAvatar>
				<Box className={classes.modalTicketsContainerBody}>
					<Typography variant="h6" >{modalTickets[index]['Contact.isGroup'] ? i18n.t('groupPrefix')+modalTickets[index]['Contact.name']: modalTickets[index]['Contact.name']}</Typography>
					{
						modalTickets[index]['User.name'] &&
						<Typography variant="subtitle1" >Atendente: {modalTickets[index]['User.name']}</Typography>
					}
					<Typography variant="subtitle2" >{status}</Typography>
					{
						modalTickets[index]['lastMessageDate'] &&
						<Box style={{position: "absolute",top: "10px",right: "10px"}}>
							{isSameDay(parseISO(modalTickets[index]['lastMessageDate']), new Date()) ? (
								<>{format(parseISO(modalTickets[index]['lastMessageDate']), "HH:mm")}</>
							) : (
								<>{format(parseISO(modalTickets[index]['lastMessageDate']), "dd/MM/yyyy")}</>
							)}
						</Box>
					}
				</Box>
			</Box>);
		}
		return <>
			{rows}
		</>;
	}

	function getDate(formato = 1) {
		// resgatar informações básicas e padronizar com 0 a esquerda
		let d = new Date();
		let day = d.getDate();
		day = ('00' + day).slice(-2);
		let month = d.getMonth() + 1;
		month = ('00' + month).slice(-2);
		let year = d.getFullYear();
		let hour = d.getHours();
		hour = ('00' + hour).slice(-2);
		let minute = d.getMinutes();
		minute = ('00' + minute).slice(-2);
		let second = d.getSeconds();
		second = ('00' + second).slice(-2);
		let milliseconds = d.getSeconds();
		milliseconds = ('000' + milliseconds).slice(-3);

		// definir formato
		if (formato == 1) {
			return day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second + ':' + milliseconds;
		}
		else {
			return year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second + ':' + milliseconds;
		}
	}

	useEffect(() => {
		if (loaded == false) {
			setSelectedQueuesIds([]);
			setLoaded(true);
		}
		setFilteredQueues([]);
		let addedQueues = [];
		selectedConnectionsIds.map((whatsappId) => {
			whatsappId = String(whatsappId);
			queues.map((item) => {
				// se a conexão bater com o parametro local, adicionar ao array
				let itemId = String(item.id).toLowerCase();

				if (item.whatsappConnections) {
					let whatsappIds = item.whatsappConnections.split(';');
					if ((whatsappIds.indexOf(whatsappId) >= 0 && addedQueues.includes(item.id) == false)) {
						setFilteredQueues(oldFilteredQueues => [
							...oldFilteredQueues,
							{
								id: item.id,
								name: item.name
							}
						]);
						addedQueues.push(item.id);
					}
				} else if (itemId == 'selectall' && addedQueues.includes(item.id) == false) {
					setFilteredQueues(oldFilteredQueues => [
						...oldFilteredQueues,
						{
							id: item.id,
							name: item.name
						}
					]);
					addedQueues.push(item.id);
				}
			});
		});
	}, [selectedConnectionsIds]);

	return (
		<Box className={classes.mainContainer}>
			<Grid container spacing={3}>
				{/* Titulos */}
				<Grid item xs={12} sm={12}>
					<Typography variant='h4' className={classes.title} style={{ fontSize: "1.5rem" }}>{i18n.t("ticketsByTags.title")}</Typography>
					{updatedAt && <Typography variant='subtitle1' className={classes.subtitle}>{i18n.t("ticketsByTags.updated")} {updatedAt}</Typography>}
				</Grid>
				{/* Filtros */}
				<Grid item xs={12} sm={12} pt={2} className={classes.container}>
					<Typography variant='h5' className={classes.title} style={{ float: "left" }}>{i18n.t("ticketsByTags.filters.title")}</Typography>
					<Box className={classes.filterContainer}>
						{/* Data de inicio */}
						<Box
							className={classes.filterButtonsContainer}
							onClick={e => { openDatePicker('start_date') }}
						>
							<Typography>{i18n.t("ticketsByTags.filters.startDate")}</Typography>
							<input
								max={new Date().toISOString().split("T")[0]}
								onChange={e => { setStartDate(e.target.value) }}
								value={startDate}
								id="start_date"
								type="date"
								aria-describedby="emailHelp"
								className={classes.filterDateButtons}
								disabled={searchingData}
							/>
						</Box>
						{/* Data de fim */}
						<Box className={classes.filterButtonsContainer} onClick={e => { openDatePicker('end_date') }}>
							<Typography>{i18n.t("ticketsByTags.filters.endDate")}</Typography>
							<input
								max={new Date().toISOString().split("T")[0]}
								onChange={e => { setEndDate(e.target.value) }}
								value={endDate}
								id="end_date"
								type="date"
								aria-describedby="emailHelp"
								className={classes.filterDateButtons}
								disabled={searchingData}
							/>
						</Box>
						{/* Etiquetas */}
						<TicketsTagSelect
							selectedTagsIds={tagsFilter}
							userTags={tags}
							onChange={(e) => setTagsFilter(e)}
							useText={
								<Box className={classes.filterButtonsContainer}>
									<Typography>{i18n.t("ticketsByTags.filters.tags")}</Typography>
									<Button variant="outlined" className={classes.filterButtons} disabled={searchingData}>
										{i18n.t("ticketsByTags.filters.selectTags")}
										<ArrowDropDownIcon className={classes.filterButtonsIcon} />
									</Button>
								</Box>}
						/>
						{/* Conexões */}
						<Box className={classes.filterButtonsContainer}>
							<Typography>{i18n.t("ticketsByTags.filters.connections")}</Typography>
							<Button variant="outlined" className={classes.filterButtons} onClick={handleConnectionClick} disabled={searchingData}>
								{i18n.t("ticketsByTags.filters.selectConnections")}
								<ArrowDropDownIcon className={classes.filterButtonsIcon} />
							</Button>
							<Menu
							anchorEl={connectionAnchorEl}
							open={Boolean(connectionAnchorEl)}
							onClose={handleConnectionClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							PaperProps={{
								style: {
									maxHeight: "300px",
									width: "230px",
									marginTop: "17px"
								},
							}}
						>
							{connections?.length > 0 &&
								connections.map(conn => (
									<MenuItem dense key={conn.id}>
										<FormControlLabel control={<Checkbox
											size="small"
											color="primary"
											checked={selectedConnectionsIds.includes(conn.id)}
											onChange={() => handleConnectionSelection(conn.id)}
										/>} label={conn.name} />
									</MenuItem>
								))}
						</Menu>
						</Box>
						{/* Filas */}
						<Box className={classes.filterButtonsContainer}>
							<Typography>{i18n.t("ticketsByTags.filters.queues")}</Typography>
							<Button 
								variant="outlined" 
								className={classes.filterButtons} 
								onClick={handleQueueClick} 
								disabled={filteredQueues == null || filteredQueues.length == 0 || searchingData ? true : false}
							>
								{i18n.t("ticketsByTags.filters.selectQueues")}
								<ArrowDropDownIcon className={classes.filterButtonsIcon} />
							</Button>
							<Menu
								anchorEl={queueAnchorEl}
								open={Boolean(queueAnchorEl)}
								onClose={handleQueueClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								PaperProps={{
									style: {
										maxHeight: "300px",
										width: "230px",
										marginTop: "17px"
									},
								}}
							>
								{filteredQueues?.length > 0 &&
									filteredQueues.map(queue => (
										<MenuItem dense key={queue.id}>
											<FormControlLabel control={<Checkbox
												size="small"
												color="primary"
												checked={selectedQueuesIds.includes(queue.id)}
												onChange={() => handleQueuesSelection(queue.id)}
											/>} label={queue.name} />
										</MenuItem>
									))}
							</Menu>
						</Box>
						{/* Aplicar filtro */}
						<Button
							aria-controls="customized-menu"
							aria-haspopup="true"
							variant="contained"
							color="primary"
							style={{ width: "100%", top: isMobile ? "0" : "19px", height: "35px" }}
							onClick={() => { searchData(
								startDate,
								endDate,
								tagsFilter.filter(id => String(id).toLowerCase() != 'selectall'),
								selectedConnectionsIds.filter(id => String(id).toLowerCase() != 'selectall'),
								selectedQueuesIds.filter(id => String(id).toLowerCase() != 'selectall')
							) }}
						>
							{searchingData ? <CircularProgress style={{ color: "#ffffff", padding: "10px" }} /> : i18n.t("ticketsByTags.buttons.apply")}
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				{/* Etiquetas */}
				<Grid item xs={12} sm={3} pt={2} className={classes.container} style={{ maxHeight: "780px", heigh: "100%", position: "relative", overflowY: "auto" }}>
					<Typography variant='h5' className={classes.title}>{i18n.t("ticketsByTags.ticketsByTags")}</Typography>
					{(generalTagsTotal || generalTagsTotal === 0) && <Typography variant='subtitle1' className={classes.subtitle}>{i18n.t("ticketsByTags.ticketsByTagsTotal")} {generalTagsTotal}</Typography>}
					{searchingData
						?
						<>
							<Skeleton variant="rect" style={{ width: "100%", height: "50px", marginBottom: "10px", borderRadius: "10px" }} />
							<Skeleton variant="rect" style={{ width: "100%", height: "50px", marginBottom: "10px", borderRadius: "10px" }} />
						</>
						:
						generalTags && renderGeneralTags()
					}
					<Dialog
						open={modalOpen}
						onClose={handleModalClose}
					>
						<DialogTitle style={{textTransform: "capitalize"}}>{modalTitle && modalTitle}</DialogTitle>
						<DialogContent style={{height: "500px", overflowY: "auto"}}>
							{modalTickets && renderModalTickets()}
						</DialogContent>
						<DialogActions>
							<Button onClick={handleModalClose} color="secondary">
								{i18n.t("ticketsByTags.buttons.close")}
							</Button>
						</DialogActions>
					</Dialog>
				</Grid>
				<Grid container xs={12} sm={9} pt={2}>
					<Grid item xs={12} sm={6} style={{ paddingLeft: isMobile ? 0 : "10px", height: "max-content" }}>
						<Box className={classes.container} style={{ padding: "12px" }}>
							<Typography variant='h5' className={classes.title} style={{ marginBottom: "10px" }}>{i18n.t("ticketsByTags.tabs.waitingForReturn")}</Typography>
							{searchingData
								?
								<>
									<Skeleton variant="rect" style={{ width: "100%", height: "300px", marginBottom: "10px", borderRadius: "10px" }} />
								</>
								:
								pending &&
								<GraphRelatory
									chartHeight='300px'
									data={pending}
								/>
							}
						</Box>
					</Grid>
					<Grid item xs={12} sm={6} style={{ paddingLeft: isMobile ? 0 : "10px", height: "max-content" }}>
						<Box className={classes.container} style={{ padding: "12px" }}>
							<Typography variant='h5' className={classes.title} style={{ marginBottom: "10px", position: "relative" }}>
								{i18n.t("ticketsByTags.tabs.closed")}
								<Button
									variant="contained"
									color="primary"
									style={{
										position: "absolute",
										top: "0px",
										right: "0px"
									}}
									onClick={() => exportExcel()}
								>
									{i18n.t(`ticketsByTags.buttons.export`)}
								</Button>
							</Typography>
							{searchingData
								?
								<>
									<Skeleton variant="rect" style={{ width: "100%", height: "300px", marginBottom: "10px", borderRadius: "10px" }} />
								</>
								:
								finished &&
								<GraphRelatory
									chartHeight='300px'
									data={finished}
								/>
							}
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} style={{ paddingLeft: isMobile ? 0 : "10px", height: "max-content" }}>
						<Box className={classes.container} style={{ padding: "12px" }}>
							<Typography variant='h5' className={classes.title} style={{ marginBottom: "10px" }}>{i18n.t("ticketsByTags.tabs.open")}</Typography>
							{searchingData
								?
								<>
									<Skeleton variant="rect" style={{ width: "100%", height: "300px", marginBottom: "10px", borderRadius: "10px" }} />
								</>
								:
								open &&
								<GraphRelatory
									chartHeight='300px'
									data={open}
								/>
							}
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<RenderChatRelatory
				title={chatModalRelatoryInfo.title}
				open={openChatRelatory}
				onClose={e => { handleChatModal(false) }}
				ticketId={chatModalRelatoryInfo.id}
			/>
		</Box>
	);

};

export default RelatoryTags;
