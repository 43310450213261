import React, { createContext, useContext, useEffect, useRef } from "react";
import socket from "../../hooks/useSocket";
import { AuthContext } from "./AuthContext";

const UserStatusContext = createContext();


// 2. Criar um componente Provider
const UserStatusProvider = ({ children }) => {
    const { user, handleStatus, state } = useContext(AuthContext);
    const statusRef = useRef('offline');
    const activityTimeout = useRef(null);
    let initialized = false;

    // Tempo para defiir ausência de usuário
    const outMinutes = 10;
    const lastActivityCheck = { timestamp: 0 };
    const handleActivity = () => {

        const now = Date.now();
        // Se passou menos de 1 minuto desde a última verificação, sai da função
        if (now - lastActivityCheck.timestamp < 60 * 1000) return;
        lastActivityCheck.timestamp = now;

        if (activityTimeout.current) clearTimeout(activityTimeout.current);
        if (statusRef.current != 'online' || user.state != 'online') {
            socket.emit('change_status', { userId: user.id, state: 'online', adminId: user.adminId });
            statusRef.current = 'online';
        }

        activityTimeout.current = setTimeout(() => {
            if (statusRef.current != 'out') {
                socket.emit('change_status', { userId: user.id, state: 'out', adminId: user.adminId });
                statusRef.current = 'out';
            }
        }, outMinutes * 60 * 1000); // Usuário fica ausente após x minutes
    };

    useEffect(() => {
        if (initialized == true) return;
        initialized = true;

        if (user?.adminId) {
            socket.connect(user.adminId);
        };

        const connectEventName = 'connect';
        const connectEventFunction = async () => {
            socket.emit('register', { userId: user.id });
            // handleActivity();
        }

        const disconnectEventName = 'disconnect'
        const disconnectEventFunction = async () => {
            // if (activityTimeout.current) clearTimeout(activityTimeout.current);
            // statusRef.current = 'offline';
        }

        const userStatusEventName = `user_status_${user.adminId}`;
        const userStatusEventFunction = async (data) => {
            if (data.id == user.id && data?.state && data?.state != 'undefined') {
                console.info(`Usuário: [${data.id}]:[${data.name}] está ${data.state}`);
                handleStatus(data.state, data.toRefresh)
            }
        };

        socket.on(connectEventName, connectEventFunction);
        socket.on(disconnectEventName, disconnectEventFunction);
        socket.on(userStatusEventName, userStatusEventFunction);

        const handleMouseMove = () => handleActivity();
        const handleKeyDown = () => handleActivity();
        const handleBeforeUnload = () => socket.disconnect();
        const handleLoad = () => socket.emit('change_status', { userId: user.id, state: 'online', adminId: user.adminId });

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('load', handleLoad);

        return () => {
            socket.off(connectEventName, connectEventFunction);
            socket.off(disconnectEventName, disconnectEventFunction);
            socket.off(userStatusEventName, userStatusEventFunction);

            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    }, [user]);

    return (
        <UserStatusContext.Provider>
            {children}
        </UserStatusContext.Provider>
    );
};

export default UserStatusProvider;