import { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainContainer from "../../components/MainContainer";
import { i18n } from "../../translate/i18n";
import { Container, Grid, Typography, Button, IconButton } from "@material-ui/core";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CustomerRelationshipConfigurationModal from "../../components/CustomerRelationship/Modals/CustomerRelationshipConfigurationModal";
import RefreshIcon from '@material-ui/icons/Refresh';
import Kanban from "../../components/CustomerRelationship/Kanban";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    minWidth: '310px',
    width: 'auto',
    margin: '30px 20px 20px 20px!important',
    padding: '30px',
    background: '#fff',
    borderRadius: '10px',
    maxWidth: 'calc(100% - 20px)!important',
    height: '50px',
    lineHeight: '50px',
    boxShadow:'0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  },
  kanbanStyle: {
    minWidth: '310px',
    width: 'auto',
    margin: '0px 20px!important',
    maxWidth: 'calc(100% - 20px)!important',
    display: 'flex',
    overflowX: 'auto',
    paddingBottom: '10px',
    height: 'calc(100vh - 180px)',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));

const CustomerRelationship = () => {
  const classes = useStyles();
  const [configurationModal, setConfigurationModal] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const { user } = useContext(AuthContext);

  const handleConfigurationModalClose = () => {
    setConfigurationModal(false);
  }

  return (
    <MainContainer>
      <Container
        className={classes.modalStyle}
      >

        <Grid container spacing={5} style={{ height: '50px', lineHeight: '50px' }}>
          <Grid item sm={12} xs={12} mb={2} style={{ height: '50px', lineHeight: '50px' }}>
            <Typography 
              variant="h6" 
              style={{ fontWeight: '800', float: 'left', marginTop: '-10px' }}
            >
              {i18n.t('customerRelationship.title')}
            </Typography>
            <IconButton 
              color="primary"
              style={{ float: 'right', marginTop: '-20px' }}
              onClick={() => { setConfigurationModal(true) }}
              hidden={user.profile != 'admin' ? true : false}
            >
              <SettingsOutlinedIcon />
            </IconButton>
            <IconButton 
              color="primary"
              style={{ float: 'right', marginTop: '-20px' }}
              onClick={() => { setRefresh(new Date().getTime()) }}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>

      <Kanban 
        className={classes.kanbanStyle} 
        refresh={refresh}
      />

      <CustomerRelationshipConfigurationModal 
        open={configurationModal}
        onClose={handleConfigurationModalClose}
      />
    </MainContainer>
  )
}

export default CustomerRelationship;
