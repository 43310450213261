import { makeStyles } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Box, Grid, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import WebhookTable from "../../components/Integration/WebhookTable";
import MainContainer from "../../components/MainContainer";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import socket from "../../hooks/useSocket";
import ApiTable from "../../components/Integration/ApiTable";

const reducer = (state, action) => {
  if (action.type === "LOAD_INTEGRATIONS") {
    const integrations = action.payload;
    const newIntegrations = [];

    integrations.forEach((integration) => {
      const integrationIndex = state.findIndex((q) => q.id === integration.id);
      if (integrationIndex !== -1) {
        state[integrationIndex] = integration;
      } else {
        newIntegrations.push(integration);
      }
    });

    return [...state, ...newIntegrations];
  }

  if (action.type === "UPDATE_INTEGRATIONS") {
    const integration = action.payload;
    const integrationIndex = state.findIndex((q) => q.id === integration.id);
    if (integrationIndex !== -1) {
      state[integrationIndex] = integration;
      return [...state];
    } else {
      return [integration, ...state];
    }
  }

  if (action.type === "DELETE_INTEGRATIONS") {
    const integrationId = action.payload;

    const integrationIndex = state.findIndex((q) => q.id === integrationId);
    if (integrationIndex !== -1) {
      state.splice(integrationIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modalStyle: {
    minWidth: "395px",
    width: "auto",
    margin: "80px auto 0 auto",
    borderRadius: "10px",
    margin: '20px 10px!important',
    padding: '10px',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  },
  title: {
    fontSize: "1.5rem!important",
    fontWeight: "700!important",
  },
  subtitle: {
    fontSize: "1.1rem!important",
    marginTop: "10px!important",
    fontWeight: "600!important",
    color: `${grey[500]}!important`
  },
  tabs: {
    background: "transparent", 
    color: "#000",
    boxShadow: "none",
    borderBottom: "1px solid rgba(0,0,0,.1)",
    "& .MuiTabs-indicator": {
      backgroundColor: "#0b708c"
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`interation-tabpanel-${index}`}
      aria-labelledby={`interations-${index}`}
      component="div"
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `interation-tab-${index}`,
    'aria-controls': `interation-tabpanel-${index}`,
  };
}

const Integrations = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [integrations, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);
  const adminId = user.adminId;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const abortController = new AbortController();

    const delayDebounceFn = setTimeout(() => {
      const fetchIntegrations = async () => {
        try {
          const { data } = await api.get("/integrations/", {
            params: { searchParam, pageNumber },
            signal: abortController.signal,
          });
          if (!isMounted) return;
          dispatch({ type: "LOAD_INTEGRATIONS", payload: data.integrations });
          setLoading(false);
        } catch (err) {
          if (!isMounted || err.name == 'AbortError') return;
          console.error({err});
          toastError(err);
        }
      };
      fetchIntegrations();
    }, 500);

    return () => {
      isMounted = false;
      abortController.abort();
      clearTimeout(delayDebounceFn);
    };
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const integrationsEventName = `integrations-${adminId}`;
    const integrationsEventFunction = async (data) => {

      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_INTEGRATIONS", payload: data.integration });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_INTEGRATIONS",
          payload: +data.integrationId,
        });
      }
    };
    socket.on(integrationsEventName, integrationsEventFunction);

    return () => {
      socket.off(integrationsEventName, integrationsEventFunction);
    };
  }, []);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <Box
        onScroll={handleScroll}
        className={classes.modalStyle}
        component={Paper}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h3" className={classes.title}>{i18n.t("integrations.title")}</Typography>
            <Typography variant="subtitle1" className={classes.subtitle} color="inherit" >{i18n.t("integrations.subtitle")}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} mb={2}>
            <Box component="div" className={classes.root}>
              <AppBar 
                position="static"
                className={classes.tabs}
              >
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label={i18n.t('integrations.api.title')} {...a11yProps(0)} />
                  <Tab label={i18n.t('integrations.webhook.title')} {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <ApiTable integrations={integrations.filter(i => String(i.type).toLowerCase() == 'api')} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <WebhookTable integrations={integrations.filter(i => String(i.type).toLowerCase() == 'webhook')} />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainContainer >
  );
};

export default Integrations;
