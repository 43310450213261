import { makeStyles } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import DeleteIcon from '@mui/icons-material/Delete';
import SchemaIcon from '@mui/icons-material/Schema';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import { Button, Container, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useContext, useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import ChatbotModal from "../../components/ChatbotModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import CreateChatbotModal from "../../components/CreateChatbotModal";
import MainContainer from "../../components/MainContainer";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import socket from "../../hooks/useSocket";

const reducer = (state, action) => {

  if (action.type === "LOAD_QUICK_ANSWERS") {
    const quickAnswers = action.payload;
    const newQuickAnswers = [];

    quickAnswers.forEach((chatbot) => {
      const chatbotIndex = state.findIndex((q) => q.id === chatbot.id);
      if (chatbotIndex !== -1) {
        state[chatbotIndex] = chatbot;
      } else {
        newQuickAnswers.push(chatbot);
      }
    });

    return [...state, ...newQuickAnswers];
  }

  if (action.type === "UPDATE_CHATBOT") {
    const chatbot = action.payload;
    const chatbotIndex = state.findIndex((q) => q.id === chatbot.id);

    if (chatbotIndex !== -1) {
      state[chatbotIndex] = chatbot;
      return [...state];
    } else {
      return [chatbot, ...state];
    }
  }

  if (action.type === "DELETE_CHATBOT") {
    const chatbotId = action.payload;
    const chatbotIndex = state.findIndex((q) => q.id === chatbotId);
    if (chatbotIndex !== -1) {
      state.splice(chatbotIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    color: '#000',
    marginLeft: '10px',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    "& :hover": {
      color: '#009688'
    }
  },
  publishedIcon: {
    width: '35px',
    height: '35px',
    cursor: 'pointer'
  }
}));

const QuickAnswers = () => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [quickAnswers, dispatch] = useReducer(reducer, []);
  const [selectedQuickAnswers, setSelectedQuickAnswers] = useState(null);
  const [quickAnswersModalOpen, setQuickAnswersModalOpen] = useState(false);
  const [deletingQuickAnswers, setDeletingQuickAnswers] = useState(null);
  const [chatbotId, setChatbotId] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [chatbotModalOpen, setChatbotModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [getCreatedBy, setSelectedUserFilter] = useState("");

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, getCreatedBy]);


  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    const delayDebounceFn = setTimeout(() => {
      const fetchQuickAnswers = async () => {
        try {
          const { data } = await api.get("/chatbot/", {
            params: { searchParam, pageNumber, getCreatedBy },
            signal: abortController.signal,
          });
          if (!isMounted) return;
          if (data === undefined) return;

          dispatch({ type: "LOAD_QUICK_ANSWERS", payload: data });
        } catch (err) {
          if (!isMounted || err.name == 'AbortError') return;
          console.error({err});
          toastError(err);
        }
      };
      fetchQuickAnswers();
    }, 500);

    return () => {
      isMounted = false;
      abortController.abort();
      clearTimeout(delayDebounceFn);
  };
  }, [searchParam, pageNumber, getCreatedBy]);

  useEffect(() => {
    const chatbotEventName = `chatbot-${user.adminId}`;
    const chatbotEventFunction = async (data) => {

      if (data.action === "update" || data.action === "create") {
        if (data?.chatbot?.companyId != user.adminId || !data?.chatbot?.companyId) return;
        dispatch({ type: "UPDATE_CHATBOT", payload: data.chatbot });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_CHATBOT",
          payload: +data.chatbotId,
        });
      }
    };

    socket.on(chatbotEventName, chatbotEventFunction);
    return () => {
      socket.off(chatbotEventName, chatbotEventFunction);
    };
  }, []);

  const handleOpenQuickAnswersModal = () => {
    setSelectedQuickAnswers(null);
    setQuickAnswersModalOpen(true);
  };

  const handleCloseQuickAnswersModal = () => {
    setSelectedQuickAnswers(null);
    setQuickAnswersModalOpen(false);
  };

  const handleEditQuickAnswers = (chatbot) => {
    setSelectedQuickAnswers(chatbot);
    setQuickAnswersModalOpen(true);
  };

  const handleDeleteQuickAnswers = async (chatbotId) => {
    try {
      await api.delete(`/chatbot/${chatbotId}`);
      toast.success(i18n.t("quickAnswers.toasts.deleted"));
    } catch (err) {
      console.error({err});
      toastError(err);
    }
    setDeletingQuickAnswers(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handlePublished = async (chatbotData, published) => {
    try {
      // verificar se é para publicar
      if (!chatbotData.nodes || !chatbotData.edges) {
        toastError({ response: { data: { error: "Edite o chatbot, antes de publicá-lo." } } });
        return;
      }

      let data = await api.put(`/chatbot/${chatbotData.id}`, { 
        companyId: chatbotData.companyId, 
        createdAt: chatbotData.createdAt, 
        createdBy: chatbotData.createdBy, 
        edges: chatbotData.edges,
        id: chatbotData.id,
        isReceptive: chatbotData.isReceptive,
        name: chatbotData.name,
        nodes: chatbotData.nodes,
        updatedAt: chatbotData.updatedAt,
        published 
      });
      toast.success('Atualizado com sucesso!');
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingQuickAnswers &&
          `${'Deseja deletar o chatbot'} ${deletingQuickAnswers.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteQuickAnswers(deletingQuickAnswers.id)}
      >
        {i18n.t("quickAnswers.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ChatbotModal
        open={chatbotModalOpen}
        onClose={setChatbotModalOpen}
        onConfirm={() => handleDeleteQuickAnswers(deletingQuickAnswers.id)}
        chatbotId={chatbotId}
      />
      <CreateChatbotModal
        open={quickAnswersModalOpen}
        onClose={handleCloseQuickAnswersModal}
        aria-labelledby="form-dialog-title"
        chatbotId={selectedQuickAnswers && selectedQuickAnswers.id}
      />

      <Container
        component={Paper}
        maxWidth={false}
        style={{
          margin: '20px 10px',
          padding: '10px',
          maxWidth: 'calc(100% - 20px)',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom style={{ float: "left" }} >Chatbot</Typography>
            <Button
              style={{
                backgroundColor: "#0b708c",
                float: "right",
                color: "#fff"
              }}
              onClick={handleOpenQuickAnswersModal}
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer style={{ marginTop: "20px" }} >
              <Table aria-label="chatbot table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell align="left">Criado em:</TableCell>
                    <TableCell align="left">Conexão</TableCell>
                    <TableCell align="left">Tipo</TableCell>
                    <TableCell align="left">Publicado</TableCell>
                    <TableCell align="right" style={{ width: '225px' }}>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quickAnswers.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">{row.name}</TableCell>
                      <TableCell align="left">{format(parseISO(row.createdAt), "dd/MM/yyyy HH:mm")}</TableCell>
                      <TableCell align="left">{row?.Whatsapps[0]?.name || ''}</TableCell>
                      <TableCell align="left">{row.isReceptive == true ? 'Receptivo' : 'Ativo'}</TableCell>
                      <TableCell align="left">
                        {
                          row.published
                            ?
                            <Tooltip
                              arrow
                              placement="bottom"
                              title="Desabilitar"
                            >
                              <ToggleOnIcon 
                                className={classes.publishedIcon} 
                                style={{ color: '#009688' }} 
                                onClick={() => {
                                  handlePublished(row, false);
                                }}
                              />
                            </Tooltip>

                            :
                            <Tooltip
                              arrow
                              placement="bottom"
                              title="Habilitar"
                            >
                              <ToggleOffIcon 
                                className={classes.publishedIcon} 
                                style={{ color: '#bdbdbd' }} 
                                onClick={() => {
                                  handlePublished(row, true);
                                }}
                              />
                            </Tooltip>
                        }
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip
                          arrow
                          placement="bottom"
                          title="Editar Fluxo"
                        >
                          <SchemaIcon
                            className={classes.actionIcon}
                            onClick={() => {
                              setChatbotModalOpen(true);
                              setChatbotId(row.id);
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          arrow
                          placement="bottom"
                          title="Editar Nome"
                        >
                          <SpellcheckIcon
                            className={classes.actionIcon}
                            onClick={() => handleEditQuickAnswers(row)}
                          />
                        </Tooltip>
                        <Tooltip
                          arrow
                          placement="bottom"
                          title="Excluir"
                        >
                          <DeleteIcon
                            className={classes.actionIcon}
                            onClick={(e) => {
                              setConfirmModalOpen(true);
                              setDeletingQuickAnswers(row);
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </MainContainer >
  );
};

export default QuickAnswers;