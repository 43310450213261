import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback, useEffect, useState } from "react";
import toastError from "../../errors/toastError";

import Select from "@material-ui/core/Select";

import api from "../../services/api";

import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import JsonParse from "../../helpers/JsonParse";
import TagCheckList from "../Tag/tagCheckList";

const useStyles = makeStyles((theme) => ({
  linearColorPrimary: {
    backgroundColor: "#b2dfdb",
  },
  linearBarColorPrimary: {
    backgroundColor: "#00695c",
  },
  tab: {
    backgroundColor: "transparent",
    color: "#000",
    boxShadow: "none",
    marginTop: "15px",
  },
  lbl: {
    display: "block",
    marginBottom: "10px",
    "& span": {
      color: "#b71c1c",
      marginLeft: "10px",
    },
  },
}));

const ConfirmationImportModal = ({
  title,
  children,
  open,
  onClose,
  onConfirm,
  isDelete,
}) => {
  const classes = useStyles();
  const [whatsapp, setWhatsapp] = useState([]);
  const [whatsappId, setWhatsappId] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);

  function renderItemWhatsapp(campaign) {
    return (
      <MenuItem value={campaign.id}>
        {campaign.name} - ({campaign.numberConnection}) -{" "}
        {campaign.status === "CONNECTED" ? "Conectado" : "Desconectado"}
      </MenuItem>
    );
  }

  function RenderSelectWhatsapp(Campaigns) {
    var i = 0;
    var rows = [];
    for (var campanha in Campaigns) {
      if (Campaigns[campanha].moduleId == 1) {
        rows.push(renderItemWhatsapp(Campaigns[campanha]));
      }
    }
    return rows;
  }

  // função para buscar tags
  async function getTags() {
    try {
      const { data } = await api.get("/tags");
      if (!data || !data.tags) data.tags = [];
      setTags(data.tags);
    } catch (error) {
      console.error(error);
    }
  }

  // função para marcar/desmarcar tags
  const handleTagChange = useCallback((tagId, isChecked) => {
    setSelectedTags((prev) =>
      isChecked ? [...prev, tagId] : prev.filter((id) => id !== tagId)
    );
  }, []);

  useEffect(() => {
    if (open != true) return;

    const fetchSession = async () => {
      try {
        await getTags();
        const { data } = await api.get("/whatsapp/");
        setWhatsapp(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [open]);

  function renderItemConnection(conexao) {
    if (!conexao.numberConnection) {
      return;
    }
    return (
      <MenuItem value={conexao.id}>
        {conexao.name} - {conexao.numberConnection}
      </MenuItem>
    );
  }

  function RenderSelectConnection(Connections) {
    var rows = [];
    for (var conexao in Connections) {
      rows.push(renderItemConnection(Connections[conexao]));
    }

    return rows;
  }

  const selectedConnection = (e) => {
    setWhatsappId(e.target.value);
  };

  function ordenarArrayCrescente(array) {
    array.sort(function (a, b) {
      return a - b;
    });

    return array;
  }
  const VerifyConnection = () => {
    if (isDelete) {
      onClose(false);
      onConfirm();
    } else {
      if (!whatsappId) return toast.error("Selecione uma conexão.");

      onClose(false);
      onConfirm({
        idConnection: whatsappId,
        tagsId: JSON.stringify(ordenarArrayCrescente(selectedTags)),
      });
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Dialog
      open={open == true ? true : false}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent dividers>
        <Typography>{children}</Typography>
      </DialogContent>
      {!isDelete && (
        <>
          {whatsapp && (
            <>
              {/* Tabs */}
              <AppBar position="static" className={classes.tab}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="simple tabs"
                  TabIndicatorProps={{ style: { background: "#0b708c" } }}
                >
                  <Tab
                    label={i18n.t("importContactsModal.tabs.main")}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={i18n.t("importContactsModal.tabs.tags")}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>

              <Box
                component="div"
                style={{
                  overflowY: "auto",
                  height: "30vh",
                  minHeight: "300px",
                }}
              >
                <TabPanel value={tabValue} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} mb={2}>
                      <Typography component="label" className={classes.lbl}>
                        {i18n.t("importContactsModal.form.whatsapp")}
                        <Typography component="span">*</Typography>
                      </Typography>
                      <Select
                        onChange={(e) => selectedConnection(e)}
                        variant="outlined"
                        name="whatsappSelected"
                        required
                        style={{ width: "100%", height: "40px" }}
                        value={whatsappId}
                      >
                        {RenderSelectWhatsapp(whatsapp)}
                      </Select>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <TagCheckList
                    tags={tags}
                    selectedTags={selectedTags}
                    onChange={handleTagChange}
                  />
                </TabPanel>
              </Box>
            </>
          )}
        </>
      )}
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setWhatsappId("");
            setSelectedTags([]);
            onClose(false);
          }}
          color="default"
        >
          {i18n.t("confirmationModal.buttons.cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setWhatsappId("");
            setSelectedTags([]);
            VerifyConnection();
          }}
          color="secondary"
        >
          {i18n.t("confirmationModal.buttons.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationImportModal;
