import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Alert, Box, Checkbox, FormControl, FormLabel, TextField, Grid, FormControlLabel, Switch, Divider, CircularProgress, Typography, Radio, RadioGroup, Link, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { i18n } from '../../../translate/i18n';
import api from '../../../services/api';
import SingleSelect from '../../InputStyled/singleSelect';
import toastError from '../../../errors/toastError';
import { MuiChipsInput } from 'mui-chips-input';
import { makeStyles } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Logo from "../../../assets/logo_horizontal-oficial.png";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import AddIcon from '@material-ui/icons/Add';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';

const Transition = React.forwardRef(function Transition(
    props,
    ref
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    emails: {
        width: "100%",
        "& input": {
            width: "100%!important"
        }
    },
    root: {
        width: '100%',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const StoreModal = ({ open, onClose, id, templateData }) => {

    const classes = useStyles();
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [loading, setLoading] = useState(false);
    const [connections, setConnections] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [editingButtonId, setEditingButtonId] = useState(null);
    const [isOpen, setIsOpen] = useState(false); // Renomear para evitar conflitos

    const initialState = {
        name: "",
        language: "pt_BR",
        connection: "",
        emails: [],
        category: "",
        messageType: "",
        typeOfOtp: "",
        securityAdvisory: false,
        addExpirationTime: false,
        expirationTime: 0,
        buttonText: "",
        packageName: "",
        applicationSignatureHash: "",
        messageBody: "",
        addFooter: 0,
        headerOption: "",
        addButtons: 0,
        buttons: [],
        files: [],
    };
    const [template, setTemplate] = useState(initialState);
    const fileInputRef = useRef(null);

    const buttonsInitialState = {
        id: "",
        type: "",
        text: "",
        url: "",
        phone: "",
        quickReply: ""
    };
    const [buttonsTmp, setButtonsTmp] = useState(buttonsInitialState);

    const categories = [
        { key: "AUTHENTICATION", value: i18n.t("connections.whatsapp.template.modal.categoryOptions.authentication"), disabled: false },
        { key: "MARKETING", value: i18n.t("connections.whatsapp.template.modal.categoryOptions.marketing"), disabled: false },
        { key: "UTILITY", value: i18n.t("connections.whatsapp.template.modal.categoryOptions.utility"), disabled: false },
    ];

    const options = [
        { value: "simple", accepts: ["MARKETING", "UTILITY"] },
        { value: "customMessageSelected", accepts: ["MARKETING", "UTILITY"] },
        { value: "otpMessageSelected", accepts: ["AUTHENTICATION"] },
    ];

    const typeOfOtpOptions = [
        { key: "COPY_CODE", value: i18n.t("connections.whatsapp.template.modal.typeOfOtp.copyCode"), disabled: false },
        { key: "ONE_TAP", value: i18n.t("connections.whatsapp.template.modal.typeOfOtp.oneTap"), disabled: false },
    ];

    const buttonsTypes = [
        { key: "URL", value: i18n.t("connections.whatsapp.template.modal.buttonsOption.url"), disabled: false, order: 1 },
        { key: "PHONE_NUMBER", value: i18n.t("connections.whatsapp.template.modal.buttonsOption.phoneNumber"), disabled: false, order: 2 },
        { key: "QUICK_REPLY", value: i18n.t("connections.whatsapp.template.modal.buttonsOption.quickReply"), disabled: false, order: 3 },
        { key: "OPT_OUT", value: i18n.t("connections.whatsapp.template.modal.buttonsOption.optOut"), disabled: false, order: 4 },
    ];

    // Filtrar os buttonsTypes para incluir OPT_OUT apenas se template.opt for MARKETING
    const filteredButtons = buttonsTypes.filter(button =>
        button.key !== "OPT_OUT" || template.category === "MARKETING"
    );

    const languages = [
        { key: "pt_BR", value: "Português (BR)", disabled: false },
        { key: "pt_PT", value: "Português (POR)", disabled: false },
        { key: "es", value: "Espanhol", disabled: false },
        { key: "es_AR", value: "Espanhol (ARG)", disabled: false },
        { key: "es_ES", value: "Espanhol (ESP)", disabled: false },
        { key: "es_MX", value: "Espanhol (MEX)", disabled: false },
        { key: "en", value: "Inglês", disabled: false },
        { key: "en_GB", value: "Inglês (UK)", disabled: false },
        { key: "en_US", value: "Inglês (US)", disabled: false },
        { key: "af", value: "Africâner", disabled: false },
        { key: "sq", value: "Albanês", disabled: false },
        { key: "ar", value: "Árabe", disabled: false },
        { key: "az", value: "Azeri", disabled: false },
        { key: "bn", value: "Bengali", disabled: false },
        { key: "bg", value: "Búlgaro", disabled: false },
        { key: "ca", value: "Catalão", disabled: false },
        { key: "zh_CN", value: "Chinês (CHN)", disabled: false },
        { key: "zh_HK", value: "Chinês (HKG)", disabled: false },
        { key: "zh_TW", value: "Chinês (TAI)", disabled: false },
        { key: "hr", value: "Croata", disabled: false },
        { key: "cs", value: "Tcheco", disabled: false },
        { key: "da", value: "Dinamarquês", disabled: false },
        { key: "nl", value: "Holandês", disabled: false },
        { key: "et", value: "Estoniano", disabled: false },
        { key: "fil", value: "Filipino", disabled: false },
        { key: "fi", value: "Finlandês", disabled: false },
        { key: "fr", value: "Francês", disabled: false },
        { key: "de", value: "Alemão", disabled: false },
        { key: "el", value: "Grego", disabled: false },
        { key: "gu", value: "Gujarati", disabled: false },
        { key: "he", value: "Hebraico", disabled: false },
        { key: "hi", value: "Hindi", disabled: false },
        { key: "hu", value: "Húngaro", disabled: false },
        { key: "id", value: "Indonésio", disabled: false },
        { key: "ga", value: "Irlandês", disabled: false },
        { key: "it", value: "Italiano", disabled: false },
        { key: "ja", value: "Japonês", disabled: false },
        { key: "kn", value: "Canará", disabled: false },
        { key: "kk", value: "Cazaque", disabled: false },
        { key: "ko", value: "Coreano", disabled: false },
        { key: "lo", value: "Lao", disabled: false },
        { key: "lv", value: "Letão", disabled: false },
        { key: "lt", value: "Lituano", disabled: false },
        { key: "mk", value: "Macedônio", disabled: false },
        { key: "ms", value: "Malaio", disabled: false },
        { key: "mr", value: "Marata", disabled: false },
        { key: "nb", value: "Norueguês", disabled: false },
        { key: "fa", value: "Persa", disabled: false },
        { key: "pl", value: "Polonês", disabled: false },
        { key: "pa", value: "Panjabi", disabled: false },
        { key: "ro", value: "Romeno", disabled: false },
        { key: "ru", value: "Russo", disabled: false },
        { key: "sr", value: "Sérvio", disabled: false },
        { key: "sk", value: "Eslovaco", disabled: false },
        { key: "sl", value: "Esloveno", disabled: false },
        { key: "sw", value: "Suaíli", disabled: false },
        { key: "sv", value: "Sueco", disabled: false },
        { key: "ta", value: "Tâmil", disabled: false },
        { key: "te", value: "Telugu", disabled: false },
        { key: "th", value: "Tailandês", disabled: false },
        { key: "tr", value: "Turco", disabled: false },
        { key: "uk", value: "Ucraniano", disabled: false },
        { key: "ur", value: "Urdu", disabled: false },
        { key: "uz", value: "Uzbeque", disabled: false },
        { key: "vi", value: "Vietnamita", disabled: false }
    ];

    useEffect(() => {
        if (open == false) return;
        if (templateData && templateData != null) {
            setTemplate(templateData);
            return;
        }

        let isMounted = true;
        const abortController = new AbortController();

        const loadConnections = async () => {
            try {
                const { data } = await api.get("/whatsappListAll", { 
                    params: { disabled: '1' },
                    signal: abortController.signal,
                });
                if (!isMounted) return;
                setConnections(data.filter(w => w.state == 1 && w.moduleId == 1));
    
            } catch (err) {
                if (!isMounted || err.name == 'AbortError') return;
                console.error({ err });
                toastError(err);
            }
        }
        loadConnections();

        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [open]);

    const TemplateSchema = [
        Yup.object().shape({
            name: Yup.string().required(`${i18n.t("connections.whatsapp.template.modal.name")} ${i18n.t("connections.whatsapp.template.modal.required")}`),
            language: Yup.string().required(`${i18n.t("connections.whatsapp.template.modal.language")} ${i18n.t("connections.whatsapp.template.modal.required")}`),
            connection: Yup.string().required(`${i18n.t("connections.whatsapp.template.modal.connection")} ${i18n.t("connections.whatsapp.template.modal.required")}`),
            name: Yup.string().required(`${i18n.t("connections.whatsapp.template.modal.name")} ${i18n.t("connections.whatsapp.template.modal.required")}`),
            category: Yup.string().required(`${i18n.t("connections.whatsapp.template.modal.category")} ${i18n.t("connections.whatsapp.template.modal.required")}`),
            emails: Yup.array().min(1, `${i18n.t("connections.whatsapp.template.modal.email")} ${i18n.t("connections.whatsapp.template.modal.required")}`),
        }),
        Yup.object().shape({
            messageType: Yup.string().required(`${i18n.t("connections.whatsapp.template.modal.messageType.title")} ${i18n.t("connections.whatsapp.template.modal.required")}`),
        }),
        Yup.object().shape({
            messageBody: Yup.string().test('messageBody', `${i18n.t("connections.whatsapp.template.modal.messageBody")} ${i18n.t("connections.whatsapp.template.modal.required")}`, function (value) {
                if (template.messageType == "otpMessageSelected") return true;
                if (!template.messageBody || template.messageBody == "" || template.messageBody == "undefined") return false;
                return true;
            }),
            otpValidation: Yup.string().test('otpValidation', `${i18n.t("connections.whatsapp.template.modal.typeOfOtp.title")} ${i18n.t("connections.whatsapp.template.modal.required")}`, function (value) {
                if (template.messageType != "otpMessageSelected") return true;
                if (!template?.typeOfOtp || template?.typeOfOtp == "" || template?.typeOfOtp == "undefined") return false;
                return true;
            }),
        })
    ]

    // reordenar para seguir sequencia correta
    for (let x = 0; x < TemplateSchema.length; x++) {
        if (TemplateSchema[x]['_nodes']) TemplateSchema[x]['_nodes'] = TemplateSchema[x]['_nodes'].reverse();
    }


    const handleClickModalOpen = () => {
        setIsOpen(true); // Abre o modal
    };

    const handleModalClose = () => {
        setIsOpen(false); // Fecha o modal
    };

    const handleClose = (event, reason) => {

        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        setTemplate(initialState);
        setConnections([]);
        setEditingButtonId(null);
        onClose();
    }

    const handleSave = async () => {
        await handleNext(true);
    }

    const handleChangeChip = (keywords) => {
        const removeDuplicates = [...new Set(keywords)];
        if (removeDuplicates.length > 10) return;
        setTemplate((prevState) => ({
            ...prevState,
            emails: removeDuplicates
        }));
    }

    const getSteps = () => {
        return [i18n.t("connections.whatsapp.template.modal.configuration"), i18n.t("connections.whatsapp.template.modal.messageType.title"), i18n.t("connections.whatsapp.template.modal.templateContent")];
    }

    const handleNext = async (isSave = false) => {
        // antes de passar para a próxima etapa, verificar se todos os campos foram preenchidos
        let validated = false;

        try {
            setLoading(true);
            await TemplateSchema[activeStep].validate(template);
            validated = true;
            if (isSave == false) setActiveStep((prevActiveStep) => prevActiveStep + 1);

            // cadastrar
            if (isSave == true) {
                const { data } = await api.post('/template/store', template);

                setTimeout(() => {
                    handleClose();
                    toast.success(data?.message ? i18n.t(data.message) : i18n.t("generalMessages.SUCCESSFULLY_SAVED"));
                }, 500);
            }
        } catch (err) {
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleMessageType = (event) => {
        const val = event.target.value;
        const buttonText = val == "otpMessageSelected" ? i18n.t(`connections.whatsapp.template.modal.messageType.otpMessageSelected.copy`) : "";
        setTemplate((prevState) => ({
            ...prevState,
            messageType: val,
            buttonText
        }));
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setTemplate((prevState) => ({
            ...prevState,
            files
        }));
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!fileInputRef.current) return;

        const files = Array.from(event.dataTransfer.files);
        if (files.length > 1) return;
        // Extensões permitidas
        const acceptedFormats = fileInputRef.current.accept.split(',');

        // Filtra os arquivos aceitos com base nas extensões
        const validFiles = files.filter((file) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return acceptedFormats.includes(`.${fileExtension}`);
        });

        if (validFiles.length == 0) {
            toast.error(`${i18n.t("connections.whatsapp.template.modal.invalidFormatFile")} ${acceptedFormats.join(', ')}`);
            return;
        }

        setTemplate((prevState) => ({
            ...prevState,
            files: validFiles
        }));
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const isValidURL = (url) => {
        const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[^\s]*)?$/i;
        return urlRegex.test(url);
    };

    const isValidPhoneNumber = (phone) => {
        const phoneRegex = /^(?:(?:\+55)?\s?)?(?:\(?[1-9][0-9]\)?\s?)?(?:9[1-9][0-9]{3}|[2-8][0-9]{3})\-?[0-9]{4}$/;
        return phoneRegex.test(phone);
    };

    const handleAddButton = () => {
        if (!buttonsTmp?.type) {
            toast.error(`${i18n.t("connections.whatsapp.template.modal.buttonsOption.type")} ${i18n.t("connections.whatsapp.template.modal.required")}`);
            return;
        }
        if (!buttonsTmp?.text) {
            toast.error(`${i18n.t("connections.whatsapp.template.modal.buttonText")} ${i18n.t("connections.whatsapp.template.modal.required")}`);
            return;
        }

        // Validações específicas para cada tipo de botão
        if (buttonsTmp?.type === "URL") {
            if (!buttonsTmp.url) {
                toast.error(`${i18n.t("connections.whatsapp.template.modal.buttonsOption.url")} ${i18n.t("connections.whatsapp.template.modal.required")}`);
                return;
            }
            if (!isValidURL(buttonsTmp.url)) {
                toast.error(`${i18n.t("connections.whatsapp.template.modal.buttonsOption.url")} ${i18n.t("connections.whatsapp.template.modal.invalid")}`);
                return;
            }
        }
        if (buttonsTmp?.type === "PHONE_NUMBER") {
            if (!buttonsTmp.phone) {
                toast.error(`${i18n.t("connections.whatsapp.template.modal.buttonsOption.phoneNumber")} ${i18n.t("connections.whatsapp.template.modal.required")}`);
                return;
            }
            if (!isValidPhoneNumber(`+55 ${buttonsTmp.phone}`)) {
                toast.error(`${i18n.t("connections.whatsapp.template.modal.buttonsOption.phoneNumber")} ${i18n.t("connections.whatsapp.template.modal.invalid")}`);
                return;
            }
        }
        if (buttonsTmp?.type === "QUICK_REPLY" && !buttonsTmp.quickReply) {
            toast.error(`${i18n.t("connections.whatsapp.template.modal.buttonsOption.quickReply")} ${i18n.t("connections.whatsapp.template.modal.required")}`);
            return;
        }

        // Verificar se está editando ou adicionando
        if (editingButtonId) {
            // Atualizar o botão existente
            setTemplate((prevState) => ({
                ...prevState,
                buttons: prevState.buttons.map((btn) =>
                    btn.id === editingButtonId ? { ...buttonsTmp, id: editingButtonId } : btn
                ),
            }));
            setEditingButtonId(null); // Limpar o estado de edição
        } else {
            // Adicionar um novo botão
            if (Array.isArray(template.buttons) && template.buttons.length >= 3) return;
            setTemplate((prevState) => ({
                ...prevState,
                buttons: [...prevState.buttons, { ...buttonsTmp, id: new Date().getTime() }],
            }));
        }

        // Limpar o estado temporário dos botões
        setButtonsTmp(buttonsInitialState);
    };

    const handleEditButton = (button) => {
        setButtonsTmp({
            id: button.id,
            type: button.type,
            text: button.text,
            url: button.url || "",
            phone: button.phone || "",
            quickReply: button.quickReply || "",
        });
        setEditingButtonId(button.id);
    };

    const handleRemoveButton = (id) => {
        if (id && id != "") {
            setTemplate((prevState) => ({
                ...prevState,
                buttons: prevState.buttons.filter(btn => btn.id != id)
            }));
        }
    }

    return (
        <Dialog
            open={open == true ? true : false}
            TransitionComponent={Transition}
            keepMounted
            onClose={(event, reason) => handleClose(event, reason)}
            aria-describedby="alert-dialog-slide-template"
            disableEscapeKeyDown={true}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
        >
            <DialogTitle>
                {!id ? i18n.t("connections.whatsapp.template.modal.storeTitle") : i18n.t("connections.whatsapp.template.modal.editTitle")}
                <Divider sx={{ marginTop: "10px" }} />
            </DialogTitle>
            <DialogContent>

                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {getSteps().map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mb={2} hidden={activeStep != 0}>
                    <Grid item xs={8}>
                        <Typography
                            component="label"
                            sx={{ fontSize: '1.1rem', marginBottom: '10px' }}
                        >
                            {i18n.t("connections.whatsapp.template.modal.name")} <Typography color="error" component="span">*</Typography>
                        </Typography>
                        <TextField
                            name="templateName"
                            variant="outlined"
                            className="form-control"
                            fullWidth
                            value={template?.name}
                            sx={{
                                height: '43px',
                                lineHeight: '43px',
                                '& div': {
                                    height: '100%',
                                },
                            }}
                            inputProps={{ maxLength: 255 }}
                            onChange={(e) => {
                                let name = String(e.target.value);
                                setTemplate((prevState) => ({
                                    ...prevState,
                                    name,
                                }));
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <SingleSelect
                            title={
                                <Typography
                                    component="label"
                                    sx={{ fontSize: '1.1rem', marginBottom: '5px' }}
                                >
                                    {i18n.t("connections.whatsapp.template.modal.language")} <Typography color="error" component="span">*</Typography>
                                </Typography>
                            }
                            displayText={i18n.t("connections.whatsapp.template.modal.selectLanguage")}
                            name="templateLanguage"
                            key="templateLanguage"
                            disabled={false}
                            options={languages.sort((a, b) => a.value - b.value)}
                            onSelected={(opt) => {
                                setTemplate((prevState) => ({
                                    ...prevState,
                                    language: opt,
                                }));
                            }}
                            selected={template.language}
                            style={{ marginBottom: '0' }}
                            closeOnSelect={true}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={2} hidden={activeStep != 0}>
                    <Grid item xs={8}>
                        <SingleSelect
                            title={
                                <Typography
                                    component="label"
                                    sx={{ fontSize: '1.1rem', marginBottom: '5px' }}
                                >
                                    {i18n.t("connections.whatsapp.template.modal.connection")} <Typography color="error" component="span">*</Typography>
                                </Typography>
                            }
                            displayText={i18n.t("connections.whatsapp.template.modal.selectConnection")}
                            name="templateConnection"
                            key="templateConnection"
                            disabled={false}
                            options={connections.map(c => { return { key: c.id, value: c.numberConnection ? `${c.name} - ${c.numberConnection}` : c.name } }).sort((a, b) => a.name - b.name)}
                            onSelected={(opt) => {
                                setTemplate((prevState) => ({
                                    ...prevState,
                                    connection: opt,
                                }));
                            }}
                            selected={template.connection}
                            style={{ marginBottom: '0' }}
                            closeOnSelect={true}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <SingleSelect
                            title={
                                <Typography
                                    component="label"
                                    sx={{ fontSize: '1.1rem', marginBottom: '5px' }}
                                >
                                    {i18n.t("connections.whatsapp.template.modal.category")} <Typography color="error" component="span">*</Typography>
                                </Typography>
                            }
                            displayText={i18n.t("connections.whatsapp.template.modal.selectCategory")}
                            name="templateCategory"
                            key="templateCategory"
                            disabled={false}
                            options={categories.sort((a, b) => a.value - b.value)}
                            onSelected={(opt) => {
                                setTemplate((prevState) => ({
                                    ...prevState,
                                    category: opt,
                                }));
                            }}
                            selected={template.category}
                            closeOnSelect={true}
                            style={{ marginBottom: '0' }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={2} hidden={activeStep != 0}>
                    <Grid item xs={8}>
                        <Typography
                            component="label"
                            sx={{ fontSize: '1.1rem', marginBottom: '10px' }}
                        >
                            {i18n.t("connections.whatsapp.template.modal.emailInfo")}
                        </Typography>
                        <Typography
                            component="label"
                            sx={{ fontSize: '1.1rem', marginBottom: '10px' }}
                        >
                            {i18n.t("connections.whatsapp.template.modal.email")} <Typography color="error" component="span">*</Typography>
                        </Typography>
                        <MuiChipsInput
                            name="templateEmails"
                            className={classes.emails}
                            size="small"
                            placeholder={''}
                            value={template?.emails || []}
                            onChange={(e) => handleChangeChip(e)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        {
                            (template?.category && template.category != '') &&
                            <Alert severity="info" sx={{ textAlign: 'justify' }}>{i18n.t(`connections.whatsapp.template.modal.${String(template.category).toLowerCase()}`)}</Alert>
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={2} hidden={activeStep != 1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{i18n.t("connections.whatsapp.template.modal.messageType.title")}</Typography>
                        <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>{i18n.t("connections.whatsapp.template.modal.messageType.subtitle")}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={2} hidden={activeStep != 1}>
                    <Grid item xs={12}>
                        <RadioGroup
                            aria-label="templateMessageType"
                            name="templateMessageType"
                            value={template?.messageType}
                            onChange={handleMessageType}
                            style={{ display: "block" }}
                        >
                            {options.map((option) => {

                                if (option.accepts.includes(template?.category) == false) return;

                                return (<Box
                                    key={option.value}
                                    onClick={() => handleMessageType({ target: { value: option.value } })}
                                    sx={{
                                        border: "2px solid",
                                        borderColor: template?.messageType == option.value ? "primary.main" : "grey.400",
                                        borderRadius: "8px",
                                        padding: "8px 16px",
                                        cursor: "pointer",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        backgroundColor: "transparent",
                                        transition: "all 0.3s ease",
                                        width: "400px",
                                        height: "200px",
                                        marginRight: "10px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    >
                                        <Radio
                                            value={option.value}
                                            checked={template?.messageType == option.value}
                                        />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                display: "inline-block",
                                                fontWeight: template?.messageType == option.value ? '800' : '400'
                                            }}
                                        >
                                            {i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.title`)}
                                        </Typography>
                                        {
                                            option.value == 'simple' &&
                                            <>
                                                <Box
                                                    sx={{
                                                        padding: "10px",
                                                        borderRadius: "10px",
                                                        background: "#c8e6c9",
                                                        width: '100%'
                                                    }}
                                                >
                                                    {i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.body`)}
                                                </Box>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        fontSize: "0.85rem",
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    {i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.max`)}
                                                </Typography>
                                            </>
                                        }
                                        {
                                            option.value == 'customMessageSelected' &&
                                            <>
                                                <Box
                                                    sx={{
                                                        padding: "10px",
                                                        borderRadius: "10px",
                                                        background: "#c8e6c9",
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Typography sx={{ fontWeight: 700, marginBottom: '5px' }}>{i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.header`)}</Typography>
                                                    {i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.body`)}
                                                    <Typography sx={{ fontWeight: 300, fontSize: '0.85rem', marginTop: '5px' }}>{i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.footer`)}</Typography>
                                                    <Link href="#" onClick={(e) => e.preventDefault()}>{i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.buttonLink`)}</Link>
                                                </Box>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        fontSize: "0.85rem",
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    {i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.max`)}
                                                </Typography>
                                            </>
                                        }
                                        {
                                            option.value == 'otpMessageSelected' &&
                                            <>
                                                <Box
                                                    sx={{
                                                        padding: "10px",
                                                        borderRadius: "10px",
                                                        background: "#c8e6c9",
                                                        width: '100%'
                                                    }}
                                                >
                                                    {i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.body`)}
                                                    <Typography sx={{ fontWeight: 300, fontSize: '0.85rem', marginTop: '5px' }}>{i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.expires`)}</Typography>
                                                    <Link href="#" onClick={(e) => e.preventDefault()} sx={{ marginTop: '10px', textAlign: 'center', display: 'block' }}>
                                                        <FileCopyOutlinedIcon />
                                                        {i18n.t(`connections.whatsapp.template.modal.messageType.${option.value}.copy`)}
                                                    </Link>
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                </Box>
                                )
                            }
                            )}
                        </RadioGroup>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={2} hidden={activeStep != 2}>
                    <Grid item xs={12} md={7}>

                        <Box hidden={template?.messageType != "otpMessageSelected"} sx={{ padding: 0, margin: 0 }}>
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '30px' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.typeOfOtp.otpInfo")}
                                    </Typography>
                                    <SingleSelect
                                        title={
                                            <Typography
                                                component="label"
                                                sx={{ fontSize: '1.1rem', marginBottom: '5px' }}
                                            >
                                                {i18n.t("connections.whatsapp.template.modal.typeOfOtp.title")} <Typography color="error" component="span">*</Typography>
                                            </Typography>
                                        }
                                        displayText={i18n.t("connections.whatsapp.template.modal.typeOfOtp.select")}
                                        name="templateTypeOfOtp"
                                        key="templateTypeOfOtp"
                                        disabled={false}
                                        options={typeOfOtpOptions.sort((a, b) => a.value - b.value)}
                                        onSelected={(opt) => {
                                            setTemplate((prevState) => ({
                                                ...prevState,
                                                typeOfOtp: opt,
                                            }));
                                        }}
                                        selected={template?.typeOfOtp}
                                        closeOnSelect={true}
                                        style={{ marginBottom: '0' }}
                                    />
                                </Grid>
                            </Grid>
                            
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '10px' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.buttonText")}
                                    </Typography>
                                    <TextField
                                        name="templateButtonText"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        value={template?.buttonText}
                                        sx={{
                                            height: '43px',
                                            lineHeight: '43px',
                                            '& div': {
                                                height: '100%',
                                            },
                                        }}
                                        inputProps={{ maxLength: 25 }}
                                        onChange={(e) => {
                                            let buttonText = e.target.value;
                                            setTemplate((prevState) => ({
                                                ...prevState,
                                                buttonText,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={template.securityAdvisory}
                                                onChange={() => {
                                                    setTemplate((prevState) => ({
                                                        ...prevState,
                                                        securityAdvisory: !prevState.securityAdvisory,
                                                    }));
                                                }}
                                                inputProps={{ 'aria-label': 'add security advisory' }}
                                            />
                                        }
                                        label={i18n.t("connections.whatsapp.template.modal.securityAdvisory")}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={template.addExpirationTime}
                                                onChange={() => {
                                                    setTemplate((prevState) => ({
                                                        ...prevState,
                                                        addExpirationTime: !prevState.addExpirationTime,
                                                    }));
                                                }}
                                                inputProps={{ 'aria-label': 'add security advisory' }}
                                            />
                                        }
                                        label={i18n.t("connections.whatsapp.template.modal.addCodeExpirationTime")}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.addExpirationTime != true}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="templateExpirationTime"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        value={template?.expirationTime}
                                        type="number"
                                        sx={{
                                            height: '43px',
                                            lineHeight: '43px',
                                            '& div': {
                                                height: '100%',
                                            },
                                        }}
                                        inputProps={{ max: 90, placeholder: '5 minutes' }}
                                        onChange={(e) => {
                                            let expirationTime = Number(e.target.value);
                                            setTemplate((prevState) => ({
                                                ...prevState,
                                                expirationTime,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.typeOfOtp != "ONE_TAP"}>
                                <Grid item xs={6}>
                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '10px' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.packageName")}
                                    </Typography>
                                    <TextField
                                        name="templatePackageName"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        value={template?.packageName}
                                        sx={{
                                            height: '43px',
                                            lineHeight: '43px',
                                            '& div': {
                                                height: '100%',
                                            },
                                        }}
                                        inputProps={{ maxLength: 100, placeholder: "Example: com.example.myapplication" }}
                                        onChange={(e) => {
                                            let packageName = e.target.value;
                                            setTemplate((prevState) => ({
                                                ...prevState,
                                                packageName,
                                            }));
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '10px' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.applicationSignatureHash")}
                                    </Typography>
                                    <TextField
                                        name="templateApplicationSignatureHash"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        value={template?.applicationSignatureHash}
                                        sx={{
                                            height: '43px',
                                            lineHeight: '43px',
                                            '& div': {
                                                height: '100%',
                                            },
                                        }}
                                        inputProps={{ maxLength: 100, placeholder: "Example: K8a%2FAINcGX7" }}
                                        onChange={(e) => {
                                            let applicationSignatureHash = e.target.value;
                                            setTemplate((prevState) => ({
                                                ...prevState,
                                                applicationSignatureHash,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box hidden={template?.messageType != "customMessageSelected" && template?.messageType != "simple"} sx={{ padding: 0, margin: 0 }}>

                            <Grid container spacing={2} mb={2} hidden={template?.messageType != "customMessageSelected"}>
                                <Grid item xs={12}>

                                    <Grid item xs={12}>
                                        <Alert severity="info" sx={{ textAlign: 'justify', marginBottom: '10px' }}>

                                            {template?.category === "MARKETING" &&
                                                <Typography
                                                    component="label"
                                                    sx={{ fontSize: '0.938rem', marginBottom: '20px', display: 'block' }}
                                                >
                                                    {i18n.t("connections.whatsapp.template.modal.marketingInfo")}
                                                </Typography>
                                            }
                                            <Typography
                                                component="label"
                                                sx={{ fontSize: '0.938rem', marginBottom: '10px', display: 'block' }}
                                            >
                                                Você pode utilizar variáveis no seu template.
                                                <span
                                                    onClick={handleClickModalOpen}
                                                    style={{ color: 'blue', cursor: 'pointer', marginRight: '4px' }} // Estilo para indicar que é clicável
                                                >
                                                    Clique aqui
                                                </span>

                                                para saber como.
                                            </Typography>

                                            <Dialog open={isOpen} onClose={handleModalClose}>
                                                <DialogTitle>Utilizando variáveis no template</DialogTitle>
                                                <DialogContent>
                                                    <Typography variant="body1" paragraph>
                                                        Você pode personalizar sua mensagem com variáveis. Variável é a informação da mensagem que será substituída pelos dados personalizados de seus clientes.
                                                    </Typography>

                                                    <Typography variant="body1" paragraph>
                                                        Como usar?
                                                    </Typography>

                                                    <Typography variant="body1" paragraph>
                                                        Use <strong>{`{{nomeDaVariavel}}`}</strong> para fazer essa indicação, conforme o exemplo abaixo:
                                                    </Typography>

                                                    <Typography variant="body1" paragraph>
                                                        "Olá <strong>{`{{nome}}`}</strong>, nós recebemos sua mensagem em <strong>{`{{horas}}`}</strong> e entraremos em contato o mais breve possível."
                                                    </Typography>

                                                    <Typography variant="body1" paragraph>
                                                        Lembrando que você pode utilizar variáveis em todos os campos de texto do template exceto no rodapé.
                                                    </Typography>

                                                    <Typography variant="body1" paragraph>
                                                        Caso a variável ultrapasse o limite de caracteres da mensagem, ocorrerá um erro no envio da mensagem.
                                                    </Typography>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        onClick={handleModalClose}
                                                        variant="outlined"
                                                        color="error"
                                                        disabled={loading}
                                                    >
                                                        {i18n.t("connections.whatsapp.template.modal.buttons.close")}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Alert>
                                    </Grid>

                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '10px', display: 'block' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.headerOption.title")}
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="headerOption"
                                            name="headerOption"
                                            value={template?.headerOption}
                                            onChange={(e) => {
                                                let headerOption = e.target.value;
                                                setTemplate((prevState) => ({
                                                    ...prevState,
                                                    headerOption,
                                                }));

                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = '';
                                                    setTemplate((prevState) => ({
                                                        ...prevState,
                                                        files: []
                                                    }));
                                                }
                                            }}
                                            sx={{ display: "block" }}
                                        >
                                            <FormControlLabel value="" control={<Radio />} label={i18n.t("connections.whatsapp.template.modal.headerOption.without")} />
                                            <FormControlLabel value="text" control={<Radio />} label={i18n.t("connections.whatsapp.template.modal.headerOption.text")} />
                                            <FormControlLabel value="image" control={<Radio />} label={i18n.t("connections.whatsapp.template.modal.headerOption.image")} />
                                            <FormControlLabel value="document" control={<Radio />} label={i18n.t("connections.whatsapp.template.modal.headerOption.document")} />
                                            <FormControlLabel value="video" control={<Radio />} label={i18n.t("connections.whatsapp.template.modal.headerOption.video")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.messageType != "customMessageSelected" || template?.headerOption != "text"}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '10px' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.headerText")}
                                    </Typography>
                                    <TextField
                                        name="templateHeaderText"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        value={template?.headerText}
                                        inputProps={{ maxLength: 60 }}
                                        onChange={(e) => {
                                            let headerText = e.target.value;
                                            setTemplate((prevState) => ({
                                                ...prevState,
                                                headerText,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.messageType != "customMessageSelected" || ["image", "document", "video"].includes(template?.headerOption) == false}>
                                <Grid item xs={12}>
                                    <Box
                                        component="div"
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                        style={{
                                            border: '2px dashed #cccccc',
                                            borderRadius: '8px',
                                            padding: '20px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            backgroundColor: '#f9f9f9',
                                        }}
                                    >
                                        {template?.files.length == 1 &&
                                            <Typography variant="h6">{template?.files[0].name}</Typography>
                                        }
                                        <Typography component="p" sx={{ marginBottom: "20px" }}>{i18n.t("connections.whatsapp.template.modal.dragText")}</Typography>
                                        <Button
                                            type="button"
                                            onClick={handleButtonClick}
                                            style={{
                                                padding: '10px 20px',
                                                backgroundColor: '#007bff',
                                                color: '#fff',
                                                border: 'none',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {i18n.t("connections.whatsapp.template.modal.selectFile")}
                                        </Button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            accept={template?.headerOption == "image" ? ".jpg,.jpeg,.png,.gif,.bmp,.webp,.svg" : template?.headerOption == "video" ? ".mp4,.mov,.avi,.mkv,.wmv,.mpg,.mpeg" : ".pdf"}
                                            onChange={handleFileChange}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '10px' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.messageBody")} <Typography color="error" component="span">*</Typography>
                                    </Typography>
                                    <TextField
                                        name="templateMessageBody"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        multiline
                                        rows={2}
                                        value={template?.messageBody}
                                        inputProps={{ maxLength: 1024 }}
                                        onChange={(e) => {
                                            let messageBody = e.target.value;
                                            setTemplate((prevState) => ({
                                                ...prevState,
                                                messageBody,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.messageType != "customMessageSelected"}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '10px', display: 'block' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.addFooter.title")}
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="addFooter"
                                            name="addFooter"
                                            value={template?.addFooter}
                                            onChange={(e) => {
                                                let addFooter = e.target.value;
                                                setTemplate((prevState) => ({
                                                    ...prevState,
                                                    addFooter,
                                                }));
                                            }}
                                            sx={{ display: "block" }}
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label={i18n.t("connections.whatsapp.template.modal.addFooter.without")} />
                                            <FormControlLabel value="1" control={<Radio />} label={i18n.t("connections.whatsapp.template.modal.addFooter.text")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.messageType != "customMessageSelected" || template?.addFooter != 1}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '10px' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.footerText")}
                                    </Typography>
                                    <TextField
                                        name="templateFooterText"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        value={template?.footerText}
                                        inputProps={{ maxLength: 60 }}
                                        onChange={(e) => {
                                            let footerText = e.target.value;
                                            setTemplate((prevState) => ({
                                                ...prevState,
                                                footerText,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.messageType != "customMessageSelected"}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="label"
                                        sx={{ fontSize: '1.1rem', marginBottom: '10px', display: 'block' }}
                                    >
                                        {i18n.t("connections.whatsapp.template.modal.buttonsOption.title")}
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="addButtons"
                                            name="addButtons"
                                            value={template?.addButtons}
                                            onChange={(e) => {
                                                let addButtons = e.target.value;
                                                setTemplate((prevState) => ({
                                                    ...prevState,
                                                    addButtons,
                                                }));
                                            }}
                                            sx={{ display: "block" }}
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label={i18n.t("connections.whatsapp.template.modal.buttonsOption.without")} />
                                            <FormControlLabel value="1" control={<Radio />} label={i18n.t("connections.whatsapp.template.modal.buttonsOption.text")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.messageType != "customMessageSelected" || template?.addButtons != 1}>
                                <Grid item xs={12}>
                                    <SingleSelect
                                        title={
                                            <Typography
                                                component="label"
                                                sx={{ fontSize: '1.1rem', marginBottom: '5px' }}
                                            >
                                                {i18n.t("connections.whatsapp.template.modal.buttonsOption.type")}
                                            </Typography>
                                        }
                                        displayText={i18n.t("connections.whatsapp.template.modal.buttonsOption.select")}
                                        name="templateButtonType"
                                        key="templateButtonType"
                                        disabled={false}
                                        options={filteredButtons.sort((a, b) => a.order - b.order)}
                                        onSelected={(opt) => {
                                            setButtonsTmp((prevState) => ({
                                                ...prevState,
                                                type: opt,
                                            }));
                                        }}
                                        selected={buttonsTmp?.type}
                                        closeOnSelect={true}
                                        style={{ marginBottom: '0' }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.messageType != "customMessageSelected" || template?.addButtons != 1}>
                            {(buttonsTmp.type == 'QUICK_REPLY' || buttonsTmp.type == 'URL' || buttonsTmp.type == 'PHONE_NUMBER') &&
                                <Grid item xs={12}>
                                    <TextField
                                        name="templateButtonText"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        value={buttonsTmp?.text || ""}
                                        sx={{
                                            height: '43px',
                                            lineHeight: '43px',
                                            '& div': {
                                                height: '100%',
                                            },
                                        }}
                                        inputProps={{ maxLength: 25, placeholder: i18n.t("connections.whatsapp.template.modal.buttonText") }}
                                        onChange={(e) => {
                                            let text = e.target.value;
                                            setButtonsTmp((prevState) => ({
                                                ...prevState,
                                                text,
                                            }));
                                        }}
                                    />
                                </Grid>
                            }                                
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={buttonsTmp.type != 'URL'}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="templateButtonUrl"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        value={buttonsTmp?.url || ""}
                                        sx={{
                                            height: '43px',
                                            lineHeight: '43px',
                                            '& div': {
                                                height: '100%',
                                            },
                                        }}
                                        inputProps={{ maxLength: 255, placeholder: i18n.t("connections.whatsapp.template.modal.buttonsOption.url") }}
                                        onChange={(e) => {
                                            let url = e.target.value;
                                            setButtonsTmp((prevState) => ({
                                                ...prevState,
                                                url,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={buttonsTmp.type !== 'PHONE_NUMBER'}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="templateButtonPhoneNumber"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        value={buttonsTmp?.phone || ""}
                                        sx={{
                                            height: '43px',
                                            lineHeight: '43px',
                                            '& div': {
                                                height: '100%',
                                            },
                                        }}
                                        inputProps={{
                                            maxLength: 15,
                                            placeholder: i18n.t("connections.whatsapp.template.modal.buttonsOption.phoneNumber"),
                                        }}
                                        onChange={(e) => {
                                            let phone = e.target.value;

                                            // Remove todos os caracteres que não sejam números
                                            phone = phone.replace(/\D/g, '');

                                            // Aplica a formatação (xx) xxxxx-xxxx
                                            if (phone.length > 2) {
                                                phone = `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}${phone.length > 7 ? `-${phone.slice(7, 11)}` : ''}`;
                                            }

                                            // Atualiza o estado com o número formatado
                                            setButtonsTmp((prevState) => ({
                                                ...prevState,
                                                phone,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={buttonsTmp.type != 'QUICK_REPLY'}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="templateButtonQuickReply"
                                        variant="outlined"
                                        className="form-control"
                                        fullWidth
                                        type="text"
                                        value={buttonsTmp?.quickReply || ""}
                                        sx={{
                                            height: '43px',
                                            lineHeight: '43px',
                                            '& div': {
                                                height: '100%',
                                            },
                                        }}
                                        inputProps={{ maxLength: 255, placeholder: i18n.t("connections.whatsapp.template.modal.buttonsOption.quickReply") }}
                                        onChange={(e) => {
                                            let quickReply = e.target.value;
                                            setButtonsTmp((prevState) => ({
                                                ...prevState,
                                                quickReply,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.messageType != "customMessageSelected" || template?.addButtons != 1}>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={handleAddButton}
                                        sx={{ height: '100%' }}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                    >
                                        {editingButtonId ? i18n.t("connections.whatsapp.template.modal.buttons.update") : i18n.t("connections.whatsapp.template.modal.buttons.add")}
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={2} hidden={template?.addButtons != '1' || !template?.buttons || template?.buttons?.length == 0}>
                                <Grid item xs={12}>
                                    <List
                                        component="nav"
                                    >
                                        {template.buttons.map(button => (
                                            <ListItem
                                                key={button.id}
                                                style={{
                                                    cursor: "default",
                                                    marginBottom: "10px",
                                                    background: "#ffffff",
                                                    borderRadius: "10px",
                                                    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center", // Centraliza ícone e texto horizontalmente
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <ListItemIcon style={{ minWidth: "unset" }}>
                                                        {button.type == "PHONE_NUMBER" && <PhoneOutlinedIcon />}
                                                        {button.type == "URL" && <ExitToAppOutlinedIcon />}
                                                    </ListItemIcon>

                                                    <ListItemText
                                                        primary={button.text || ""}
                                                        style={{
                                                            textAlign: "center", // Centraliza o texto
                                                        }}
                                                    />

                                                    <Box display="flex" gap="10px">
                                                        <IconButton onClick={() => handleEditButton(button)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            sx={{ color: "#d50000" }}
                                                            onClick={() => { handleRemoveButton(button.id) }}
                                                        >
                                                            <DeleteOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </Box>

                                                    {/* <ListItemSecondaryAction>
                                                        <IconButton 
                                                            edge="end" 
                                                            aria-label="delete"
                                                            sx={{ color: "#d50000" }}
                                                            onClick={() => { handleRemoveButton(button.id) }}
                                                        >
                                                            <DeleteOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction> */}
                                                </div>
                                            </ListItem>
                                        ))}

                                    </List>
                                </Grid>
                            </Grid>

                        </Box>

                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box
                            sx={{
                                background: '#eeeeee',
                                minHeight: '300px',
                                border: '15px solid',
                                borderColor: '#0b708c',
                                borderBottom: 'none',
                                borderTopRightRadius: '50px',
                                borderTopLeftRadius: '50px',
                                paddingBottom: "10px",
                                position: "sticky",
                                top: 0,
                            }}
                        >
                            <Box
                                component="header"
                                sx={{
                                    background: "#ffffff",
                                    color: "#424242",
                                    fontFamily: "Blouse, sans-serif",
                                    textShadow: "1px 1px #424242",
                                    fontSize: "1.4rem",
                                    height: "70px",
                                    borderTopRightRadius: "35px",
                                    borderTopLeftRadius: "35px",
                                    padding: "20px 15px 15px 15px",
                                    marginBottom: "20px",
                                    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)"
                                }}
                            >
                                <img
                                    src={Logo}
                                    style={{
                                        height: "auto",
                                        width: "100%",
                                        margin: "0 auto",
                                        objectFit: "contain",
                                        maxWidth: "180px",
                                        display: "block",
                                    }}
                                />
                            </Box>
                            <Box
                                component="div"
                                sx={{
                                    background: "#fff",
                                    padding: "10px",
                                    minHeight: "70px",
                                    borderRadius: "15px",
                                    maxWidth: "80%",
                                    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
                                    margin: "0 auto 10px 10px",
                                    fontSize: "1.1rem",
                                    wordBreak: "break-word"
                                }}
                            >
                                {
                                    template?.messageType == "otpMessageSelected" &&
                                    <Box>
                                        <Typography variant="button" sx={{ fontWeight: 700, fontSize: "1.1rem" }}>55908 </Typography>
                                        {i18n.t("connections.whatsapp.template.modal.otpMessage")}
                                        {template.securityAdvisory == true && i18n.t("connections.whatsapp.template.modal.securityAdvisoryMessage")}
                                        {(template?.addExpirationTime == true && template?.expirationTime > 0) &&
                                            <Typography component="footer" sx={{ fontWeight: 300, fontSize: '0.85rem', marginTop: '5px' }}>
                                                {String(i18n.t("connections.whatsapp.template.modal.expirationTimeMessage")).replace("{minutes}", template?.expirationTime)}
                                            </Typography>
                                        }
                                        {
                                            template?.buttonText != "" &&
                                            <Link href="#" onClick={(e) => e.preventDefault()} sx={{ marginTop: '10px', textAlign: 'center', display: 'block' }}>
                                                <FileCopyOutlinedIcon />
                                                {template?.buttonText}
                                            </Link>
                                        }
                                    </Box>
                                }
                                {
                                    (template?.messageType == "simple" || template?.messageType == "customMessageSelected") &&
                                    <Box>
                                        {
                                            ["image", "document", "video"].includes(template?.headerOption) &&
                                            <Box
                                                sx={{
                                                    width: "calc(100% + 17px)",
                                                    height: "100px",
                                                    position: "relative",
                                                    background: "#bdbdbd",
                                                    left: "-8px",
                                                    top: "-8px",
                                                    borderTopLeftRadius: "15px",
                                                    borderTopRightRadius: "15px",
                                                    display: "flex"
                                                }}
                                            >
                                                {template.headerOption == 'document' && <DescriptionOutlinedIcon style={{ color: "#ffffff", fontSize: "50px", margin: "20px auto", display: "block" }} />}
                                                {template.headerOption == 'video' && <VideocamOutlinedIcon style={{ color: "#ffffff", fontSize: "50px", margin: "20px auto", display: "block" }} />}
                                                {(template.headerOption == 'image' && template?.files.length == 0) && <ImageOutlinedIcon style={{ color: "#ffffff", fontSize: "50px", margin: "20px auto", display: "block" }} />}
                                                {(template.headerOption == 'image' && template?.files.length == 1) && <img src={URL.createObjectURL(template?.files[0])} style={{ height: "auto", width: "100%", objectFit: "contain", margin: "5px auto" }} />}
                                            </Box>
                                        }
                                        {(template.messageType == "customMessageSelected" && template?.headerOption == "text" && template.headerText != "") && <Typography sx={{ fontWeight: 700, marginBottom: '5px' }}>{template.headerText}</Typography>}
                                        {template?.messageBody || ""}
                                        {(template.messageType == "customMessageSelected" && template?.footerText && template.footerText != "") &&
                                            <Typography component="footer" sx={{ fontWeight: 300, fontSize: '0.85rem', marginTop: '5px' }}>
                                                {template.footerText}
                                            </Typography>
                                        }
                                        <Box hidden={template?.addButtons != '1' || !template?.buttons || template?.buttons?.length == 0}>
                                            <List
                                                component="nav"
                                            >
                                                {template.buttons.map(button => (
                                                    <ListItem
                                                        key={button.id}
                                                        style={{
                                                            cursor: "default",
                                                            marginBottom: "10px",
                                                            background: "#ffffff",
                                                            borderRadius: "10px",
                                                            boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center", // Centraliza ícone e texto horizontalmente
                                                        }}
                                                    >
                                                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                            <ListItemIcon style={{ minWidth: "unset" }}>
                                                                {button.type == "PHONE_NUMBER" && <PhoneOutlinedIcon />}
                                                                {button.type == "URL" && <ExitToAppOutlinedIcon />}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={button.text || ""}
                                                                style={{
                                                                    textAlign: "center", // Centraliza o texto
                                                                }}
                                                            />
                                                        </div>
                                                    </ListItem>
                                                ))}

                                            </List>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (activeStep == 0) handleClose();
                        else handleBack();
                    }}
                    variant="outlined"
                    color="error"
                    disabled={loading}
                >
                    {activeStep == 0 ? i18n.t("connections.whatsapp.template.modal.buttons.cancel") : i18n.t("connections.whatsapp.template.modal.buttons.previous")}
                </Button>
                <Button
                    onClick={() => {
                        if (activeStep < (getSteps().length - 1)) handleNext();
                        else handleSave();
                    }}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    {
                        loading ?
                            <CircularProgress size={24} style={{ color: "#fff" }} />
                            :
                            activeStep < (getSteps().length - 1) ? i18n.t("connections.whatsapp.template.modal.buttons.next") : i18n.t("connections.whatsapp.template.modal.buttons.save")
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default StoreModal;