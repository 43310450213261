import { useEffect, useState } from "react";

import { FormControlLabel, Select, Switch, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { Grid, Checkbox } from "@material-ui/core";
import { useCallback } from "react";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	}

}));

const ByCompanyModal = ({ open, onClose, companyId }) => {
	const classes = useStyles();
	const [valor, setValor] = useState('');
	const [loading, setLoading] = useState(false);
	const [components, setComponents] = useState([]);
	const [selectedComponents, setSelectedComponents] = useState([]);

	const clearInput = () => {
		setComponents([]);
		setSelectedComponents([]);
	}

	useEffect(() => {

		if (!companyId || open == false) {
			clearInput();
			return;
		}

		let isMounted = true;
        const abortController = new AbortController();

		(async () => {

			try {
				setLoading(true);

				// resgatar todos os componentes
				const { data: componentsData } = await api.get('component/listAll',{
					signal: abortController.signal,
				});

				if (!isMounted) return;

				// resgatar todos os componentes desta empresa
				const { data: componentsByCompany } = await api.get('/component/listByCompany', {
					params: { companyId },
					signal: abortController.signal,
				});

				if (!isMounted) return;

				if (componentsData?.components) setComponents(componentsData.components);
				if (componentsByCompany?.components) setSelectedComponents(componentsByCompany.components.map(c => c.id));

			} catch (err) {
				if (!isMounted || err.name == 'AbortError') return;
				console.error({ err });
				toastError(err);
			} finally {
				setLoading(false);
			}
		})();

		return () => {
            isMounted = false;
            abortController.abort();
        };

	}, [companyId, open]);

	const handleClose = () => {
		clearInput();
		onClose();
	};

	const handleSelectedComponent = useCallback((componentId, isChecked) => {
        setSelectedComponents((prev) =>
            isChecked ? [...prev, componentId] : prev.filter(id => id !== componentId)
        );
    }, []);

	const handleSave = async () => {

		try {
			setLoading(true);
			await api.post(`/component/updateLinks/${companyId}`, {componentIds: selectedComponents});
			toast.success(i18n.t('component.messages.updatedLinks'));
			handleClose();
		} catch (err) {
			console.error({ err });
			toastError(err);
		} finally {
			setLoading(false);
		}

	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open == true ? true : false}
				onClose={handleClose}
				maxWidth="md"
				fullWidth

				scroll="paper"

			>
				<DialogTitle>{i18n.t('component.byCompanyModal.title')}</DialogTitle>
				<DialogContent dividers>
					{(components && components.length > 0) && components.map(component => {
						return (
							<Grid
								container
								spacing={2}
								alignItems="center"
								key={`component-row-${component.id}`}
							>
								<Grid
									item
									xs={1}
									style={{ position: "relative" }}
								>
									<FormControlLabel
										color="success"
										style={{ margin: '0px' }}
										control={
											<Checkbox
												checked={selectedComponents.includes(component.id)}
												onChange={(e) => handleSelectedComponent(component.id, e.target.checked)}
												id={`component${component.id}`}
												name={`component${component.id}`}
												value={component.id}
											/>
										}
									/>
								</Grid>
								<Grid
									item
									xs={2}
									style={{
										position: "relative",
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{component.name}
								</Grid>
								<Grid
									item
									xs={9}
									style={{
										position: "relative",
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{component.description}
								</Grid>
							</Grid>

						);
					})}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t('component.byCompanyModal.cancel')}
					</Button>
					<Button
						type="submit"
						color="primary"
						disabled={loading}
						variant="contained"
						className={classes.btnWrapper}
						onClick={handleSave}
					>
						{i18n.t('component.byCompanyModal.save')}
						{loading && (
							<CircularProgress
								size={24}
								className={classes.buttonProgress}
							/>
						)}
					</Button>
				</DialogActions>

			</Dialog>
		</div>
	);
};

export default ByCompanyModal;
