import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../../translate/i18n";
import toastError from "../../../errors/toastError";
import { Grid, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Select, MenuItem, Tooltip } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import api from "../../../services/api";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
}));

const OriginStoreModal = ({ open, onClose, originId }) => {

    const classes = useStyles();

    const initialState = {
        name: null,
    };
    const [origin, setOrigin] = useState(initialState);

    useEffect(() => {
        if (!open || !originId) return;
        let isMounted = true;
        const abortController = new AbortController();

        (async () => {
            try {
            	const { data } = await api.get(`/customerRelationship/origin/${originId}`, {
                    signal: abortController.signal,
                });
                if (!isMounted) return;
                if (data.origin) {
                    setOrigin(prevState => {
                        return { ...prevState, ...data.origin };
                    });
                }
            } catch (err) {
                if (!isMounted || err.name == 'AbortError') return;
            	console.error({err});
            	toastError(err);
            }
        })();

        return () => {
            isMounted = false;
            abortController.abort();
        };

    }, [open]);

    const handleClose = (event, reason) => {
        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        onClose();
        setOrigin(initialState);
    };

    const handleSave = async () => {
        try {
            if (!origin.name || origin.name == '') { toastError({ response: { data: { error: i18n.t('customerRelationship.configuration.origin.messages.nameRequired') } } }); return; }

            if (originId) {
                await api.put(`/customerRelationship/origin/update/${originId}`, { ...origin });
            } else {
                await api.post(`/customerRelationship/origin/create`, { ...origin });
            }

            toast.success(i18n.t('backendSuccess.SAVED_SUCCESSFULLY'));
            handleClose();
        } catch (err) {
            console.error({ err });
            toastError(err);
        }
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open == true ? true : false}
                onClose={(event, reason) => handleClose(event, reason)}
                scroll="paper"
                fullWidth="sm"
                maxWidth="sm"
                disableEscapeKeyDown={true}
            >
                <DialogTitle>
                    {i18n.t('customerRelationship.configuration.origin.title')}
                </DialogTitle>
                <DialogContent style={{ padding: "0 25px", height: "50vh", overflowY: "auto" }}>
                    <Grid container spacing={1} style={{ marginBottom: "10px" }}>
                        <Grid item sm={12} xs={12} mb={3}>
                            <Typography component="label">
                                {i18n.t('customerRelationship.configuration.origin.form.name')}
                                <Typography component="span" className="text-danger ml-2">*</Typography>
                            </Typography>
                            <TextField
                                id="crmOriginName"
                                key="crmOriginName"
                                placeholder={i18n.t('customerRelationship.configuration.origin.form.name')}
                                variant="outlined"
                                style={{ marginTop: "10px" }}
                                value={origin.name || null}
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                size="small"
                                onChange={(e) => setOrigin({ ...origin, name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
                    >
                        {i18n.t("buttons.close")}
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                    >
                        {i18n.t("buttons.save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default OriginStoreModal;