import { Grid, ListSubheader, Select } from "@material-ui/core";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from '@material-ui/icons/CheckBox'; // icone de selecionar todos em massa
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import FilterListTwoToneIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Box, Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useContext, useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import socket from "../../hooks/useSocket";
import useTickets from "../../hooks/useTickets";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import BatchConclusionMessages from "../BatchConclusionMessages"; // componente de conclusao de ticket em massa
import BatchTagModal from "../BatchTagModal"; // componente de vincular etiqueta em massa
import ContactListItem from "../ContactListItem";
import SelectAction from "../SelectAction/SelectAction";
import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";
import FilteredTickets from "../../helpers/FilteredTickets";
import { listDrafts } from "../../helpers/Drafts";
import GetUrlTicketPath from "../../helpers/GetUrlTicketPath";

const removeDuplicated = (tickets, status) => {
	let filteredTickets = tickets.filter(ticket => {
		// Verifica se o ticket tem o status correspondente
		if (status === 'open' || status === 'closed' || status === 'pending') {
			return ticket.status === status;
		} else {
			return true; // Se status não for especificado ou não corresponder aos status conhecidos, mantém o ticket
		}
	});

	let uniqueTicketsList = filteredTickets.reduce((accumulator, currentValue) => {
		// Verifica se o id do objeto atual já está presente na lista acumuladora
		let found = accumulator.some(item => item.id === currentValue.id);

		// Se o id não estiver presente, adiciona o objeto à lista acumuladora
		if (!found) {
			accumulator.push(currentValue);
		}

		return accumulator;
	}, []);

	return filteredTickets;
};

const useStyles = makeStyles(theme => ({
	ticketsListWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},

	ticketsList: {
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderTop: "2px solid rgba(0, 0, 0, 0.12)",
	},

	ticketsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	ticketsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#106ba3',
		},
	},
	contactTitle: {
		fontSize: '1.1rem',
		margin: '10px 0 10px 10px!important',
		color: 'rgba(0, 0, 0, 0.54)!important',
		fontWeight: '700!important'
	},
	actionIcons: {
		cursor: 'pointer', 
		marginLeft: '5px', 
		height: '20px',
		[theme.breakpoints.down('960')]: {
			marginLeft: '10px',
		}
	}
}));

const reducer = (state, action) => {
	const statusTab = action.status;

	if (action.type === "LOAD_TICKETS") {
		const newTickets = action.payload;
		newTickets.forEach((ticket) => {
			const ticketIndex = state.findIndex((t) => t.id === ticket.id);
			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
				if (ticket.unreadMessages > 0) {
					state.unshift(state.splice(ticketIndex, 1)[0]);
				}
			} else {
				state.push(ticket);
			}
		});
		return [...newTickets];
	}

	if (action.type === "RESET_UNREAD") {
		const { ticketId, lastMessage } = action.payload || {};
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state[ticketIndex].unreadMessages = 0;
			if (lastMessage) state[ticketIndex].lastMessage = lastMessage;
		}
		return [...state];
	}

	if (action.type === "UPDATE_TICKET") {

		const ticket = action.payload;

		const ticketIndex = state.findIndex(t => t.id == ticket.id && t.uuid == ticket.uuid);
		if (ticket.status !== statusTab) {
			if (ticketIndex != -1) {
				state.splice(ticketIndex, 1);
				return [...state];
			}
			else return [...state];
		}

		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
		} else {
			state.unshift(ticket);
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		const ticket = action.payload;
		if (!ticket?.queueId) return [...state];
		const ticketIndex = state.findIndex(t => t.id == ticket.id && t.uuid == ticket.uuid);
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
			state.unshift(...state.splice(ticketIndex, 1));
		} else {
			state.unshift(ticket);
		}
		return [...state];
	}

	if (action.type === "UPDATE_TICKET_CONTACT") {
		const contact = action.payload;
		if (contact) {
			const ticketIndex = state.findIndex(t => t.contactId === contact?.id);
			if (ticketIndex !== -1) {
				state[ticketIndex].Contact = contact;
			}
		}
		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			// verificar se o atendimento está aberto na tela
			const currentUuid = GetUrlTicketPath();
			const tckt = state[ticketIndex];
			if (tckt && currentUuid == tckt.uuid) {
				setTimeout(() => {
					const btnClose = document.querySelector('#btnCloseTicketOnHeader');
					if (btnClose) btnClose.click();
				}, 1000);
			}

			state.splice(ticketIndex, 1);
		}

		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}

	// Retorne o estado original se nenhuma ação corresponder
	return state;
};

const TicketsList = ({ status, selectedStatus, searchParam, showAll, selectedQueueIds, tagsFilter }) => {
	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(1);
	const [pressed, setPressed] = useState(false);
	const [order, setOrder] = useState('DESC');
	const [ticketsList, dispatch] = useReducer(reducer, []);
	const [sortedTicketList, setSortedTicketList] = useState(ticketsList);
	const [users, setUsers] = useState([]);
	const [sortedUnreadTickets, setSortedUnreadTickets] = useState(ticketsList);
	const Icon = KeyboardDoubleArrowDownIcon;
	const IconTwo = FilterListTwoToneIcon;
	const { user } = useContext(AuthContext);
	const { adminId, profile, queues } = user;
	const [conclusionModalOpen, setConclusionModalOpen] = useState(false); // close ticket modal
	const [TagModalOpen, setTagModalOpen] = useState(false); // tag modal
	const [selectedTickets, setSelectedTickets] = useState([]);
	const history = useHistory();
	const [searchContacts, setSearchContacts] = useState([]);
	const [drafts, setDrafts] = useState([]);
	const [totalTickets, setTotalTickets] = useState([]);

	useEffect(() => {

		let isMounted = true;
        const abortController = new AbortController();

		async function getUsers() {
			try {
				const { data } = await api.get("/users-dropDown", {
					signal: abortController.signal,
				});
				if (!isMounted) return;
				return data;
			} catch (error) {
				if (!isMounted || error.name == 'AbortError') return;
				console.error(error);
				throw error;
			}
		}

		async function getTotalTickets() {
			try {
				const { data } = await api.get("/tickets/getTotal", {
					params: { status },
					signal: abortController.signal,
				});
				if (!isMounted) return;
				return data;
			} catch (error) {
				if (!isMounted || error.name == 'AbortError') return;
				console.error(error);
				throw error;
			}
		}

		(async () => {
			try {
				const [usersData] = await Promise.all([getUsers()]);
				setUsers(usersData);
				
				const { total } = await getTotalTickets();
				setTotalTickets(total);

			} catch (error) {
				// Trate os erros de forma apropriada aqui
				console.error(error);
			}
		})();

		setDrafts(listDrafts());

		const draftEventName = `draft-${user.id}`;
		const draftEventFunction = async (data) => {
			const { action, payload } = data;
			if (!action || !payload) return;

			handleDrafts(action, payload);
		}

		socket.on(draftEventName, draftEventFunction);

		return () => {
			socket.off(draftEventName, draftEventFunction);
			isMounted = false;
            abortController.abort();
		}
	}, []);

	const handleDrafts = (action, payload) => {
		if (action == 'update') {
			// inserir ou atualizar
			const draftsTmp = drafts;
			const idx = draftsTmp.findIndex(obj => obj.uuid === payload.uuid);
			const props = {
				uuid: payload.uuid,
				text: payload.text,
				timestamp: payload.timestamp,
			};

			if (idx != -1) draftsTmp[idx] = props;
			else draftsTmp.push(props);

			setDrafts(draftsTmp);

		} else if (action == 'delete') {

			// remover
			const draftsTmp = drafts;
			const idx = draftsTmp.findIndex(obj => obj.uuid === payload.uuid);

			if (idx != -1) {
				draftsTmp.splice(idx, 1);
				setDrafts(draftsTmp);
			}
		}
	}

	const checkDrafts = () => {
		// listar rascunhos
		const draftsTmp = listDrafts();

		// se o rascunho não existe na variavel local, adiciona
		for (const draft of draftsTmp) {
			if (drafts.findIndex(dft => dft.uuid == draft.uuid) == -1) {
				const props = {
					uuid: draft.uuid,
					text: draft.text,
					timestamp: draft.timestamp,
				};
				setDrafts([...drafts, props]);
			}
		}

		// se existir na variavel local, e no localstora não, remove
		let updated = false;
		let draftsTemp = drafts;
		for (let x = draftsTemp.length - 1; x >= 0; x--) {
			if (draftsTmp.findIndex(dft => dft.uuid == draftsTemp[x].uuid) == -1) {
				updated = true
				draftsTemp.splice(x, 1);
			}
		}

		if (updated == true) setDrafts(draftsTemp);

		setTimeout(checkDrafts, 45000)
	}

	useEffect(() => {
		checkDrafts();

		// Limpar o timeout ao desmontar o componente
		return () => clearTimeout(checkDrafts);
	},[]);

	const handleSelectAll = () => {
		if (selectedTickets.length === ticketsList.length) {
			setSelectedTickets([]);
			return;
		}
		const allTicketIds = ticketsList.map(ticket => ticket.id);
		setSelectedTickets(allTicketIds);

	};

	// manipulando o modal de finalizacao em massa
	const showBatchConclusionMessages = () => {
		setConclusionModalOpen(true);
	};

	const hideBatchConclusionMessages = () => {
		setConclusionModalOpen(false);
		setSelectedTickets([]);
	}

	// manipulando o modal de tags em massa
	const showTagModalOpen = () => {
		setTagModalOpen(true);
	};

	const hideTagModalOpen = () => {
		setTagModalOpen(false);
		setSelectedTickets([]);
	};

	const generalActions = async (option, props) => {
		if (!option) return;

		try {
			let response;

			if (option === "closed") {
				const { message, reason, userId, conclusionId } = props;

				await Promise.all(selectedTickets.map(async (ticket) => {
					await api.put(`/tickets/${ticket}`, {
						status: "closed",
						reason,
						userId,
						conclusionId,
						inBatch: true
					});
				}));

				toast.success('Atualizado com sucesso!');
				hideBatchConclusionMessages();
				setSelectedTickets([]);
				history.push("/tickets");
			}
			else if (option === "open") {
				response = await api.post(`/tickets/generalActions`, {
					ticketsIds: selectedTickets,
					option,
					props,
					inBatch: true
				});
				setSelectedTickets([]);
			} else if (option === "tag" || option === "removeTags") {
				response = await api.post(`/tickets/generalActions`, {
					ticketsIds: selectedTickets,
					option,
					props
				});
				setSelectedTickets([]);
			} else if (option === "setUnread") {
				response = await api.post(`/tickets/setUnread`, {
					ticketsIds: selectedTickets,
				});
				setSelectedTickets([]);
			} else {
				response = await api.post(`/tickets/generalActions`, {
					ticketsIds: selectedTickets,
					option,
					inBatch: true
				});
				setSelectedTickets([]);
			}

			if (response) {
				toast.success('Atualizado com sucesso!');
				history.push("/tickets");
			}
		} catch (err) {
			console.error({err});
			const errorMessages = Array.isArray(err.response?.data?.error) ?
				err.response.data.error.map(er => er.message) :
				[err.response?.data?.error?.message || 'Erro desconhecido'];

			errorMessages.forEach(errorMessage => {
				const obj = { response: { data: { error: errorMessage } } };
				toastError(obj);
			});
		}
	};

	// Resetar e configurar a página quando houver alterações nos filtros ou no status dos tickets
	// useEffect(() => {
	// 	dispatch({ type: "RESET" });
	// 	setPageNumber(1);
	// }, [status, searchParam, showAll, selectedQueueIds, tagsFilter]);


	useEffect(() => {

		let tickets, sortUnreadTicketsList;

		const unreadTicketsList = ticketsList.filter(ticket => ticket.unreadMessages > 0);

		tickets = [...ticketsList].sort((a, b) => {
			let dtA = a?.lastMessageDate || a?.updatedAt;
			let dtB = b?.lastMessageDate || b?.updatedAt;
			const dateA = new Date(dtA).getTime();
			const dateB = new Date(dtB).getTime();
			return order === "ASC" ? dateA - dateB : dateB - dateA;
		});
		sortUnreadTicketsList = [...unreadTicketsList].sort((a, b) => {
			let dtA = a?.lastMessageDate || a?.updatedAt;
			let dtB = b?.lastMessageDate || b?.updatedAt;
			const dateA = new Date(dtA).getTime();
			const dateB = new Date(dtB).getTime();
			return order === "ASC" ? dateA - dateB : dateB - dateA;
		});

		const unifiedIds = removeDuplicated(tickets, status);
		setSortedTicketList(unifiedIds);
		setSortedUnreadTickets(sortUnreadTicketsList);
	}, [order, ticketsList]);

	const handleCheckboxChange = (clickedTicket) => {
		setSelectedTickets(prevSelectedTickets => {
			if (prevSelectedTickets.includes(clickedTicket)) {
				return prevSelectedTickets.filter(ticketId => ticketId !== clickedTicket);
			} else {
				return [...prevSelectedTickets, clickedTicket];
			}
		});
	};

	const ticketsCounter = (action, contentStatus, ticketId) => {
		if (contentStatus !== status) return;
	  	  
		switch (action) {
		  case "delete":
			// Remove o ticketId se ele existir no array
			setTotalTickets(prev => prev.filter(ticket => ticket !== ticketId));
			break;
	  
		  case "update":
			// Adiciona o ticketId se ele ainda não estiver no array
			setTotalTickets(prev => {
			  return prev.includes(ticketId) ? prev : [...prev, ticketId];
			});
			break;
	  
		  default:
			console.warn(`Unknown action: ${action}`);
			break;
		}
	};
	  
	const { tickets, hasMore, contacts, loading, setLoading } = useTickets({
		pageNumber,
		searchParam,
		tagsFilter,
		status,
		showAll,
		queueIds: JSON.stringify(selectedQueueIds),
	});
	
	function findInArray(obj) {
		const numbers = [];
		obj.map(item => {
			item.Whatsapps.map(w => {
				if (w.numberConnection && w.numberConnection.trim() != '' && !numbers.includes(w.numberConnection.trim())) numbers.push(w.numberConnection.trim());
			})
		});
		return numbers;
	}

	function findQueueId(obj) {
		return obj.map(item => item.id);
	}

	useEffect(() => {

		if (!status && !searchParam) return;

		const numeros = findInArray(user.queues);

		let minimizeTicket = false;
		
		const filteredTickets = FilteredTickets(tickets, tagsFilter, numeros, user, selectedQueueIds);

		if (filteredTickets.length === 0) {
			dispatch({ type: "RESET" });
			setPageNumber(1);
			minimizeTicket = true;
		} else {
			dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });
			const currentUuid = GetUrlTicketPath();
			if (filteredTickets.findIndex(ft => ft.uuid == currentUuid) == -1) {
				minimizeTicket = true;
			}
		}

		if ((Array.isArray(tickets) && tickets.length > 0) && minimizeTicket == true && status == selectedStatus) {
			history.push('/tickets');
		}
	}, [tickets, status, searchParam, user, tagsFilter, selectedQueueIds]);

	// resgatar contatos pesquisados
	useEffect(() => {
		if (contacts && Array.isArray(contacts) && contacts.length > 0) setSearchContacts(contacts);
		else setSearchContacts([]);
	}, [contacts]);


	const shouldUpdateTicket = (ticket, tagsFilter, user, selectedQueueIds) => {
		if (!ticket) return false;

		// Filtrar etiquetas
		const hasTags = ticket?.Contact?.tagsId && Array.isArray(ticket?.Contact?.tagsId) && ticket?.Contact?.tagsId.length > 0;
		const hasTagsFilter = tagsFilter && Array.isArray(tagsFilter) && tagsFilter.length > 0;

		const hasMatchingTag = hasTags
			? ticket?.Contact?.tagsId.some(tag => tagsFilter.includes(tag.id)) && hasTagsFilter
			: hasTagsFilter && tagsFilter.includes(null);  // Verifica se null ou array vazio está no filtro

		if (!hasMatchingTag) return false;

		// Verificar atendente
		if (!user) return false;
        if (ticket?.userId && ticket?.userId !== user.id) return false;

		// Verificar conexão se o módulo for de WhatsApp
		const whatsappNumbers = findInArray(user?.queues);
        if (ticket?.whatsapp?.moduleId == 1 && !whatsappNumbers.includes(ticket?.whatsapp?.numberConnection)) return false;

		// Verificar fila
		if (!selectedQueueIds.includes(ticket?.queueId)) return false;

		return true;
	};

	const notBelongsToUserQueues = (ticket, selectedQueueIds) =>
		ticket?.queueId && selectedQueueIds?.indexOf(ticket?.queueId) === -1;


	const handleSocketEvents = (data, user, queues, profile, status, tagsFilter, selectedQueueIds, adminId) => {

		let ticket = data.ticket;
		if (!ticket && data?.Ticket) ticket = data.Ticket;
		if (!ticket && data?.message?.Ticket) ticket = data.message.Ticket;

		switch (data.action) {
			case "updateUnread":
				dispatch({
					type: "RESET_UNREAD",
					payload: { ticketId: data.ticketId, lastMessage: data.ticket.lastMessage },
					status
				});
				break;

			case "update":
				if (shouldUpdateTicket(ticket, tagsFilter, user, selectedQueueIds)) {
					ticketsCounter("update", data.content?.status || ticket?.status, ticket.id);
					const numeros = findInArray(user.queues);
					const filter = numeros.includes(ticket.numberConnection);

					if (user.id === ticket?.userId || filter || ticket?.isGroup) {
						dispatch({
							type: "UPDATE_TICKET",
							payload: ticket,
							transfer: data.transfer,
							status
						});

					}
				}

				if (notBelongsToUserQueues(ticket, selectedQueueIds) || (ticket?.userId !== null && ticket?.userId !== user.id && !ticket?.isGroup)) {
					if (ticket?.id) dispatch({ type: "DELETE_TICKET", payload: ticket.id, status });
					ticketsCounter("delete", data.content?.status || ticket?.status, ticket?.id || data?.content?.ticketId);
				}
				break;

			case "delete":
				if (data.oldStatus === status) {
					dispatch({ type: "DELETE_TICKET", payload: data.ticketId, status });					
					if (ticket?.inChatbotFlow != true) ticketsCounter("delete", data.oldStatus, data.ticketId);
				}
				break;

			case "create":

				// caso não esteja na fila, não inserir
				const queueIds = queues.map(q => q.id);
				if (queueIds.indexOf(ticket.Queue?.id) === -1 || ticket.Queue === null) return;

				if ((!status || status == 'undefined') && searchParam?.length > 0) return;

				if (shouldUpdateTicket(ticket, tagsFilter, user, selectedQueueIds)) {

					dispatch({
						type: "UPDATE_TICKET_UNREAD_MESSAGES",
						payload: ticket,
						status
					});
				}
				break;
				

			case "updateContact":
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: data.contact,
					status
				});
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		const handleTicketUpdate = data => handleSocketEvents(data, user, queues, profile, status, tagsFilter, selectedQueueIds, adminId);
		const handleAppMessage = data => handleSocketEvents(data, user, queues, profile, status, tagsFilter, selectedQueueIds, adminId);
		const handleContactUpdate = data => handleSocketEvents({ ...data, action: "updateContact" }, user, queues, profile, status, tagsFilter, selectedQueueIds, adminId);

		socket.on(`ticket-${adminId}`, handleTicketUpdate);
		socket.on(`appMessage-${adminId}`, handleAppMessage);
		socket.on(`contact-${adminId}`, handleContactUpdate);

		return () => {
			socket.off(`ticket-${adminId}`, handleTicketUpdate);
			socket.off(`appMessage-${adminId}`, handleAppMessage);
			socket.off(`contact-${adminId}`, handleContactUpdate);
		}

	}, [status, showAll, user, selectedQueueIds, profile, queues, tagsFilter, adminId, searchParam]);

	const loadMore = () => {
		setLoading(true);
		setPageNumber(pageNumber + 1);
	};

	const handleScroll = e => {

		if (!hasMore || loading || searchParam) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	const ticketsArray = removeDuplicated(ticketsList, status);

	return (
		<div className={classes.ticketsListWrapper}>
			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.ticketsList}
				onScroll={handleScroll}
			>
				<List style={{ paddingTop: 0 }} id="ticketList">
					{status === "open" && (
						<ListSubheader style={{ height: '35px', zIndex: 5 }} className={classes.ticketsListHeader}>
							<div>
								{i18n.t("ticketsList.assignedHeader")}
								<span className={classes.ticketsCount}>
									{!Array.isArray(totalTickets) || totalTickets.length == 0 ? ticketsArray.length : `${ticketsArray.length} / ${totalTickets.length}`}
								</span>
							</div>

							<Grid style={{ display: "block" }}>

								{status === "open" && selectedTickets.length > 0 && <SelectAction
									status={status}
									selectedTickets={selectedTickets}
									onTransferToAwait={() => generalActions("pending")}
									onConcludeTicket={() => showBatchConclusionMessages()}
									onTagModal={() => showTagModalOpen()}
									onRemoveTags={() => generalActions("removeTags")}
									onUnread={() => generalActions("setUnread")}
								/>}

								<Tooltip title="Selecionar Todos os Atendimentos">
									<CheckBoxIcon
										className={classes.actionIcons}
										margin="dense"
										variant="outlined"
										color="action"
										onClick={handleSelectAll}
										style={{
											color: selectedTickets.length == ticketsList.length ? '#0b708c' : 'rgb(67, 83, 105)'
										}}
									/>
								</Tooltip>

								<Tooltip title="Não Respondidos">
									<SpeakerNotesIcon
										className={classes.actionIcons}
										onClick={() => {
											pressed === true ? setPressed(false) : setPressed(true);
										}}
										style={{
											color: pressed == true ? '#0b708c' : 'rgb(67, 83, 105)'
										}}
									>
										<IconTwo
											style={{ cursor: 'pointer', marginLeft: '2px', height: '20px', width: '20px' }}
											margin="dense"
											variant="outlined"
											color="action"
										/>
									</SpeakerNotesIcon>
								</Tooltip>
								<Tooltip title="Ordenar por Data">
									<SortByAlphaIcon
										className={classes.actionIcons}
										onClick={() => {
											order === "ASC" ? setOrder("DESC") : setOrder("ASC");
										}}
										style={{
											color: order == "DESC" ? '#0b708c' : 'rgb(67, 83, 105)'
										}}
									>
										<Icon
											style={{ cursor: 'pointer', marginLeft: '2px', height: '20px' }}
											margin="dense"
											variant="outlined"
											color="action"
										/>
									</SortByAlphaIcon>
								</Tooltip>
							</Grid>

						</ListSubheader>
					)}
					{status === "pending" && (
						<ListSubheader style={{ height: '35px', zIndex: 5 }} className={classes.ticketsListHeader}>
							<div>
								{i18n.t("ticketsList.pendingHeader")}
								<span className={classes.ticketsCount}>
									{!Array.isArray(totalTickets) || totalTickets.length == 0 ? ticketsArray.length : `${ticketsArray.length} / ${totalTickets.length}`}
								</span>
							</div>
							<Grid>
								{status === "pending" && selectedTickets.length > 0 && <SelectAction
									status={status}
									selectedTickets={selectedTickets}
									onOpen={() => generalActions("open", { userId: user.id })}
									onConcludeTicket={() => showBatchConclusionMessages()}
									onTagModal={() => showTagModalOpen()}
									onRemoveTags={() => generalActions("removeTags")}
									onUnread={() => generalActions("setUnread")}
								/>}

								<Tooltip title="Selecionar Todos os Atendimentos">
									<CheckBoxIcon
										className={classes.actionIcons}
										margin="dense"
										variant="outlined"
										color="action"
										onClick={handleSelectAll}
										style={{
											color: selectedTickets.length == ticketsList.length ? '#0b708c' : 'rgb(67, 83, 105)'
										}}
									/>
								</Tooltip>

								<Tooltip title="Não Respondidos">
									<SpeakerNotesIcon
										className={classes.actionIcons}
										onClick={() => {
											pressed === true ? setPressed(false) : setPressed(true);
										}}
										style={{
											color: pressed == true ? '#0b708c' : 'rgb(67, 83, 105)'
										}}
									>
										<IconTwo
											style={{ cursor: 'pointer', marginLeft: '2px', height: '20px', width: '20px' }}
											margin="dense"
											variant="outlined"
											color="action"
										/>
									</SpeakerNotesIcon>
								</Tooltip>

								<Tooltip title="Ordenar por Data">
									<SortByAlphaIcon
										className={classes.actionIcons}
										onClick={() => {
											order === "ASC" ? setOrder("DESC") : setOrder("ASC");
										}}
										style={{
											color: order == "DESC" ? '#0b708c' : 'rgb(67, 83, 105)'
										}}
									>
										<Icon
											style={{ cursor: 'pointer', marginLeft: '2px', height: '20px' }}
											margin="dense"
											variant="outlined"
											color="action"
										/>
									</SortByAlphaIcon>
								</Tooltip>
							</Grid>
						</ListSubheader>
					)}
					{status === "closed" && (
						<ListSubheader style={{ height: '35px', zIndex: 5 }} className={classes.ticketsListHeader}>
							<div>
								Finalizados
								<span className={classes.ticketsCount}>
									{!Array.isArray(totalTickets) || totalTickets.length == 0 ? ticketsArray.length : `${ticketsArray.length} / ${totalTickets.length}`}
								</span>
							</div>
							<Grid style={{ display: "flex" }}>
								{status === "closed" && selectedTickets.length > 0 && <SelectAction
									status={status}
									selectedTickets={selectedTickets}
									onOpen={() => generalActions("open", { userId: user.id })}
									onTagModal={() => showTagModalOpen()}
									onRemoveTags={() => generalActions("removeTags")}
									onUnread={() => generalActions("setUnread")}
								/>}

								<Tooltip title="Selecionar Todos os Atendimentos">
									<CheckBoxIcon
										className={classes.actionIcons}
										margin="dense"
										variant="outlined"
										color="action"
										onClick={handleSelectAll}
										style={{
											color: selectedTickets.length == ticketsList.length ? '#0b708c' : 'rgb(67, 83, 105)'
										}}
									/>
								</Tooltip>

								<Tooltip title="Não Respondidos">
									<SpeakerNotesIcon
										className={classes.actionIcons}
										onClick={() => {
											pressed === true ? setPressed(false) : setPressed(true);
										}}
										style={{
											color: pressed == true ? '#0b708c' : 'rgb(67, 83, 105)'
										}}
									>
										<IconTwo
											style={{ cursor: 'pointer', marginLeft: '2px', height: '20px', width: '20px' }}
											margin="dense"
											variant="outlined"
											color="action"
										/>
									</SpeakerNotesIcon>
								</Tooltip>

								<Tooltip title="Ordenar por Data">
									<SortByAlphaIcon
										className={classes.actionIcons}
										onClick={() => {
											order === "ASC" ? setOrder("DESC") : setOrder("ASC");
										}}
										style={{
											color: order == "DESC" ? '#0b708c' : 'rgb(67, 83, 105)'
										}}
									>
										<Icon
											style={{ cursor: 'pointer', marginLeft: '2px', height: '20px' }}
											margin="dense"
											variant="outlined"
											color="action"
										/>
									</SortByAlphaIcon>
								</Tooltip>
							</Grid>
						</ListSubheader>
					)}
					{ticketsArray.length === 0 && searchContacts.length === 0 && !loading ? (
						<div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>
								{i18n.t("ticketsList.noTicketsTitle")}
							</span>
							<p className={classes.noTicketsText}>
								{i18n.t("ticketsList.noTicketsMessage")}
							</p>
						</div>
					) : (
						<>
							{
								searchContacts.length > 0 &&
								<Box>
									<Typography className={classes.contactTitle}>Contatos</Typography>
									{searchContacts.map(contact => {
										return (<ContactListItem contact={contact} />)
									})}
									<Typography className={classes.contactTitle}>Atendimentos</Typography>
								</Box>
							}
							{pressed == false ? sortedTicketList.map((ticket, index) => (
								<TicketListItem
									ticket={{
										...ticket,
										draft: drafts.find(df => df.uuid == ticket.uuid)?.text
									}}
									key={ticket.id}
									index={index}
									onClick={e => {
										e.stopPropagation();
										handleCheckboxChange(ticket.id);
									}}
									checked={selectedTickets.includes(+ticket.id)}
									hiddenCheckboxOption={searchContacts.length > 0 ? true : false}
									searchingByParam={searchParam && String(searchParam).trim() != '' ? true : false}
								/>
							)) : sortedUnreadTickets.map(ticket => (
								<TicketListItem
									ticket={{
										...ticket,
										draft: drafts.find(df => df.uuid == ticket.uuid)?.text
									}}
									key={ticket.id}
									onClick={e => {
										e.stopPropagation();
										handleCheckboxChange(ticket.id);
									}}
									checked={selectedTickets.includes(+ticket.id)}
									hiddenCheckboxOption={searchContacts.length > 0}
									searchingByParam={searchParam && String(searchParam).trim() != '' ? true : false}
								/>
							))}
						</>
					)}
					{loading && <TicketsListSkeleton />}
				</List>
			</Paper>

			{/* modals for SelectAction */}
			<BatchConclusionMessages
				open={conclusionModalOpen}
				handleClose={hideBatchConclusionMessages}
				onConcludeTicket={generalActions}
			/>

			<BatchTagModal
				open={TagModalOpen}
				onClose={hideTagModalOpen}
				onSave={generalActions}
			/>
		</div>
	);
};

export default TicketsList;