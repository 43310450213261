import Dialog from "@material-ui/core/Dialog";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import toastError from "../../errors/toastError";
import ChatBotFlow from './ChatBotFlow';


import api from "../../services/api";

import "./styles.css";

const ConfirmationModal = ({ open, onClose, onConfirm, chatbotId }) => {

	const [loading, setLoading] = useState(true);
	const [edges, setEdges] = useState("");
	const [nodes, setNodes] = useState("");
	const [queues, setQueues] = useState([]);
	const [isReceptive, setIsReceptive] = useState(true);
	const [whatsappId, setWhatsappId] = useState(null);
	const [campaignProps, setCampaignProps] = useState(null);
	const [variables, setVariables] = useState(null);

	const resetValues = () => {
		setEdges('');
		setNodes('');
		setIsReceptive(true);
		setWhatsappId(null);
		setCampaignProps(null);
		setVariables(null);
	}

	useEffect(() => localStorage.removeItem("ChatBot"), [open]);

	useEffect(() => {
		if (!chatbotId || !open) return;

		let isMounted = true;
        const abortController = new AbortController();

		const delayDebounceFn = setTimeout(() => {
			setLoading(true)
			const fetchContacts = async () => {
				try {
					setLoading(true)
					const { data } = await api.get(`/chatbot/${chatbotId}`, {
						signal: abortController.signal,
					});
					if (!isMounted) return;
					setEdges(data.edges);
					setNodes(data.nodes);
					setIsReceptive(data.isReceptive == true ? true : false);
					setWhatsappId(data?.WhatsappChatbots[0]?.WhatsappId ? data?.WhatsappChatbots[0]?.WhatsappId : '');
					setCampaignProps(data?.campaign ? data.campaign : null);
					setVariables(data?.captures ? data.captures : null);
					setTimeout(() => setLoading(false), 1000);
				} catch (err) {
					if (!isMounted || err.name == 'AbortError') return;
					console.error({err});
					toastError(err);
				}
			};
			fetchContacts();

			const fetchQueues = async () => {
				try {
					const { data } = await api.get(`/queueByChatbot/${chatbotId}`, {
						signal: abortController.signal,
					});
					if (!isMounted) return;
					setQueues(data.filter(dt => dt.name != 'FILA_CHATBOT'));
				} catch (err) {
					if (!isMounted || err.name == 'AbortError') return;
					try {
						const { data } = await api.get(`/queue`, {
							signal: abortController.signal,
						});
						if (!isMounted) return;
						setQueues(data.filter(dt => dt.name != 'FILA_CHATBOT'));
					} catch (err) {
						if (!isMounted || err.name == 'AbortError') return;
						console.error({err});
						toastError(err);
					}
					console.error({err});
					toastError(err);
				}
			}
			fetchQueues();

		}, 500);
		return () => {
            isMounted = false;
            abortController.abort();
			clearTimeout(delayDebounceFn);
        };
	}, [chatbotId, open]);

	const saveFlowChatbot = async (values) => {
		let nodes = JSON.stringify(values.nodes)
		let edges = JSON.stringify(values.edges)
		let campaign = values?.campaign || null;
		setVariables(values?.variables ? values.variables : null);

		try {
			await api.put(`/chatbot/${chatbotId}`, { "nodes": nodes, "edges": edges, campaign, whatsappId, isReceptive, variables });
			// toast.success('Fluxo salvo com sucesso!')
		} catch (err) {
			console.error({err});
			toastError(err);
		}
	}

	return (
		<Dialog
			open={open == true ? true : false}
			fullWidth
			onClose={() => onClose(false)}
			PaperProps={{
				style: {
					margin: '0px',
					width: "calc(100% - 0px)",
					maxWidth: "calc(100% - 0px)",
					maxHeight: 'calc(100% - 0px)'
				},
			}}
			aria-labelledby="confirm-dialog"
		>
			{loading ?
				<>
					<Backdrop
						style={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={true}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				</> :
				<>
					<div style={{ height: "100vh" }}>
						<ChatBotFlow
							onClose={() => { resetValues(); onClose(false) }}
							onConfirm={e => { saveFlowChatbot(e) }}
							getNodes={nodes}
							getEdges={edges}
							getQueues={queues}
							isReceptive={isReceptive}
							campaignProps={campaignProps}
							variables={variables}
							setVariables={setVariables}
						/>
					</div>
				</>
			}
		</Dialog>
	);
};

export default ConfirmationModal;
