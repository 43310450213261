import { useContext } from "react";
import { Redirect, Route as RouterRoute } from "react-router-dom";
import BackdropLoading from "../components/BackdropLoading";
import { AuthContext } from "../context/Auth/AuthContext";
import { userRoutes, publicRoutes, privateRoutes } from ".";
import PageNotFound from "../pages/Error/PageNotFound";
import CheckComponentAccess from "../helpers/CheckComponentAccess";

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
  const { isAuth, loading, user } = useContext(AuthContext);

  if (loading || (isAuth && !user?.id)) {
    return <BackdropLoading />;
  }

  const pathSegment = window.location.pathname.replace(/^\//, '').split('/')[0];

  const getRouteMatch = () => (
    userRoutes.some(route => route.path.includes(pathSegment)) ||
    privateRoutes.some(route => route.path.includes(pathSegment)) ||
    publicRoutes.some(route => route.path.includes(pathSegment))
  );

  const hasAccessToRoute = () => (
    user?.profile !== 'user' ||
    userRoutes.some(route => route.path.includes(pathSegment)) ||
    (user?.campaignManagement && pathSegment === 'campaign')
  );  

  // Redireciona para /tickets quando a rota base ("/") é acessada
  if (window.location.pathname === "/" || window.location.pathname === "") {
    window.location.href = `${!user?.id ? "/login" : "/tickets"}`;
    return <BackdropLoading />;
  }

  if (!getRouteMatch()) {
    window.location.href = "/notFound";
    return <BackdropLoading />;
  }

  if (isPrivate && !isAuth) {
    window.location.href = "/login";
    return <BackdropLoading />;
  }

  if (isAuth && !isPrivate) {
    window.location.href = user?.profile == "user" ? "/tickets" : "/dashboard";
    return <BackdropLoading />;
  }

  if (
    pathSegment === 'customerRelationship' &&
    (
      !CheckComponentAccess(user, pathSegment) ||
      (user.profile == 'user' && user.crmAccess == false)
    )
  ) {
    return <RouterRoute {...rest} component={PageNotFound} />
  }

  if (!hasAccessToRoute()) {
    return (
      <>
        {loading && <BackdropLoading />}
        <RouterRoute {...rest} component={PageNotFound} />
      </>
    );
  }

  return (
    <>
      {loading && <BackdropLoading />}
      <RouterRoute {...rest} component={Component} />
    </>
  );
};

export default Route;
